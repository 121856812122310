import React, { Component } from "react";
import { Col, Input } from "reactstrap";

class SearchComponent extends Component {
  render() {
    const { keys = [] } = this.props;
    const is_date =
      this.props.searchKeyProperty === "created_at" ||
      this.props.searchKeyProperty === "payment_created_at" ||
      this.props.searchKeyProperty === "refund_created_at"
        ? true
        : false;

    return (
      <>
        <Col md={2}>
          {
            <Input
              type="select"
              id="search"
              placeholder="Search..."
              value={this.props.searchKeyProperty}
              name="searchKeyProperty"
              className="dropdown-color form-control"
              onChange={this.props.handleSearchInputChanges}
              autoFocus={true}
            >
              {keys.map((key) => {
                let split_keys = key.split(".");
                let req_key = split_keys[split_keys.length - 1];
                return <option value={key}>{req_key.toUpperCase()}</option>;
              })}
            </Input>
          }
        </Col>
        <Col md={3}>
          {
            <Input
              placeholder="Search..."
              type={is_date ? "date" : "input"}
              id="keyword"
              name="searchKeyword"
              value={this.props.searchKeyword}
              className="dropdown-color form-control"
              onChange={this.props.handleSearchInputChanges}
              data-is_date={is_date ? "date" : "text"}
              autoFocus={true}
            ></Input>
          }
        </Col>
      </>
    );
  }
}
export default SearchComponent;
