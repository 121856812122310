import React, { Component } from "react";
import "../../assets/css/nReports.css";

class TerminatedUsers extends Component {
  render() {
    return (
      <div className="table2 row-margin">
        <h2 style={{ "font-weight": "600" }}> Terminated Users</h2>
        <table className="newTable">
          <tr>
            <th>Plan</th>
            <th>LifeTime</th>
            <th>Today (total)</th>
          </tr>
          <tr>
            <td style={{ "font-weight": "bolder" }}>All</td>
            <td>{this.props.total}</td>
            <td>{this.props.today}</td>
          </tr>
          <tr>
            <td style={{ "font-weight": "bolder" }}>Pro</td>
            <td>{this.props.totalPro}</td>
            <td>{this.props.todayPro}</td>
          </tr>
          <tr>
            <td style={{ "font-weight": "bolder" }}>PRO Upgraded</td>
            <td>{this.props.totalUpgradedPro}</td>
            <td>{this.props.todayUpgradedPro}</td>
          </tr>
          <tr>
            <td style={{ "font-weight": "bolder" }}>Essential</td>
            <td>{this.props.totalEssential}</td>
            <td>{this.props.todayEssential}</td>
          </tr>
          <tr>
            <td style={{ "font-weight": "bolder" }}>Free Trial</td>
            <td>{this.props.totalTrial}</td>
            <td>{this.props.todayTrial}</td>
          </tr>
        </table>
      </div>
    );
  }
}

export default TerminatedUsers;
