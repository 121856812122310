import React, { Component } from 'react';
import { GetGiftsList, GetCouponsList } from '../../services/GiftRequests';
import Navbar from '../Others/NavbarComponent';
import Pagination from '../Payments/History/Pagination';
import GiftTable from './GiftTable';
import CouponsList from './CouponsList';

class Comp extends Component {
  constructor() {
    super();
    this.state = {
      searchEmail: '',
      giftOwnersList: [],
      pageSize: 10,
      pageNo: 1,
      totalPages: null,
      searchCategory: null,
      showDetails: false,
      couponsList: [],
      couponsOwner: {},
    };
  }

  componentDidMount() {
    const data = {
      pageNo: this.state.pageNo,
      pageSize: this.state.pageSize,
    };
    GetGiftsList(data)
      .then((res) => res.response)
      .then((resp) => {
        this.setState({
          giftOwnersList: resp.result,
          pageNo: resp.pageNo,
          pageSize: resp.pageSize,
          totalPages: resp.totalPages,
        });
      })
      .catch((err) => { console.log('err'); });
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  }

  onSubmit = (e) => {
    e.preventDefault();
    console.log(this.state);
  }

  getDetailsHandler = (couponCodes, userData) => {
    this.setState({
      showDetails: true,
      couponsOwner: userData,
    });
    GetCouponsList(couponCodes)
      .then((res) => {
        this.setState({
          couponsList: res.response.result,
        });
      })
      .catch((err) => { console.log('err'); });
  }

  closeCouponsListHandler = () => {
    this.setState({
      showDetails: false,
      couponsList: [],
      couponsOwner: {},
    });
  }

  resetData = (e) => {
    e.preventDefault();
    const data = {
      pageNo: this.state.pageNo,
      pageSize: this.state.pageSize,
    };
    GetGiftsList(data)
      .then((res) => res.response)
      .then((resp) => {
        this.setState({
          giftOwnersList: resp.result,
          pageNo: resp.pageNo,
          pageSize: resp.pageSize,
          totalPages: resp.totalPages,
          searchEmail: '',
        });
      })
      .catch((err) => { console.log('err'); });
  }

  search = (event) => {
    event.preventDefault();
    const data = {
      pageNo: 1,
      pageSize: 10,
    };
    this.getGiftCardData(data);
  }

  getGiftCardData = (data) => {
    if (!data) {
      data = {
        pageNo: this.state.pageNo,
      };
    }
    data.pageSize = this.state.pageSize;
    if (this.state.searchEmail != null) {
      data.email = this.state.searchEmail;
    }
    this.setState({ giftOwnersList: [] });
    GetGiftsList(data).then((resp) => {
      if (resp && resp.success) {
        this.setState({
          giftOwnersList: resp.response.result,
          pageNo: resp.response.pageNo,
          pageSize: resp.response.pageSize,
          totalPages: resp.response.totalPages,
        });
      }
    });
  }

  render() {
    if (this.state.showDetails) {
      return <CouponsList getBack={this.closeCouponsListHandler} tableData={this.state.couponsList} ownerData={this.state.couponsOwner} />;
    }

    return (
      <div>
        <Navbar />
        <div style={{ 'text-align': 'left', margin: '30px 5%' }}>
          <form onSubmit={this.search} onReset={this.resetData}>
            <label>Email</label>
            <input
              id="searchEmail"
              type="email"
              style={{ 'margin-left': '10px', height: '32px' }}
              onChange={this.onChange}
              value={this.state.searchEmail}
              autoFocus
            />
            <button className="btn-primary btn" style={{ 'margin-left': '10px', 'margin-bottom': '2px' }} type="submit">Search</button>
            <button className="btn-primary btn" style={{ 'margin-left': '10px', 'margin-bottom': '2px' }} type="reset">Reset</button>
          </form>
        </div>
        <div>
          <GiftTable tableData={this.state.giftOwnersList} getDetails={this.getDetailsHandler} />
          <Pagination currentPage={this.state.pageNo} lastPage={this.state.totalPages} getInfo={this.getGiftCardData} />
        </div>
      </div>
    );
  }
}

export default Comp;
