import { get, post, sess_get, sess_post } from '../services/HttpRequests';
import * as actionTypes from './actionTypes';

export const sendOtp = (phone_number) => (dispatch) => {
  return new Promise((resolve, reject) => {
    post('admin/console/send-otp', { phone_number })
      .then((response) => {
        if (response.data !== undefined && response.data.success) {
          dispatch({
            type: actionTypes.SEND_OTP,
            payload: response.data.response,
          });
          return resolve(response);
        }
      })
      .catch((err) => {
        let error = "Send otp error";
        if (err?.response?.data?.error.reason) {
          error = err.response.data.error.reason;
        }
        dispatch({
          type: actionTypes.ERROR,
          payload: error
        });
        return reject(err);
      });
  });
};

export const verifyOtp = ({ otp, secret, phone_number }) => async (
  dispatch
) => {
  try {
    const response = await post('admin/console/verify-otp', {
      otp,
      secret,
      phone_number,
    });
    dispatch({
      type: actionTypes.VERIFY_OTP,
      payload: response.data.response,
    });
  } catch (err) {
    let error = "Verify otp error";
    if (err?.response?.data?.error.reason) {
      error = err.response.data.error.reason;
    }
    dispatch({
      type: actionTypes.ERROR,
      payload: error
    });
  }
};

export const resetPassword = ({
  phone_number,
  confirm_password,
  new_password,
}) => async (dispatch) => {
  try {
    const response = await post('admin/console/forgot-password', {
      phone_number,
      confirm_password,
      new_password,
    });
    dispatch({
      type: actionTypes.RESET_PASSWORD,
      payload: response.data.response,
    });
    
  } catch (err) {
    let error = "Reset password error";
    if (err?.response?.data?.error.reason) {
      error = err.response.data.error.reason;
    }
    dispatch({
      type: actionTypes.ERROR,
      payload: error
    });
  }
};

export const resetState = ()=>(dispatch)=>{
  dispatch({
    type:actionTypes.LOGOUT
  })
}

export const setError = (error)=>(dispatch)=>{
  dispatch({
    type:actionTypes.ERROR,
    payload: error
  })
}
