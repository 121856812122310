import React, { Component } from "react";
import "../../assets/css/nReports.css";

class VMNinventory extends Component {
  render() {
    return (
      <div className="table2 row-margin">
        <h2 style={{ "font-weight": "600" }}> VMN inventory</h2>
        <table className="newTable">
          <tr>
            <th>Type</th>
            <th>Alloted</th>
            <th>Unalloted</th>
            <th>Reserved</th>
          </tr>
          <tr>
            <td style={{ "font-weight": "bolder" }}>Total</td>
            <td>{this.props.totalAllotted}</td>
            <td>{this.props.totalUnallotted}</td>
            <td>{this.props.totalReserved}</td>
          </tr>
          <tr>
            <td style={{ "font-weight": "bolder" }}>Free</td>
            <td>{this.props.freeAllotted}</td>
            <td>{this.props.freeUnallotted}</td>
            <td>{this.props.freeReserved}</td>
          </tr>
          <tr>
            <td style={{ "font-weight": "bolder" }}>Premium</td>
            <td>{this.props.premiumAllotted}</td>
            <td>{this.props.premiumUnallotted}</td>
            <td>{this.props.premiumReserved}</td>
          </tr>
          <tr style={{ "border-bottom": "1px solid grey" }}>
            <td style={{ "font-weight": "bolder" }}>VIP</td>
            <td>{this.props.vipAllotted}</td>
            <td>{this.props.vipUnallotted}</td>
            <td>{this.props.vipReserved}</td>
          </tr>
        </table>
      </div>
    );
  }
}

export default VMNinventory;
