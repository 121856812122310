import React, { useState } from 'react';
import Popup from "reactjs-popup";
import Loader from 'components/Others/Loader';
import { sess_post } from 'services/HttpRequests';


export default function UnblockUser(props) {
  const [processing, setProcessing] = useState(false);
  const [reason, setReason] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const closeHandler = () => {
    setError('');
    setConfirmationMessage('');
    setProcessing(false);
    if (success) {
      props.closeHandler(true);
    } else {
      props.closeHandler();
      setSuccess(false);
    }
  }

  const unblockHandler = () => {
    if (confirmationMessage !== 'UNBLOCK') return setError('invalid confirmation message');
    const session_token = localStorage.getItem("session_token");
    sess_post("admin/console/unblock-user-account", session_token, { user_id: props.userId })
      .then((res) => res.data)
      .then((res) => {
        setError('');
        setSuccess(true);
        setProcessing(false);
      })
      .catch((err) => {
        setError(err?.response?.data?.error?.reason);
        setProcessing(false);
      });
  }

  return (
    <Popup
      open={props.isOpen}
      onClose={closeHandler}
    >
      <div className="Modal">
        {processing ? (
          <div
            style={{
              textAlign: "center",
              margin: "30px 0",
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <h4
              style={{
                textAlign: "center",
                margin: "30px 0",
              }}
            >
              {success ? (
                <h5 style={{ color: "limegreen" }}>
                  The user is unblocked
                </h5>
              ) : (
                "Are you sure you want to unblock the user?"
              )}
            </h4>

            {!success && (
              <React.Fragment>
                <label style={{ "margin-left": "35px" }}>
                  <p style={{ fontSize: '12px', margin: 0 }}>Please enter the text "UNBLOCK" as-is to confirm the action.</p>
                  <input value={confirmationMessage}
                    placeholder="UNBLOCK"
                    onChange={(e) => { setConfirmationMessage(e.target.value) }} style={{ "margin-top": "5px" }} />
                </label>
                <p style={{ color: "red", textAlign: 'center' }}>
                  {error}
                </p>
                <button
                  className="btn btn-primary"
                  onClick={unblockHandler}
                  style={{ margin: "20px" }}
                >
                  Confirm
                </button>
              </React.Fragment>
            )}
            <button
              className="btn btn-danger"
              onClick={closeHandler}
              style={{ "margin-left": "100px" }}
            >
              Close
            </button>
          </>
        )}
      </div>
    </Popup>
  )
}