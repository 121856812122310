import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import Loader from 'components/Others/Loader';
import { Delete } from 'services/HttpRequests';

export default function DowngradeUser(props) {
  const [processing, setProcessing] = useState(false);
  const [reason, setReason] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const closeHandler = () => {
    setError('');
    setConfirmationMessage('');
    setReason('');
    setProcessing(false);
    if (success) { props.closeHandler(true); } else {
      props.closeHandler();
      setSuccess(false);
    }
  };

  const downgradeHandler = () => {
    if (reason === '') return setError('Provide reason');
    if (confirmationMessage !== 'DOWNGRADE') return setError('confirm action');

    setProcessing(true);
    Delete(
      'admin/console/downgrade-users-from-pro',
      { type: 'user_id' },
      { users: [props.userId], reason },
    )
      .then((res) => res.data)
      .then((res) => {
        setError('');
        setSuccess(true);
        setProcessing(false);
      })
      .catch((err) => {
        setError(err?.response?.data?.error?.reason);
        setProcessing(false);
      });
  };

  return (
    <Popup
      open={props.isOpen}
      onClose={props.closeHandler}
    >
      <div className="Modal">
        {processing ? (
          <div
            style={{ textAlign: 'center', margin: '30px 0' }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <h4
              style={{
                textAlign: 'center',
                margin: '30px 0',
              }}
            >
              {success ? (
                <h5 style={{ color: 'limegreen' }}>
                  user downgraded to essential plan
                </h5>
              ) : (
                'Are you sure you want to downgrade this user to Essential ?'
              )}
            </h4>
            {!success && (
              <>
                <form>
                  <label style={{ 'margin-left': '35px' }}>
                    <p style={{ fontSize: '12px', margin: 0 }}> Reason:</p>
                    <textarea value={reason} onChange={(e) => { setReason(e.target.value); }} style={{ 'margin-top': '5px', width: '100%' }} />
                  </label>
                  <label style={{ 'margin-left': '35px' }}>
                    <p style={{ fontSize: '12px', margin: 0 }}>Please enter the text "DOWNGRADE" as-is to confirm the action.</p>
                    <input
                      value={confirmationMessage}
                      placeholder="DOWNGRADE"
                      onChange={(e) => { setConfirmationMessage(e.target.value); }}
                      style={{ 'margin-top': '5px' }}
                    />
                  </label>
                </form>
                <p style={{ color: 'red', textAlign: 'center' }}>
                  {error}
                </p>
                <button
                  onClick={downgradeHandler}
                  style={{ margin: '20px' }}
                >
                  Confirm
                </button>
              </>
            )}
            <button
              onClick={closeHandler}
              style={{ 'margin-left': '100px' }}
            >
              Close
            </button>
          </>
        )}
      </div>
    </Popup>
  );
}
