import React, { Component } from 'react';
import '../../assets/css/nReports.css';

class ActiveUsersNewC extends Component {
  render() {
    return (
      <div className="table1">
        <h2 style={{ 'font-weight': '600' }}>Active Users</h2>
        <table className="newTable">
          <thead>
            <tr>
              <th />
              <th>LifeTime</th>
              <th>Today (total)</th>
              <th>Today (Web)</th>
              <th>Today (Android)</th>
              <th>Today (iOS)</th>
            </tr>
          </thead>
          <tr>
            <td style={{ 'font-weight': 'bolder' }}>All</td>
            <td>{this.props.total}</td>
            <td>{this.props.todayTotal}</td>
            <td>{this.props.todayWeb}</td>
            <td>{this.props.todayAndroid}</td>
            <td>{this.props.todayIOS}</td>
          </tr>
          <tr>
            <td style={{ 'font-weight': 'bolder' }}>PRO</td>
            <td>{this.props.totalPro}</td>
            <td>{this.props.todayProTotal}</td>
            <td>{this.props.todayProWeb}</td>
            <td>{this.props.todayProAndroid}</td>
            <td>{this.props.todayProIOS}</td>
          </tr>
          <tr>
            <td style={{ 'font-weight': 'bolder' }}>PRO Upgrades</td>
            <td>{this.props.totalUpgradedPro}</td>
            <td>{this.props.todayUpgradedPro}</td>
            <td>{this.props.todayUpgradedProWeb}</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr>
            <td style={{ 'font-weight': 'bolder' }}>Essential</td>
            <td>{this.props.totalEssential}</td>
            <td>{this.props.todayEssential}</td>
            <td>{this.props.todayEssentialWeb}</td>
            <td>{this.props.todayEssentialAndroid}</td>
            <td>{this.props.todayEssentialIOS}</td>
          </tr>
          <tr>
            <td style={{ 'font-weight': 'bolder' }}>Free Trial</td>
            <td>{this.props.totalTrial}</td>
            <td>{this.props.todayTrial}</td>
            <td>{this.props.todayTrialWeb}</td>
            <td>{this.props.todayTrialAndroid}</td>
            <td>{this.props.todayTrialIOS}</td>
          </tr>
        </table>
      </div>
    );
  }
}
export default ActiveUsersNewC;
