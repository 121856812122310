import moment from "moment";
import React, { Component } from "react";
import { Table } from "reactstrap";
import { GetPlans } from "../../services/KYCRequests";
let colorMap = {
  Active: "#29b82e",
  Expired: "#df3e3e",
};
function TableHeader() {
  return (
    <thead>
      <tr>
        <th>#</th>
        <th>Plan</th>
        <th>Price</th>
        <th>Payment Date</th>
        <th>Validity</th>
      </tr>
    </thead>
  );
}

function PaymentRow({ plan, id }) {
  const checkValidity = (validity) => {
    if (validity === "-") return "-";
    if (validity && isDate(validity)) {
      return moment(validity).format("DD/MM/YYYY");
    } else {
      return "Not activated";
    }
  };

  return (
    <React.Fragment>
      <td>{id + 1}</td>
      <td>{plan?.plan || '-'}</td>
      <td>{plan?.price / 100 || '-'}</td>
      <td>{plan?.paymentDate ? moment(plan.paymentDate).format("DD/MM/YYYY") : '-'}</td>
      <td>{plan?.validity ? checkValidity(plan.validity) : '-'}</td>
    </React.Fragment>
  );
}

class PlanDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPlansDetailsFetched: false,
      plans: [],
    };
  }
  componentDidMount() {
    var params = {
      user_id: this.props.user_id,
    };

    GetPlans(params).then((resp) => {
      if (resp && resp.success) {
        this.setState({
          plans: resp.response?.ordersHistory?.ordersSubscriptionsHistory || [],
          isPlansDetailsFetched: true,
        });
      }
    });
  }

  render() {
    const Plans = () => {
      if (this.state.plans.length > 0) {
        const plansList = this.state.plans.map((plan, index) => {
          return (
            <tr key={plan._id}>
              <PaymentRow plan={plan} id={index} />
            </tr>
          );
        });
        return (
          <Table responsive className="newTable">
            <TableHeader></TableHeader>
            <tbody>{plansList}</tbody>
          </Table>
        );
      } else if (
        this.state.plans.length == 0 &&
        this.state.isPlansDetailsFetched
      ) {
        return <p>No Details Found</p>;
      } else {
        return <p>Loading.....</p>;
      }
    };
    return <Plans />;
  }
}

export default PlanDetails;

function isDate(dateStr) {
  return !isNaN(new Date(dateStr).getDate());
}
