import { get, post, sess_get, sess_post } from './HttpRequests';



async function GetPaymentDetails(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get("admin/console/search-razorpay-payments", session_token, { ...params, type: "payments" })
      .then((resp) => {
        if (resp.data !== undefined && resp.data.success) {
          resolve(resp.data);
        } else {
          resolve(resp);
        }
      })
      .catch((error) => {
        if (
          Object.keys(error).length > 0 &&
          error?.response?.data?.error?.reason
        ) {
          reject(error.response.data);
        } else {
          reject({
            success: false,
            error: { code: 1003, reason: "Something went wrong" },
          });
        }
      });

  });

  return (response.success !== undefined) ? response : {};
}

async function GetOrdersData(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get("admin/console/search-razorpay-payments", session_token, {
      ...params,
      type: "orders",
    })
      .then((resp) => {
        if (resp.data !== undefined && resp.data.success) {
          resolve(resp.data);
        } else {
          resolve(resp);
        }
      })
      .catch((error) => {
        if (
          Object.keys(error).length > 0 &&
          error?.response?.data?.error?.reason
        ) {
          resolve(error.response.data);
        } else {
          resolve({
            success: false,
            error: { code: 1003, reason: "Something went wrong" },
          });
        }
      });
  });

  return response.success !== undefined ? response : {};
}

async function GetSubscriptionsData(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get("admin/console/search-razorpay-payments", session_token, {
      ...params,
      type: "subscriptions",
    })
      .then((resp) => {
        if (resp.data !== undefined && resp.data.success) {
          resolve(resp.data);
        } else {
          resolve(resp);
        }
      })
      .catch((error) => {
        if (
          Object.keys(error).length > 0 &&
          error?.response?.data?.error?.reason
        ) {
          resolve(error.response.data);
        } else {
          resolve({
            success: false,
            error: { code: 1003, reason: "Something went wrong" },
          });
        }
      });
  });

  return response.success !== undefined ? response : {};
}


async function GetRefundsData(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get("admin/console/search-razorpay-payments", session_token, {
      ...params,
      type: "refunds",
    })
      .then((resp) => {
        if (resp.data !== undefined && resp.data.success) {
          resolve(resp.data);
        } else {
          resolve(resp);
        }
      })
      .catch((error) => {
        if (
          Object.keys(error).length > 0 &&
          error?.response?.data?.error?.reason
        ) {
          resolve(error.response.data);
        } else {
          resolve({
            success: false,
            error: { code: 1003, reason: "Something went wrong" },
          });
        }
      });
  });

  return response.success !== undefined ? response : {};
}


async function GetInvoiceDownloadData(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get("admin/console/invoice", session_token, { ...params })
      .then((resp) => {
        if (resp.data !== undefined && resp.data.success) {
          resolve(resp.data);
        } else {
          resolve(resp);
        }
      })
      .catch((error) => {
        if (
          Object.keys(error).length > 0 &&
          error?.response?.data?.error?.reason
        ) {
          resolve(error.response.data);
        } else {
          resolve({
            success: false,
            error: { code: 1003, reason: "Something went wrong" },
          });
        }
      });
  });

  return response.success !== undefined ? response : {};
}

export {
  GetPaymentDetails,
  GetOrdersData,
  GetSubscriptionsData,
  GetRefundsData,
  GetInvoiceDownloadData
};