import { get, post, sess_get, sess_post } from './HttpRequests';

async function LoginAPI(phone_number, password) {

  const response = await new Promise(function (resolve, reject) {

    post('admin/console/customer-care-login', { username: phone_number, password: password }).then((resp) => {
      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }

    })
      .catch((error) => {
        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          reject(error.response.data);
        }
        else {
          reject({ "success": false, "error": { "code": 1003, "reason": "Something went wrong" } });
        }
      });

  });

  return (response.success !== undefined) ? response : {};
}

async function Logout() {

  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_post('admin/console/logout', session_token).then((resp) => {
      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }

    })
      .catch((error) => {
        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          reject(error.response.data);
        }
        else {
          reject({ "success": false, "error": { "code": 1003, "reason": "Something went wrong" } });
        }
      });

  });

  return (response.success !== undefined) ? response : {};
}

async function AutoLogout(err) {

  if (err.response && err.response.data && err.response.data.error && err.response.data.error.code == 1001) {
    localStorage.removeItem('session_token');
    localStorage.removeItem('isLogged');
    localStorage.removeItem('username');
    window.location.reload();
  }


}

export { LoginAPI, Logout, AutoLogout };
