import moment from 'moment';
import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import {
  GetBaseReports,
  RenewalStatistics,
  EngagementStatistics,
  GetUserStatistics,
  VMNStatistics,
  ProspectsStatistics
} from '../../services/ReportRequests';
import NavbarComponent from '../Others/NavbarComponent';
import ActiveUsersNewC from './ActiveUsersNewC';
// import FreeTrailUsers from './FreeTrailUsers'
import DeactivatedUsers from './DeactivatedUsers';
import Other from './Other';
import ReportTable from './ReportTable';
import TerminatedUsers from './TerminatedUsers';
import VMNinventory from './VMNinventory';
import KYCData from './Kyc_data';
import Engagement from './Engagement';
import RenewalsTable from './RenewalsTable';
import Pagination from '../Payments/History/Pagination';
import Loader from '../Others/Loader';

const headers = [
  { label: 'Date', key: 'date' },
  { label: 'New Users', key: 'new_users' },
  { label: 'New Users (Web)', key: 'new_users_web' },
  { label: 'New Users (Android)', key: 'new_users_mobile' },
  { label: 'New Users (iOS)', key: 'new_users_iap_ios' },
  { label: 'Total Active Users', key: 'total_active_users' },
  // { label: 'New Users (Trial)', key: 'new_active_free_trial' },
  { label: 'New Users (Trial, Web)', key: 'new_active_free_trial_web' },
  { label: 'New Users (Trial, Android)', key: 'new_active_free_trial_mobile' },
  { label: 'New Users (Trial, iOS)', key: 'new_active_free_trial_ios' },
  { label: 'Total Active Users (Trial)', key: 'total_active_free_trial' },
  { label: 'New Users (Pro)', key: 'new_pro_active' },
  { label: 'New Users (Pro, Web)', key: 'new_pro_active_web' },
  { label: 'New Users (Pro, Android)', key: 'new_pro_active_mobile' },
  { label: 'New Users (Pro, iOS)', key: 'new_pro_active_iap_ios' },
  { label: 'Total Active Users (Pro)', key: 'total_pro_active' },
  { label: 'New Users (Upgraded Pro)', key: 'new_upgraded_pro_active' },
  {
    label: 'Total Active Users (Upgraded Pro)',
    key: 'total_upgraded_pro_active',
  },
  { label: 'Total Active Users (app)', key: 'total_signin_users' },
  { label: 'Verified Prospects', key: 'verified_prospects' },
  { label: 'Bounced Users', key: 'bounced_users' },
  { label: 'Deactivated Users', key: 'deactivated_users' },
  { label: 'Terminated Users', key: 'terminated_users' },
  { label: 'Referrals', key: 'total_referrals' },

];
const today = moment().format('YYYY-MM-DD');

class Reports extends Component {
  constructor() {
    super();
    this.state = {
      from: null,
      to: null,
      error: '',
      reportData: [],
      engagementStatistics: null,
      userStats: null,
      vmns_stats: null,
      prospect_stats: null,
      renewalStatistics: {},
      reportAllData: [],
    };
  }

  componentDidMount() {
    const fiveDaysAgo = moment().subtract(5, 'days').format('YYYY MM DD');

    // Get pagination Data
    const reqdata = {
      pageNo: 1,
      pageSize: 10,
    };
    this.getReportData(reqdata, 'pagination');
    // get all data for download
    this.getReportData({ pageNo: 1, pageSize: 10000 }, 'All');

    // Get pagination Data
    // GetBaseReports()
    //   .then((resp) => {
    //     this.setState({ reportData: resp.response.result });
    //     if (resp.response?.result?.length > 0) {
    //       const formatedData = resp.response.result.map((row) => ({
    //         ...row,
    //         date: moment(row.date).format('YYYY-MM-DD'),
    //       }));
    //       this.setState({ reportData: formatedData });
    //     }
    //   })
    //   .catch((err) => {
    //     console.log('Report::index:GetBaseReports:err', err.message);
    //   });
    this.getDailyCardsData();
  }

  refreshData = () => {
    this.setState({
      dailyReport: {},
      otherStatistics: {},
      engagementStatistics: null,
      userStats: null,
      vmnsStats: null,
      prospectStats: null,
      renewalStatistics: {},
      pageSize: 10,
      pageNo: 1,
      totalPages: null,
    });
    this.getDailyCardsData();
  };

  getDailyCardsData = () => {

    GetUserStatistics()
      .then((resp) => {
        var user_stats = resp.response && resp.response.user_stats ? resp.response.user_stats : null;
        this.setState({ userStats: user_stats });
      })
      .catch((err) => {
        console.log('Report::index:GetUserStatistics:err', err.message);
      });

    EngagementStatistics()
      .then((resp) => {
        this.setState({ engagementStatistics: resp?.response?.engagement_statistics });
      })
      .catch((err) => {
        console.log('Report::index:EngagementStatistics:err', err);
      });

    VMNStatistics()
      .then((resp) => {
        this.setState({ vmnsStats: resp?.response?.vmn_inventory });
      })
      .catch((err) => {
        console.log('Report::index:VMNStatistics:err', err);
      });

    ProspectsStatistics()
      .then((resp) => {
        this.setState({ prospectStats: resp?.response?.prospect_stats });
      })
      .catch((err) => {
        console.log('Report::index:ProspectsStatistics:err', err);
      });

    RenewalStatistics()
      .then((resp) => {
        this.setState({ renewalStatistics: resp.response.result });
      })
      .catch((err) => {
        console.log('Report::index:RenewalStatistics:err', err.message);
      });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  searchHandler = () => {
    if (this.state.from == null || this.state.to == null) return this.setState({ error: 'provide input dates' });
    if (this.state.from > this.state.to) return this.setState({ error: 'from can not be greate that till data' });
    if (this.state.to > today) return this.setState({ error: 'no future dates' });
    this.setState({ error: '', reportData: [] });
    const data = {
      from: this.state.from, to: this.state.to, pageNo: parseInt(this.state.pageNo || 0), pageSize: parseInt(this.state.pageSize || 10),
    };
    this.getReportData(data, 'search');
  };

  getReportData = (data, type) => {
    const { from } = this.state;
    const { to } = this.state;
    if (from && to) {
      data = { ...data, from: this.state.from, to: this.state.to };
    }
    GetBaseReports(data)
      .then((resp) => {
        const resData = resp?.response?.result?.docs || [];
        if (type === 'All') {
          this.setState({ reportAllData: resData });
          return;
        }

        const totalPages = resp?.response?.totalPages || null;
        const pageNo = resp?.response?.pageNo || 1;
        const pageSize = resp?.response?.pageSize || 10;
        this.setState({ pageNo, pageSize, totalPages });
        if (resData.length > 0) {
          const formatedData = resData.map((row) => ({
            ...row,
            date: moment(row.date).format('YYYY-MM-DD'),
          }));
          this.setState({ reportData: formatedData });
        }
      })
      .catch((err) => {
        console.log('err');
        if (type === 'All') this.setState({ reportAllData: [] });
        else {
          this.setState({
            reportData: [], pageNo: 1, pageSize: 10, totalPages: null,
          });
        }
        console.error('Report::index:GetBaseReports:err', err.message);
      });
  };

  getPercentage = (item, total) => {
    return total == 0 ? 0 : (((item / total) * 100).toFixed(1));
  }

  render() {
    const { renewalStatistics, pageNo, totalPages, userStats, engagementStatistics, prospectStats, vmnsStats } = this.state;

    return (
      <div>
        <NavbarComponent />
        <div>
          <h1>
            Reports
            <img
              style={{ cursor: 'pointer' }}
              onClick={this.refreshData}
              src={require('../../assets/refresh_icon.svg')}
            />
          </h1>
        </div>
        <div className="ReportContainer">

          {userStats && userStats.active_users ? (
            <ActiveUsersNewC
              freeTrial
              pro
              upgradedPro
              total={userStats?.active_users?.all_lifetime}
              todayTotal={userStats?.active_users?.all_today}
              todayWeb={userStats?.active_users?.all_web}
              todayAndroid={userStats?.active_users?.all_android}
              todayIOS={userStats?.active_users?.all_ios}
              totalTrial={userStats?.active_users?.freetrial_lifetime}
              todayTrial={userStats?.active_users?.freetrial_today}
              todayTrialWeb={userStats?.active_users?.freetrial_web}
              todayTrialAndroid={userStats?.active_users?.freetrial_android}
              todayTrialIOS={userStats?.active_users?.freetrial_ios}
              todayProWeb={userStats?.active_users?.pro_web}
              todayProAndroid={userStats?.active_users?.pro_android}
              todayProIOS={userStats?.active_users?.pro_ios}
              totalPro={userStats?.active_users?.pro_lifetime}
              todayProTotal={userStats?.active_users?.pro_today}
              todayUpgradedPro={userStats?.active_users?.pro_upgrade_today}
              totalUpgradedPro={userStats?.active_users?.pro_upgrade_lifetime}
              todayUpgradedProWeb={userStats?.active_users?.pro_upgrade_web}
              totalEssential={userStats?.active_users?.essential_lifetime}
              todayEssential={userStats?.active_users?.essential_today}
              todayEssentialWeb={userStats?.active_users?.essential_web}
              todayEssentialAndroid={userStats?.active_users?.essential_android}
              todayEssentialIOS={userStats?.active_users?.essential_ios}
            />
          ) : (<div>
            <h2 style={{ 'font-weight': '600' }}>Active Users</h2><Loader /></div>)}

          {/* <Kyc_data
            total={this.state.dailyReport.total_active_users}
            totalPro={proReport.total_pro_active}
          /> */}

          {prospectStats ? (
            <Other
              todayBounced={prospectStats?.bounced_today}
              totalBounced={prospectStats?.bounced_total}
              todayVerified={prospectStats?.prospects_today}
              totalVerified={prospectStats?.prospects_total}
              todayReferral={prospectStats?.referrals_today}
              totalReferral={prospectStats?.referrals_total}
            />
          ) : (<div>
            <h2 style={{ 'font-weight': '600' }}>Other Metrics</h2><Loader /></div>)}

          {userStats && userStats.terminated_users ? (
            <TerminatedUsers
              style={{ 'margin-top': '20px' }}
              today={userStats?.terminated_users?.all_today_terminated}
              total={userStats?.terminated_users?.all_lifetime}
              freeTrial
              pro
              upgradedPro
              todayTrial={userStats?.terminated_users?.freetrial_today}
              totalTrial={userStats?.terminated_users?.freetrial_lifetime}
              todayPro={userStats?.terminated_users?.pro_today}
              totalPro={userStats?.terminated_users?.pro_lifetime}
              todayUpgradedPro={userStats?.terminated_users?.pro_upgrade_today}
              totalUpgradedPro={userStats?.terminated_users?.pro_upgrade_lifetime}
              todayEssential={userStats?.terminated_users?.essential_today}
              totalEssential={userStats?.terminated_users?.essential_lifetime}
            />
          ) : (<div>
            <h2 style={{ 'font-weight': '600' }}>Terminated Users</h2><Loader /></div>)}
          {userStats && userStats.deactivated_users ? (
            <DeactivatedUsers
              today={userStats?.deactivated_users?.all_today_expired}
              total={userStats?.deactivated_users?.all_lifetime}
              freeTrial
              pro
              upgradedPro
              todayTrial={userStats?.deactivated_users?.freetrial_today}
              totalTrial={userStats?.deactivated_users?.freetrial_lifetime}
              todayPro={userStats?.deactivated_users?.pro_today}
              totalPro={userStats?.deactivated_users?.pro_lifetime}
              todayUpgradedPro={userStats?.deactivated_users?.pro_upgrade_today}
              totalUpgradedPro={userStats?.deactivated_users?.pro_upgrade_lifetime}
              todayEssential={userStats?.deactivated_users?.essential_today}
              totalEssential={userStats?.deactivated_users?.essential_lifetime}
            />
          ) : (<div>
            <h2 style={{ 'font-weight': '600' }}>Deactivated Users</h2><Loader /></div>)}

          {vmnsStats ? (
            <VMNinventory
              style={{ 'margin-top': '30px' }}
              totalAllotted={vmnsStats?.total_vmns_allotted}
              totalUnallotted={vmnsStats?.total_vmns_unallotted}
              totalReserved={vmnsStats?.total_vmns_reserved}
              freeAllotted={vmnsStats?.free_vmns_allotted}
              freeUnallotted={vmnsStats?.free_vmns_unallotted}
              freeReserved={vmnsStats?.free_vmns_reserved}
              premiumAllotted={vmnsStats?.premium_vmns_allotted}
              premiumUnallotted={vmnsStats?.premium_vmns_unallotted}
              premiumReserved={vmnsStats?.premium_vmns_reserved}
              vipAllotted={vmnsStats?.vip_vmns_allotted}
              vipUnallotted={vmnsStats?.vip_vmns_unallotted}
              vipReserved={vmnsStats?.vip_vmns_reserved}
            />
          ) : (<div>
            <h2 style={{ 'font-weight': '600' }}>VMN inventory</h2><Loader /></div>)}

          {userStats && userStats.kyc_details ? (
            <KYCData
              total={userStats?.kyc_details?.kyc_total}
              totalCompleted={userStats?.kyc_details?.kyc_completed}
              totalNotCompleted={userStats?.kyc_details?.kyc_not_completed}
              percentage={((100 * (userStats?.kyc_details?.kyc_completed || 0)) / (userStats?.kyc_details?.kyc_total || 0)).toFixed(1)}
              totalPRO={userStats?.kyc_details?.kyc_pro_total}
              totalCompletedPRO={userStats?.kyc_details?.kyc_pro_completed}
              totalNotCompletedPRO={userStats?.kyc_details?.kyc_pro_not_completed}
              percentagePRO={((100 * (userStats?.kyc_details?.kyc_pro_completed || 0)) / (userStats?.kyc_details?.kyc_pro_total || 0)).toFixed(1)}
              totalEssential={userStats?.kyc_details?.kyc_essential_total}
              totalCompletedEssential={userStats?.kyc_details?.kyc_essential_completed}
              totalNotCompletedEssential={userStats?.kyc_details?.kyc_essential_not_completed}
              percentageEssential={((100 * (userStats?.kyc_details?.kyc_essential_completed || 0)) / (userStats?.kyc_details?.kyc_essential_total || 0)).toFixed(1)}
            />
          ) : (<div>
            <h2 style={{ 'font-weight': '600' }}>KYC Data</h2><Loader /></div>)}

          {engagementStatistics ? (
            <Engagement
              style={{ 'margin-top': '30px' }}
              passcodesLifetime={engagementStatistics.total_passcodes}
              passcodesToday={engagementStatistics.today_passcodes}
              otpLifetime={engagementStatistics.total_otps}
              otpToday={engagementStatistics.today_otps}
              viaTSLifetime={engagementStatistics.total_ts_calls}
              viaTSToday={engagementStatistics.today_ts_calls}
              viaTCLifetime={engagementStatistics.total_tc_calls}
              viaTCToday={engagementStatistics.today_tc_calls}
              viaPasscodeLifetime={engagementStatistics.total_passcode_calls}
              viaPasscodeToday={engagementStatistics.today_passcode_calls}
              passcode_sov={engagementStatistics.passcode_sov}
              otp_sov={engagementStatistics.otp_sov}
              trusted_services_sov={engagementStatistics.trusted_services_sov}
              trusted_contacts_sov={engagementStatistics.trusted_contacts_sov}
              passcode_calls_sov={engagementStatistics.passcode_calls_sov}
            />
          ) : (<div>
            <h2 style={{ 'font-weight': '600' }}>Engagement</h2>
          </div>)}

          {this.state.renewalStatistics ? (
            <RenewalsTable
              // style={{ 'margin-top': '30px' }}
              last15days={renewalStatistics.last_15_renewal}
              last15dayspercentage={this.getPercentage(renewalStatistics.last_15_renewal, renewalStatistics.last_15_count)}
              last15inorganic={renewalStatistics.last_15_inorganic_renewal}
              last15inorganic_percentage={this.getPercentage(renewalStatistics.last_15_inorganic_renewal, renewalStatistics.last_15_renewal)}
              last15organic={renewalStatistics.last_15_renewal - renewalStatistics.last_15_inorganic_renewal}
              last15organic_percentage={this.getPercentage((renewalStatistics.last_15_renewal - renewalStatistics.last_15_inorganic_renewal), renewalStatistics.last_15_renewal)}
              last30days={renewalStatistics.last_30_renewal}
              last30dayspercentage={this.getPercentage(renewalStatistics.last_30_renewal, renewalStatistics.last_30_count)}
              last30inorganic={renewalStatistics.last_30_inorganic_renewal}
              last30inorganic_percentage={this.getPercentage(renewalStatistics.last_30_inorganic_renewal, renewalStatistics.last_30_renewal)}
              last30organic={renewalStatistics.last_30_renewal - renewalStatistics.last_30_inorganic_renewal}
              last30organic_percentage={this.getPercentage((renewalStatistics.last_30_renewal - renewalStatistics.last_30_inorganic_renewal), renewalStatistics.last_30_renewal)}
              last180days={renewalStatistics.last_180_renewal}
              last180dayspercentage={this.getPercentage(renewalStatistics.last_180_renewal, renewalStatistics.last_180_count)}
              last180inorganic={renewalStatistics.last_180_inorganic_renewal}
              last180inorganic_percentage={this.getPercentage(renewalStatistics.last_180_inorganic_renewal, renewalStatistics.last_180_renewal)}
              last180organic={renewalStatistics.last_180_renewal - renewalStatistics.last_180_inorganic_renewal}
              last180organic_percentage={this.getPercentage((renewalStatistics.last_180_renewal - renewalStatistics.last_180_inorganic_renewal), renewalStatistics.last_180_renewal)}
            />
          ) : null}
        </div>
        <div style={{ textAlign: 'left', marginLeft: '5%', marginTop: '40px' }}>
          <h4>Filter</h4>
          <label>From</label>
          <input
            type="date"
            className="inputs"
            onChange={this.onChange}
            value={this.state.from}
            id="from"
          />
          <label style={{ paddingLeft: '10px' }}>To</label>
          <input
            type="date"
            className="inputs"
            onChange={this.onChange}
            value={this.state.to}
            id="to"
          />
          <button
            className="btn-primary btn"
            style={{ marginLeft: '10px' }}
            onClick={this.searchHandler}
          >
            Search
          </button>
          <button
            className="btn-primary btn"
            style={{ float: 'right', marginRight: '5%' }}
          >
            <CSVLink
              style={{ color: 'white' }}
              data={this.state.reportAllData}
              filename={`users_report_${moment().format('DD-MM-YYYY')}.csv`}
              headers={headers}
            >
              Download
            </CSVLink>
          </button>
        </div>
        <p style={{ color: 'red' }}>
          {this.state.error}
          {' '}
        </p>
        <div style={{ overflow: 'scroll', margin: '20px', paddingBottom: '15px' }}>
          <ReportTable tableData={this.state.reportData} style={{ padding: '20px', margin: '20px' }} />
        </div>
        <Pagination
          style={{ margin: '20px' }}
          currentPage={pageNo || 1}
          lastPage={totalPages || null}
          getInfo={this.getReportData}
        />
      </div>
    );
  }
}

export default Reports;
