import React, { Component } from 'react';
import { Table, Row, Col, Form, Input, Button, Alert } from 'reactstrap';
import Pagination from './Pagination';
import { GetOrdersData } from '../../../services/PaymentRequests';
import moment from 'moment';
import SearchComponent from '../../Others/SearchComponent';
let colorMap = {
  created: '#808080',
  paid: '#4bb371',
  authenticated: '#000000',
};

class Orders extends Component {
  constructor() {
    super();
    this.state = {
      ordersList: [],
      pageSize: 10,
      pageNo: 1,
      totalPages: null,
      searchCategory: null,
      searchInputType:'string'
    };
  }

  componentDidMount() {
    let data = {
      pageNo: 1,
      pageSize: 10,
    };
    GetOrdersData(data)
      .then((resp) => {
        if (resp && resp.success) {
          this.setState({
            ordersList: resp.response.required_docs,
            pageNo: resp.response.pageNo,
            pageSize: resp.response.pageSize,
            totalPages: resp.response.totalPages,
            error: "",
          });
        }
      })
      .catch((error) => {
        this.setState({ error: error?.response?.reason });
      });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    if (value == "all") {
      this.setState({ [name]: null });
    } else {
      this.setState({ [name]: value });
    }
  };

  search = (event) => {
    event.preventDefault();
    let data = {
      pageNo: 1,
      pageSize: 10,
    };
    this.getOrderData(data);
  };

  getOrderData = (data) => {
    if (!data) {
      data = {
        pageNo: this.state.pageNo,
        pageSize: this.state.pageSize,
      };
    }
    if (this.state.searchCategory) {
      data.label = this.state.searchCategory;
    }

    if (this.state.searchKeyProperty) {
      data.searchKeyProperty = this.state.searchKeyProperty;
    }

    if (this.state.searchKeyword) {
      data.searchKeyword = this.state.searchKeyword;
    }
    
    if(this.state.searchInputType){
      data.searchInputType=this.state.searchInputType;
    }

    GetOrdersData(data)
      .then((resp) => {
        if (resp && resp.success) {
          this.setState({
            ordersList: resp.response.required_docs,
            pageNo: resp.response.pageNo,
            pageSize: resp.response.pageSize,
            totalPages: resp.response.totalPages,
            // searchKeyword: '',
            // searchKeyProperty: '',
            error: "",
          });
        }
      })
      .catch((err) => {
        this.setState({
          error: err?.error?.reason || "Something went wrong",
        });
      });
  };
  handleSearchInputChanges = (event) => {
    let state = {};
    if (event.target.value === "Select Category") {
      state = {
        searchKeyProperty: "",
        searchKeyword: "",
      };
    }
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value === "Select Category" ? "" : event.target.value,
      ...state,
      searchInputType:event.target.getAttribute('data-is_date')
    });
  };

  render() {
    const keys = [
      "Select Category",
      "id",
      "payment_id",
      "amount",
      "payment_details.contact",
      "receipt_id",
      "created_at",
      "status",
    ];
    function TableHeader() {
      return (
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Payment ID</th>
            <th>Amount</th>
            <th>Mobile No</th>
            <th>Receipt</th>
            <th>Created At</th>
            <th>Status</th>
          </tr>
        </thead>
      );
    }

    const data = this.state.ordersList.map((order) => {
      return (
        <tr key={order._id}>
          <td>{order.id}</td>
          <td>{order.payment_id}</td>
          <td>{order.amount / 100}</td>
          <td>
            {order.payment_details && order.payment_details.contact
              ? order.payment_details.contact
              : "--"}
          </td>
          <td>{order.receipt}</td>
          <td>{moment(order.created_at).format("DD MMM YYYY h:mm a")}</td>
          <td style={{ color: colorMap[order.status] || "#7b7b7b" }}>
            {order.status}
          </td>
        </tr>
      );
    });

    return (
      <div>
        <Form onSubmit={this.search} style={{ "padding-bottom": "20px" }}>
          <Row>
            <Col md={3}>
              <Input
                type="select"
                id="searchCategory"
                name="searchCategory"
                value={this.state.searchCategory}
                className="dropdown-color"
                onChange={this.handleInputChange}
              >
                <option value="all"> All </option>
                <option value="created"> Created </option>
                <option value="paid"> Paid </option>
                <option value="authenticated"> Authenticated </option>
              </Input>
            </Col>
            <SearchComponent
              keys={keys}
              handleSearchInputChanges={this.handleSearchInputChanges}
              searchKeyProperty={this.state.searchKeyProperty}
              searchKeyword={this.state.searchKeyword}
            />
            <Col md={3}>
              <Button
                type="submit"
                color="primary"
                style={{ float: "right" }}
                className="kyc-filter-button"
              >
                Search
              </Button>
            </Col>
          </Row>
        </Form>

        {this.state.error && <Alert color="danger">{this.state.error}</Alert>}

        <Table responsive className="newTable" style={{"width":"100%"}}>
          <TableHeader></TableHeader>
          <tbody>{data}</tbody>
        </Table>

        <Pagination
          currentPage={this.state.pageNo}
          lastPage={this.state.totalPages}
          getInfo={this.getOrderData}
        />
      </div>
    );
  }
}

export default Orders;
