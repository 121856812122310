import React from 'react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import store from './store';
import './App.css';
import {
  BrowserRouter,
} from 'react-router-dom';
import MainComponent from './components/MainComponent';
import { setup_axios_interceptor } from './services/HttpRequests';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

setup_axios_interceptor(store);
function App() {
  const TITLE = 'Doosra';
  return (
    <Provider store={store}>
      <BrowserRouter basename="/">
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <div className="App">
          <ToastContainer />
          <MainComponent />
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
