/* eslint-disable */
import React, { useEffect, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { MdOutlineCancel } from 'react-icons/md'
import Popup from "reactjs-popup";
import styled from "styled-components";
import { sess_get, sess_post } from "../../services/HttpRequests";

const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  &-overlay {
    ...;

  }
  // use your custom style for ".popup-content"
  &-content {
    ...;
    border: 2px solid black;
    border-radius: 6px;
    padding: 10px 10px 30px 10px;
  }
`;

const Button = styled.button`
  color: white;
  background-color: black;
  border: none;
  font-weight: 500;
  border-radius: 4px;
  padding: 8px 20px;
`;

export default function ExtendValidity(props) {
  const [step, setStep] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [days, setDays] = useState(1);
  const [campaign, setCampaign] = useState("");
  const [confirmationText, setConfirmationText] = useState('');
  const [campaignList, setCampaignList] = useState([]);
  const [reason, setReason] = useState('');
  const [error, setError] = useState("");
  let screen;

  useEffect(() => {
    const session_token = localStorage.getItem("session_token");
    sess_get("admin/console/campaigns", session_token)
      .then((res) => {
        setCampaign(res.data.response[0]);
        setCampaignList(res?.data?.response);
      })
      .catch((err) => {
        setError(err?.response?.data?.error?.reason);
      });
  }, []);

  const applyCampaignPromotion = (userId) => {
    const session_token = localStorage.getItem("session_token");
    let data = {
      user_id: userId,
      validity: days,
      campaign,
      reason
    };
    if (confirmationText !== 'VALIDITY') return setError('invalid confirmation value');
    sess_post("admin/console/add-to-campaign", session_token, data)
      .then((res) => {
        if (res?.data?.response?.validity_extended) {
          setStep(3);
        }
      })
      .catch((err) => {
        setError(err?.response?.data?.error?.reason);
      });
  };

  const clearData = () => {
    setDays(1);
    setCampaign(campaignList[0]);
    setConfirmationText("");
    setError("");
    setReason("");
    if (step === 3) {
      window.location.reload();
    } else {
      setIsPopupOpen(false);
      setStep(1);
    }
  };

  switch (step) {
    case 1:
      screen = (
        <div>
          <p style={{ textAlign: "end" }}>
            <MdOutlineCancel onClick={clearData} style={{ fontSize: "25px" }} />
          </p>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              width: "fit-content",
              alignItems: "center",
              margin: "auto",
            }}
            onSubmit={(e) => {
              e.preventDefault();
              if (reason === '') return setError('Provide reason');
              setStep(2);
              setError('')
            }}
          >
            <h3>Add Validity</h3>
            <label style={{ alignSelf: "baseline", color: "#666", margin: 0 }}>
              Select Campaign
            </label>
            <select
              value={campaign}
              onChange={(e) => setCampaign(e.target.value)}
              style={{ minWidth: "150px", marginBottom: "10px" }}
            >
              {campaignList.map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
            <label style={{ alignSelf: "baseline", color: "#666", margin: 0 }}>
              Validity (in days)
            </label>
            <input
              style={{ minWidth: "150px" }}
              value={days}
              onChange={(e) => setDays(e.target.value)}
              placeholder="validity in days"
              style={{ marginBottom: "10px", minWidth: "150px" }}
              type="number"
              min="1"
              max="365"
            />
            <label style={{ alignSelf: "baseline", color: "#666", margin: 0 }}>Reason:</label>
            <textarea
              value={reason}
              onChange={e => setReason(e.target.value)}
              placeholder="provide reason"
              rows={4}
              cols={30}
            />

            <p style={{ color: "red" }}>{error}</p>
            <Button type="submit">Submit</Button>
          </form>
        </div>
      );
      break;
    case 2:
      screen = (
        <div>
          <p style={{ textAlign: "end" }}>
            <MdOutlineCancel onClick={clearData} style={{ fontSize: "25px" }} />
          </p>
          <form
            style={{ textAlign: "center" }}
            onSubmit={(e) => {
              e.preventDefault();
              applyCampaignPromotion(props.userId);
            }}
          >
            <p>
              Are you sure you want to increase the validity by{" "}
              <strong>{days}</strong> days under the campaign{" "}
              <strong>{campaign}</strong>
            </p>
            <p style={{ fontSize: '12px' }}>*Please enter the text "VALIDITY" as-is to confirm the action.</p>
            <input
              value={confirmationText}
              placeholder="VALIDITY"
              autoFocus
              onChange={e => setConfirmationText(e.target.value)}
            />
            <p style={{ color: "red" }}>{error}</p>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Button type="submit">Submit</Button>
              <Button type="button" onClick={clearData}>
                Close
              </Button>
            </div>
          </form>
        </div>
      );
      break;
    case 3:
      screen = (
        <div style={{ textAlign: "center" }}>
          <h2>Success</h2>
          <Button
            onClick={clearData}
          >
            Close
          </Button>
        </div>
      );
      break;
  }

  return (
    <span>
      <FiEdit2
        style={{ cursor: "pointer" }}
        onClick={() => setIsPopupOpen(true)}
      />
      <StyledPopup
        open={isPopupOpen}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div>{screen}</div>
      </StyledPopup>
    </span>
  );
}
