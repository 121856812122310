import moment from "moment";
import React, { useState } from "react";
import { TiCancel } from "react-icons/ti";
import Popup from "reactjs-popup";
import { sess_put } from "../../../services/HttpRequests";
import EditLinkPopup from "./EditLinkPopup";

function LinksDataTable(props) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [linkId, setLinkId] = useState("");
  let tableData;
  if (props.tableData && props.tableData.length > 0) {
    tableData = props.tableData.map((item) => (
      <tr key={item.link_id}>
        <td>{item.link_id}</td>
        <td>{item.amount / 100}</td>
        <td>{item.receipt}</td>
        <td>
          <p style={{ margin: "0" }}>
            <strong>Cst name: </strong>
            {item.customer_name || "-"}
          </p>
          <p style={{ margin: "0" }}>
            <strong>Cst mail: </strong>
            {item.customer_email || "-"}
          </p>
          <p style={{ margin: "0" }}>
            <strong>Cst no: </strong>
            {item.customer_number || "-"}
          </p>
        </td>
        <td>{item.link_url}</td>
        <td
          style={
            item.status === "paid"
              ? { fontWeight: "bold", color: "green" }
              : null
          }
        >
          {item.status}
        </td>
        <td>{moment(item.created_at).format("DD-MM-YYYY")}</td>
        <td>
          {item.link_expire_by
            ? moment(item.link_expire_by).format("DD-MM-YYYY")
            : "No Expiry"}
        </td>
        <td>
          <Popup
            open={isPopupOpen}
            onClose={() => setIsPopupOpen(false)}
            closeOnDocumentClick={false}
          >
            <div className="Modal">
              <p style={{ fontSize: "18px", fontWeight: "500" }}>
                Are you sure, you want to cancel the payment link?
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  margin: "20px 0",
                }}
              >
                <button onClick={cancelPaymentLink}>Confirm</button>
                <button onClick={() => setIsPopupOpen(false)}>Close</button>
              </div>
            </div>
          </Popup>

          {item.status === "created" ? (
            <EditLinkPopup
              data={item}
              editLink={(data, callback) => editPaymentLink(data, callback)}
            />
          ) : null}

          {item.status === "created" ? (
            <TiCancel
              onClick={() => {
                setIsPopupOpen(true);
                setLinkId(item.link_id);
              }}
              style={{ fontSize: "22px" }}
            />
          ) : null}
        </td>
        <td>{item.created_by || "-"}</td>
      </tr>
    ));
  }

  function cancelPaymentLink() {
    props.cancelLink({ link_id: linkId }, (res) => {
      if (res?.response?.data?.success === false) {
        console.log("err", res.response.data.error.reason);
      } else {
        console.log("res", res?.data?.response?.result);
      }
    });
  }

  function editPaymentLink(data, callback) {
    const session_token = localStorage.getItem("session_token");
    sess_put("admin/console/payment-link", session_token, data)
      .then((resp) => {
        callback(resp);
        window.location.reload();
      })
      .catch((error) => {
        callback(error);
      });
  }

  return (
    <div>
      <table className="newTable">
        <tr style={{ "background-color": "#e99794" }}>
          <th>Payment Link id</th>
          <th>Amount</th>
          <th>Receipt no.</th>
          <th>Client Details</th>
          <th>Payment Link</th>
          <th>Status</th>
          <th>Created At</th>
          <th>Expire By</th>
          <th>Action</th>
          <th>Created By</th>
        </tr>
        {tableData}
      </table>
    </div>
  );
}

export default LinksDataTable;
