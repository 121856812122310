import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';
import NavbarComponent from '../Others/NavbarComponent';
// import { Helmet } from "react-helmet";
import {
  Container,
  Form,
  Input,
  Alert,
  FormGroup,
  Label,
  FormFeedback,
  FormText,
  Row,
  Col,
  Button,
} from 'reactstrap';

import PasswordCheck from './PasswordCheck';
// import InCNavbar from './InCNavBar';
import '../../styles/passwordcheck.css';
import {
  loginUser,
  setUserFromLocalStorage,
  sendOtp,
  verifyOtp,
  resetPassword,
  resetState,
} from '../../actions/authActions';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number: '',
      otp: '',
      password: '',
      confirmPassword: '',
      resetLevel: 'enterNumber',
      charNumberValid: false,
      specialCharValid: false,
      uppercaseValid: false,
      lowercaseValid: false,
      numberValid: false,
      passwordsMatch: false,
    };
  }

  componentDidMount() {
    if (
      localStorage.getItem('doosra-user-sessionToken') &&
      localStorage.getItem('doosra-user-sessionToken') != null
    ) {
      this.props.history.push('/user-dashboard');
    }
  }

  checkPasswordLength = (password) => {
    if (password.length >= 15) {
      this.setState({
        charNumberValid: true,
      });
    } else {
      this.setState({
        charNumberValid: false,
      });
    }
  };

  // Check for special characters
  checkSpecialCharacters = (password) => {
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    if (pattern.test(password)) {
      this.setState({
        specialCharValid: true,
      });
    } else {
      this.setState({
        specialCharValid: false,
      });
    }
  };

  // Check for an uppercase character
  checkUppercase = (password) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      this.setState({
        uppercaseValid: true,
      });
    } else {
      this.setState({
        uppercaseValid: false,
      });
    }
  };

  // Check for an lowercase character
  checkLowercase = (password) => {
    const pattern = /[a-z]/;
    if (pattern.test(password)) {
      this.setState({
        lowercaseValid: true,
      });
    } else {
      this.setState({
        lowercaseValid: false,
      });
    }
  };

  // Check for a number
  checkNumber = (password) => {
    const pattern = /[0-9]/;
    if (pattern.test(password)) {
      this.setState({
        numberValid: true,
      });
    } else {
      this.setState({
        numberValid: false,
      });
    }
  };

  checkPasswordsMatch = () => {
    const { password, confirmPassword } = this.state;
    if (password && confirmPassword) {
      this.setState({ passwordsMatch: password === confirmPassword });
    }
  };

  verifyOtp = () => {
    // this.props.verifyOtp({otp,secret}).then((res)=>{
    // })
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value }, () => {
      this.checkPasswordsMatch();
    });
    if (e.target.id == 'password') {
      this.checkPasswordLength(e.target.value);
      this.checkSpecialCharacters(e.target.value);
      this.checkUppercase(e.target.value);
      this.checkLowercase(e.target.value);
      this.checkNumber(e.target.value);
    }
  };

  handleResetAndLogin = (e) => {
    e.preventDefault();
    let data = {
      new_password: this.state.password,
      confirm_password: this.state.confirmPassword,
      phone_number: this.props.auth.phone_number,
    };
    if (!this.state.charNumberValid) return;
    if (!this.state.specialCharValid) return;
    if (!this.state.uppercaseValid) return;
    if (!this.state.numberValid) return;
    if (!this.state.passwordsMatch) return;

    this.props.resetPassword(data);
  };

  render() {
    if (!this.props.auth.is_valid_OTP) {
      return <Redirect to="/verify-otp" />;
    }
    if (this.props.auth.reset_password) {
      this.props.resetState();
      return <Redirect to="/" />;
    }
    const { seconds, otpDisabled } = this.state;
    return (
      <div>
        <NavbarComponent />
        <Container>
          <Row className="mt-5">
            <Col xs="12" sm="12" md={{ size: 6, offset: 3 }}>
              <Form>
                <FormGroup>
                  <Input
                    value={this.state.phone_number}
                    placeholder="New Password"
                    id="password"
                    onChange={this.handleChange}
                    type="password"
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    value={this.state.confirmPassword}
                    placeholder="Confirm Password"
                    id="confirmPassword"
                    onChange={this.handleChange}
                    type="password"
                  />
                </FormGroup>
                <FormGroup style={{ textAlign: 'left' }}>
                  <Button
                    color="primary"
                    onClick={this.handleResetAndLogin}
                    disabled={seconds}
                    type="submit"
                  >
                    Reset Password{' '}
                  </Button>
                </FormGroup>
              </Form>
              {this.state.error && (
                <Alert variant="danger">{this.state.error}</Alert>
              )}
              {this.props.error && (
                <Alert variant="success">{this.props.error}</Alert>
              )}
              <PasswordCheck
                charNumberValid={this.state.charNumberValid}
                specialCharValid={this.state.specialCharValid}
                uppercaseValid={this.state.uppercaseValid}
                lowercaseValid={this.state.lowercaseValid}
                numberValid={this.state.numberValid}
                passwordsMatch={this.state.passwordsMatch}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.auth.error,
});

export default connect(mapStateToProps, { resetPassword, resetState })(
  ResetPassword
);
