import { get, post, sess_get, sess_post } from './HttpRequests';


async function GetGiftsList(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get('admin/console/get-gift-vouchers', session_token, params).then((resp) => {

      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }


    })
      .catch((error) => {
        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          resolve(error.response.data);
        }
        else {
          resolve({ "success": false, "error": { "code": 1003, "reason": "Something went wrong" } });
        }
      });

  });
  return (response.success !== undefined) ? response : {};
}

async function GetCouponsList(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_post('admin/console/get-gift-voucher-codes', session_token, params).then((resp) => {

      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }


    })
      .catch((error) => {
        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          resolve(error.response.data);
        }
        else {
          resolve({ "success": false, "error": { "code": 1003, "reason": "Something went wrong" } });
        }
      });

  });
  return (response.success !== undefined) ? response : {};
}


export {
  GetGiftsList,
  GetCouponsList
};