/* eslint-disable */
import moment from 'moment';
import React from 'react';
import { Table } from 'reactstrap';

function TableHeader() {
  return (
    <thead>
      <tr>
        <th>Date</th>
        <th>Time</th>
        <th>Action</th>
        <th>Agent</th>
        <th>Reason</th>
      </tr>
    </thead>
  );
}

// eslint-disable-next-line react/prefer-stateless-function
export default function CarePortalActivityList(props) {
  if (props?.list?.length === 0) return null;

  /* eslint-disable react/prop-types */

  const displayAction = (activity) => {
    if (activity?.action === 'CAMPAIGN_EXTENSION' && activity?.metadata?.extended_validity_in_days) {
      return `${activity?.action} (${activity?.metadata?.validity} days)`;
    }
    else if(activity?.action === 'CAMPAIGN_EXTENSION') {
      return `${activity?.action} (${activity?.metadata?.validity} months)`;
    }
    return activity?.action || '-';
  };

  const displayReason = (activity) => {
    if (activity?.metadata?.reason) {
      return activity?.metadata?.reason;
    }

    if (activity?.reason) {
      return activity?.reason;
    }

    return '-';
  };

  if (props?.list?.length > 0) {
    return (
      <>
        <h1 className="demo" style={{ color: 'brown' }}> Support Actions </h1>
        <Table responsive className="newTable" style={{ 'margin-bottom': '5px' }}>
          <TableHeader />
          <tbody>
            {props.list.map((activity) => (
              <tr>
                <td>{activity?.created_at ? moment(new Date(activity.created_at)).format('YYYY-MM-DD') : '-'}</td>
                <td>{activity?.created_at ? moment(new Date(activity.created_at)).format('HH:mm') : '-'}</td>
                <td>{displayAction(activity)}</td>
                <td>{activity?.action_by?.user_name || activity?.metadata?.updated_by}</td>
                <td>{displayReason(activity)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  }
}
