import React, { Component } from "react";
import "../../assets/css/nReports.css";

class KYCData extends Component {
  render() {
    return (
      <div className="table2 row-margin" style={{ "margin-top": "21px", "margin-left": "107px" }}>
        <h2 style={{ "font-weight": "600" }}>KYC Data</h2>
        <table className="newTable">
          <tr>
            <th>User Type</th>
            <th>No of Users</th>
            <th>Completed</th>
            <th>Not Completed</th>
            <th>Completion %</th>
          </tr>
          <tr>
            <td style={{ "font-weight": "bolder" }}>All</td>
            <td>{this.props.total}</td>
            <td>{this.props.totalCompleted}</td>
            <td>{this.props.totalNotCompleted}</td>
            <td>{this.props.percentage}</td>
          </tr>
          <tr>
            <td style={{ "font-weight": "bolder" }}>PRO</td>
            <td>{this.props.totalPRO}</td>
            <td>{this.props.totalCompletedPRO}</td>
            <td>{this.props.totalNotCompletedPRO}</td>
            <td>{this.props.percentagePRO}</td>
          </tr>
          <tr>
            <td style={{ "font-weight": "bolder" }}>Essential</td>
            <td>{this.props.totalEssential}</td>
            <td>{this.props.totalCompletedEssential}</td>
            <td>{this.props.totalNotCompletedEssential}</td>
            <td>{this.props.percentageEssential}</td>
          </tr>
        </table>
      </div>
    );
  }
}
export default KYCData;
