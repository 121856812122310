import { get, post, sess_get, sess_post } from './HttpRequests';


async function GetBaseReports(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get('admin/console/get-statistics', session_token, params).then((resp) => {

      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }


    })
      .catch((error) => {
        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          resolve(error.response.data);
        }
        else {
          resolve({ "success": false, "error": { "code": 1003, "reason": "Something went wrong" } });
        }
      });

  });
  return (response.success !== undefined) ? response : {};
}

async function GetDailyReports(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get('admin/console/daily-statistics', session_token, params).then((resp) => {

      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }


    })
      .catch((error) => {

        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          resolve(error.response.data);
        }
        else {
          resolve({ "success": false, "error": { "code": 1003, "reason": "Something went wrong" } });
        }
      });

  });
  return (response.success !== undefined) ? response : {};
}

async function OtherStatistics(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get('admin/console/other-statistics', session_token, params).then((resp) => {

      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }


    })
      .catch((error) => {

        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          resolve(error.response.data);
        }
        else {
          resolve({ "success": false, "error": { "code": 1003, "reason": "Something went wrong" } });
        }
      });

  });
  return (response.success !== undefined) ? response : {};
}

async function GetVerifiedProspectsReports(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get('admin/console/verified-prospects-statistics', session_token, params).then((resp) => {

      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }


    })
      .catch((error) => {

        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          resolve(error.response.data);
        }
        else {
          resolve({ "success": false, "error": { "code": 1003, "reason": "Something went wrong" } });
        }
      });

  });
  return (response.success !== undefined) ? response : {};
}

async function GetBouncedReports(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get('admin/console/bounced-statistics', session_token, params).then((resp) => {

      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }


    })
      .catch((error) => {

        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          resolve(error.response.data);
        }
        else {
          resolve({ "success": false, "error": { "code": 1003, "reason": "Something went wrong" } });
        }
      });

  });
  return (response.success !== undefined) ? response : {};
}

async function GetDeactivatedReports(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get('admin/console/deactivated-statistics', session_token, params).then((resp) => {

      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }


    })
      .catch((error) => {

        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          resolve(error.response.data);
        }
        else {
          resolve({ "success": false, "error": { "code": 1003, "reason": "Something went wrong" } });
        }
      });

  });
  return (response.success !== undefined) ? response : {};
}

async function GetTerminatedReports(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get('admin/console/terminated-statistics', session_token, params).then((resp) => {

      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }


    })
      .catch((error) => {

        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          resolve(error.response.data);
        }
        else {
          resolve({ "success": false, "error": { "code": 1003, "reason": "Something went wrong" } });
        }
      });

  });
  return (response.success !== undefined) ? response : {};
}


async function GetReferralStatistics(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get('admin/console/referral-statistics', session_token, params).then((resp) => {

      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }


    })
      .catch((error) => {

        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          resolve(error.response.data);
        }
        else {
          resolve({ "success": false, "error": { "code": 1003, "reason": "Something went wrong" } });
        }
      });

  });
  return (response.success !== undefined) ? response : {};
}


async function GetFreeTrialReport(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get('admin/console/get-free-trial-statistics', session_token, params).then((resp) => {

      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }


    })
      .catch((error) => {

        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          resolve(error.response.data);
        }
        else {
          resolve({ "success": false, "error": { "code": 1003, "reason": "Something went wrong" } });
        }
      });

  });
  return (response.success !== undefined) ? response : {};
}

async function GetProReport(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get('admin/console/get-pro-statistics', session_token, params).then((resp) => {

      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }


    })
      .catch((error) => {

        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          resolve(error.response.data);
        }
        else {
          resolve({ "success": false, "error": { "code": 1003, "reason": "Something went wrong" } });
        }
      });

  });
  return (response.success !== undefined) ? response : {};
}


async function GetUpgradedProReport(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get('admin/console/get-upgraded-pro-statistics', session_token, params).then((resp) => {

      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }


    })
      .catch((error) => {

        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          resolve(error.response.data);
        }
        else {
          resolve({ "success": false, "error": { "code": 1003, "reason": "Something went wrong" } });
        }
      });

  });
  return (response.success !== undefined) ? response : {};
}
async function RenewalStatistics(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get('admin/console/renewal-statistics', session_token, params).then((resp) => {

      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }


    })
      .catch((error) => {

        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          resolve(error.response.data);
        }
        else {
          resolve({ "success": false, "error": { "code": 1003, "reason": "Something went wrong" } });
        }
      });

  });
  return (response.success !== undefined) ? response : {};
}
async function GetUserStatistics(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get('admin/console/careportal/dashboard/users', session_token, params).then((resp) => {
      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }
    })
      .catch((error) => {

        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          resolve(error.response.data);
        }
        else {
          resolve({ "success": false, "error": { "code": 1003, "reason": "Something went wrong" } });
        }
      });

  });
  return (response.success !== undefined) ? response : {};
}

async function EngagementStatistics(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get('admin/console/careportal/dashboard/engagement', session_token, params).then((resp) => {
      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }
    })
      .catch((error) => {
        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          resolve(error.response.data);
        }
        else {
          resolve({ "success": false, "error": { "code": 1003, "reason": "Something went wrong" } });
        }
      });

  });
  return (response.success !== undefined) ? response : {};
}

async function VMNStatistics(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get('admin/console/careportal/dashboard/vmn', session_token, params).then((resp) => {
      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }
    })
      .catch((error) => {
        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          resolve(error.response.data);
        }
        else {
          resolve({ "success": false, "error": { "code": 1003, "reason": "Something went wrong" } });
        }
      });

  });
  return (response.success !== undefined) ? response : {};
}


async function ProspectsStatistics(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get('admin/console/careportal/dashboard/prospects', session_token, params).then((resp) => {
      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }
    })
      .catch((error) => {
        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          resolve(error.response.data);
        }
        else {
          resolve({ "success": false, "error": { "code": 1003, "reason": "Something went wrong" } });
        }
      });

  });
  return (response.success !== undefined) ? response : {};
}


export {
  GetBaseReports,
  GetDailyReports,
  OtherStatistics,
  GetVerifiedProspectsReports,
  GetBouncedReports,
  GetDeactivatedReports,
  GetTerminatedReports,
  GetReferralStatistics,
  GetFreeTrialReport,
  GetProReport,
  GetUpgradedProReport,
  RenewalStatistics,
  GetUserStatistics,
  EngagementStatistics,
  VMNStatistics,
  ProspectsStatistics
};