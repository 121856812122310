/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import GiftCards from './GiftCard/MainComponent';
import MessageLog from './Logs/MessageLog/MainComponent';
import SupportLogs from './Logs/SupportLogs';
import SupportLogging from './Logs/SupportLogging';
import HomeComponent from './Others/HomeComponent';
import KYCComponent from './KYC';
import AddKYC from './KYCAnalysis/AddKYC';
import KYCAnalysis from './KYCAnalysis/KYCAnalysis';
import NumberAnalyserComponent from './NumberAnalyser/NumberAnalyser';
// import PaymentLinks from './Payment/Links/PaymentLinks';
import DoosraPaymentLinks from './Payments/DoosraPaymentLinks';
// import NavbarComponent from './NavbarComponent';
import PaymentComponent from './Payments/History/PaymentComponent';
import Reports from './Report';
import Otp from './ResetPassword/Otp';
import ResetPassword from './ResetPassword';
import TwoFASendOtp from './TwoFA/TwoFASendOtp';
import Discount from './Discount/DiscountList';
import EditDiscount from './Discount/EditDiscount';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    render={(props) => (localStorage.getItem('session_token')
      && localStorage.getItem('session_token') !== null ? (
      // eslint-disable-next-line react/jsx-props-no-spreading
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location },
          }}
        />
      ))}
  />
);

PrivateRoute.propTypes = {
  location: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
};

function MainComponent() {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={HomeComponent} />
        <Route exact path="/home" component={HomeComponent} />
        <Route exact path="/verify-otp" component={Otp} />
        <Route exact path="/reset-password" component={ResetPassword} />

        <PrivateRoute exact path="/kyc" component={KYCComponent} />
        <PrivateRoute
          exact
          path="/numberanalyser"
          component={NumberAnalyserComponent}
        />
        <PrivateRoute
          exact
          path="/payments-history"
          component={PaymentComponent}
        />
        <PrivateRoute
          exact
          path="/doosra-payment-links"
          component={DoosraPaymentLinks}
        />
        <PrivateRoute exact path="/reports" component={Reports} />
        <PrivateRoute exact path="/gift-cards" component={GiftCards} />
        <PrivateRoute exact path="/message-log" component={MessageLog} />
        <PrivateRoute
          exact
          path="/support-logging"
          component={SupportLogging}
        />
        <PrivateRoute exact path="/support-log-view" component={SupportLogs} />
        <Route
          eaxact
          path="/2fa-reset-otp"
          render={(props) => (
            <TwoFASendOtp location={props.location} {...props} />
          )}
        />
        <PrivateRoute exact path="/kyc-analysis" component={KYCAnalysis} />
        <PrivateRoute exact path="/add-kyc" component={AddKYC} />
        <PrivateRoute exact path="/discount" component={Discount} />
        <PrivateRoute exact path="/discount/:id" component={EditDiscount} />
      </Switch>
    </div>
  );
}

export default MainComponent;
