import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Label, Form, Alert } from "reactstrap";
import { get, post, sess_get, sess_post } from "../../services/HttpRequests";
import NavbarComponent from "../Others/NavbarComponent";
import { Control, LocalForm, Errors } from "react-redux-form";
// import {
//   verifyOtp2FA,
//   sendOtp2FA,
//   resetState,
// } from "../../actions/authActions";

// import { Form, Col, Row, Button, Alert } from "react-bootstrap";
import { Redirect } from "react-router";
import { withRouter } from "react-router-dom";
// import { get, post, sess_get, sess_post } from "../../utils/HttpRequests";

const REACT_APP_REDIRECT_AFTER =
  process.env.REACT_APP_REDIRECT_AFTER * 1000 || 10 * 1000;
const REACT_APP_RESEND_OTP_AFTER = process.env.REACT_APP_RESEND_OTP_AFTER || 30;
class TwoFASendOtp extends Component {
  state = {
    otp: "",
    seconds: REACT_APP_RESEND_OTP_AFTER,
  };
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  async componentDidMount() {

    if (
      !this.props.location.state ||
      !Object.keys(this.props.location.state).length
    ) {
      this.props.history.push("/");
    }
    if (
      this.props.location.state &&
      Object.keys(this.props.location.state).length
    ) {
      this.initiateTimer();
    }
  }
  verifyOtp2FA = (e) => {
    // e.preventDefault();
    return new Promise((resolve, reject) => {
      const {user={}} = this.props.location.state;
      const { phone_number } = user;
      if (phone_number) {
        post("admin/console/verify-otp-2fa", { username:phone_number, otp:this.state.otp })
          .then((response) => {
            if (response.data !== undefined && response.data.success) {
              this.setState({
                error:
                  "Otp Verified successfully, you can add a new google auth device",
              });
              setTimeout(() => {
                this.props.history.push("/");
                this.setState({});
                // this.props.location.state.resetState();
              }, REACT_APP_REDIRECT_AFTER);
            }
          })
          .catch((err) => {
            if(err && err.response && err.response.data.error.reason)
            this.setState({error:err.response.data.error.reason});
            else{
              this.setState({error:'Error in verifying otp'})
            }
            reject(err);
          });
      }
    });
  };

  handle2FASendOtp = (e) => {
    // e.preventDefault();
    return new Promise((resolve, reject) => {
      let email = "";
      this.setState({error:'', otp:''})
      const { is_2FA_enabled, user } = this.props.location.state;
      if (is_2FA_enabled && user) {
        email = user.email;
        post("admin/console/send-otp-2fa", { username: email })
          .then((response) => {
            if (response.data !== undefined && response.data.success) {
              this.initiateTimer();
              return resolve(response);
            }
          })
          .catch((err) => {
            console.log(err);
            if (err.response && err.response.data && err.response.data.error) {
              return this.setState({
                errorMsg: err.response.data.error.reason,
              });
            }
            this.setState({ errorMsg: "validate2FATokenToken: Somethin went wrong" });
            return reject(err);
          });
      }
    });
  };
  initiateTimer = () => {
    clearInterval(this.timer);
    this.setState({ seconds: REACT_APP_RESEND_OTP_AFTER });
    this.timer = setInterval(() => {
      if (this.state.seconds <= 0) {
        clearInterval(this.timer);
        this.setState({ seconds: "" });
        return;
      }
      this.setState({ seconds: this.state.seconds - 1 });
    }, 1000);
  };
  render() {
    const { seconds } = this.state;
    return (
      <div>
        <NavbarComponent />
        <Container>
          <Row className="mt-5">
            <Col xs="12" sm="12" md={{ size: 8, offset: 4 }}>
              <LocalForm>
                <Row className="form-group">
                  <Col md={6}>
                    <Control.text
                      model=".otp"
                      id="otp"
                      name="otp"
                      placeholder="Enter otp sent to your registered mobile number"
                      className="form-control field"
                      onChange={this.onChange}
                      value={this.state.otp}
                    />
                    <Errors
                      className="text-danger"
                      model=".token"
                      show="touched"
                      messages={{
                        required: "Required",
                      }}
                    />
                  </Col>
                </Row>
                <Row className="form-group">
                  <Col md={6}>
                    <Button type="submit" onClick={this.verifyOtp2FA}>Submit</Button>
                    <Button
                      style={{ marginLeft: 10 }}
                      onClick={this.handle2FASendOtp}
                      disabled={seconds}
                      type="submit"
                    >
                      Resend Otp{" "}
                      {this.state.seconds
                        ? `in ${this.state.seconds} seconds`
                        : ""}
                    </Button>
                    
                  </Col>
                </Row>
                <Row className="form-group">
                    <Col md={6}>
                      {this.state.error && <Alert >{this.state.error}</Alert>}
                      </Col>
                    </Row>
              </LocalForm>
            </Col>
          </Row>
          {/* </Form> */}
        </Container>
      </div>
    );
  }
}

export default TwoFASendOtp;
