import React, { useState } from 'react';
import Popup from "reactjs-popup";
import Loader from 'components/Others/Loader';
import { sess_post } from 'services/HttpRequests';

export default function SuspendUser(props) {
  const [processing, setProcessing] = useState(false);
  const [reason, setReason] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const closeHandler = () => {
    setError('');
    setConfirmationMessage('');
    setReason('');
    setProcessing(false);
    if (success) { props.closeHandler(true) } else {
      props.closeHandler()
      setSuccess(false);
    };
  }

  const suspendUserHandler = () => {
    if (!reason) return setError('Provide reason');
    if (confirmationMessage !== 'SUSPEND') return setError('invalid confirmation value');
    setProcessing(true);
    const session_token = localStorage.getItem("session_token");
    sess_post("admin/console/suspend-user", session_token, { user_id: props.userId, suspended_reason: reason }
    )
      .then((res) => res.data)
      .then((res) => {
        setError('');
        setSuccess(true);
        setProcessing(false);
      })
      .catch((err) => {
        setError(err?.response?.data?.error?.reason);
        setProcessing(false);
      });
  }

  return (
    <Popup
      open={props.isOpen}
      onClose={props.closeHandler}
    >
      <div className="Modal">
        {processing ? (
          <div
            style={{ textAlign: "center", margin: "30px 0" }}
          >
            <Loader />
          </div>
        ) : (
          <React.Fragment>
            <h4
              style={{
                textAlign: "center",
                margin: "30px 0",
              }}
            >
              {success ? (
                <h5 style={{ color: "limegreen" }}>
                  User has been suspended
                </h5>
              ) : (
                "Are you sure you want to Suspend the User ?"
              )}
            </h4>
            {!success && (
              <React.Fragment>
                <form>
                  <label style={{ "margin-left": "35px" }}>
                    Reason:
                    <textarea value={reason} onChange={e => setReason(e.target.value)} style={{ "margin-top": "5px", width: '100%' }} />
                  </label>
                  <label style={{ "margin-left": "35px" }}>
                    <p style={{ fontSize: '12px', margin: 0 }}>Please enter the text "SUSPEND" as-is to confirm the action.</p>
                    <input value={confirmationMessage}
                      placeholder='SUSPEND'
                      onChange={e => setConfirmationMessage(e.target.value)} style={{ "margin-top": "5px" }} />
                  </label>
                </form>
                <p style={{ color: "red", textAlign: 'center' }}>
                  {error}
                </p>
                <button
                  onClick={suspendUserHandler}
                  style={{ margin: "20px" }}
                >
                  Confirm
                </button>
              </React.Fragment>
            )}
            <button
              onClick={closeHandler}
              style={{ "margin-left": "100px" }}
            >
              Close
            </button>
          </React.Fragment>
        )}
      </div>
    </Popup>
  )
}