import React, { Component } from 'react';
import {
  Button, Input, Col, Row, Container, Form,
} from 'reactstrap';
import { CSVReader } from 'react-papaparse';
import NavbarComponent from '../Others/NavbarComponent';
import {
  getNumberStatus,
  checkNumberStatus,
  checkNumberListStatus,
  getNumberStatusCsv,
} from '../../services/NumberAnalysersRequests';
import { GetDailyReports } from '../../services/ReportRequests';
import './NumberAnalyser.css';

const buttonRef = React.createRef();

class NumberAnalyserComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: '',
      number: '',
      updatedAt: '',
      createdAt: '',
      isLoading: false,
      csvUploadSuccess: false,
      initialStatus: {},
      lastStatus: {},
      trustChecker: {},
      services: [],
      startDate: new Date(),
      endDate: new Date(),
      disableDownload: false,
      filters: {
        amazon: false,
        flipkart: false,
        snapdeal: false,
        lenskart: false,
        swiggy: false,
        onemg: false,
        bewakoof: false,
        paytmmall: false,
        myntra: false,
      },
    };
  }

  componentDidMount() {
    GetDailyReports()
      .then((resp) => {
        this.setState({ dailyReport: resp.response });
      })
      .catch((err) => {
        console.log('err');
      });
  }

  handlephoneNumberChange = (event) => {
    const number = event.target.value;
    this.setState({
      ...this.state,
      number,
    });
  };

  history = (event) => {
    const { number } = this.state;
    if (number.length == 10) {
      this.setState({
        ...this.state,
        isLoading: true,
      });
      console.log('Getting number now');
      getNumberStatus(number)
        .then((data) => {
          if (!data) {
            this.setState({
              ...this.state,
              isLoading: false,
            });
            return;
          }
          for (const item in data.initialStatus) {
            if (data.initialStatus[item] == true) {
              data.initialStatus[item] = 'Registered';
            } else if (data.initialStatus[item] == false) {
              data.initialStatus[item] = 'Not Registered';
            }
          }
          for (const item in data.lastStatus) {
            if (data.lastStatus[item] == true) {
              data.lastStatus[item] = 'Registered';
            } else if (data.lastStatus[item] == false) {
              data.lastStatus[item] = 'Not Registered';
            }
          }
          console.log('Setting state now');
          this.setState(
            {
              ...this.state,
              initialStatus: data.initialStatus || {},
              lastStatus: data.lastStatus || {},
              services: [
                ...new Set([
                  ...Object.keys(data.initialStatus || {}),
                  ...Object.keys(data.lastStatus || {}),
                ]),
              ],
              updatedAt: data.updatedAt,
              createdAt: data.createdAt,
              isLoading: false,
            },
            () => {
              console.log('analyse');
              console.log(this.state);
            },
          );
        })
        .catch((e) => {
          console.log(e);
          this.setState({
            ...this.state,
            isLoading: false,
          });
        });
    }
    event.preventDefault();
  };

  analyse = (event) => {
    const { number } = this.state;
    if (number.length == 10) {
      this.setState({
        ...this.state,
        isLoading: true,
      });

      const searchParams = {};
      for (const property in this.state.filters) {
        if (this.state.filters[property] == true) {
          searchParams[property] = this.state.filters[property];
        }
      }
      checkNumberStatus(number, searchParams)
        .then((data) => {
          if (!data) {
            this.setState({
              ...this.state,
              // isLoading: false
            });
            return;
          }
          for (const item in data.initialStatus) {
            if (data.initialStatus[item] == true) {
              data.initialStatus[item] = 'Registered';
            } else if (data.initialStatus[item] == false) {
              data.initialStatus[item] = 'Not Registered';
            }
          }
          for (const item in data.lastStatus) {
            if (data.lastStatus[item] == true) {
              data.lastStatus[item] = 'Registered';
            } else if (data.lastStatus[item] == false) {
              data.lastStatus[item] = 'Not Registered';
            }
          }
          this.setState(
            {
              ...this.state,
              initialStatus: data.initialStatus || {},
              lastStatus: data.lastStatus || {},
              trustChecker: {
                associatedEmail: data.associated_email,
                associatedName: data.associated_name,
              },
              services: [
                ...new Set([
                  ...Object.keys(data.initialStatus || {}),
                  ...Object.keys(data.lastStatus || {}),
                ]),
              ],
              updatedAt: data.updatedAt,
              createdAt: data.createdAt,
              isLoading: false,
            },
            () => {
              console.log('In reanalyse');
              console.log(this.state);
            },
          );
        })
        .catch((e) => {
          this.setState({
            ...this.state,
            isLoading: false,
          });
        });
    }
    event.preventDefault();
  };

  analyseMultipleNumbers = () => {
    const numbersToBeUploaded = (this.state.numbersToBeUploaded || []).filter(
      (item) => item,
    );
    const searchParams = {};
    for (const property in this.state.filters) {
      if (this.state.filters[property] == true) {
        searchParams[property] = this.state.filters[property];
      }
    }
    this.setState({ msg: 'File upload started' });
    checkNumberListStatus(numbersToBeUploaded, searchParams)
      .then(({ response }) => {
        console.log('response', !response.success);
        if (!response.success) {
          console.log(response);
          this.setState({ csvUploadSuccess: false, msg: response.msg });
          // throw Error(response.result.message);
        } else {
          const { result } = response;
          this.setState({
            csvUploadSuccess: result.success,
            msg: result.message || result.status,
          });
        }
      })
      .catch((error) => {
        this.setState({
          csvUploadSuccess: false,
          msg: `${error?.response?.data?.response?.result?.msg}`,
        });
      });
  };

  handleOnDrop = (data) => {
    data = data.map((item) => item.data[0]);
    this.setState({
      numbersToBeUploaded: data,
    });
  };

  handleOnRemoveFile = () => {
    this.setState({
      numbersToBeUploaded: [],
      msg: '',
      csvUploadSuccess: false,
    });
  };

  handleStartDate = (event) => {
    this.setState({ startDate: event.target.value });
  };

  handleEndDate = (event) => {
    this.setState({ endDate: event.target.value });
  };

  getReport = async () => {
    this.setState({ disableDownload: true });
    const startDate = new Date(this.state.startDate).setHours(0, 0, 0, 0);
    const endDate = new Date(this.state.endDate).setHours(23, 59, 59, 999);
    // console.log({ startDate, endDate })
    const ans = await getNumberStatusCsv(startDate, endDate).catch((e) => null);
    // console.log(ans.data.response.csvString)
    if (
      ans
      && ans.data
      && ans.data.response
      && ans.data.response.csvString
      && ans.data.response.csvString
    ) {
      // Download the file as CSV
      var downloadLink = document.createElement('a');
      var blob = new Blob(['\ufeff', ans.data.response.csvString]);
      var url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = 'DataDump.csv'; // Name the file here
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      var downloadLink = document.createElement('a');
      var blob = new Blob(['\ufeff', 'errored']);
      var url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = 'DataDump.csv'; // Name the file here
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
    this.setState({
      disableDownload: false,
    });
    // window.open(`data:text/csv;charset=utf-8`, ans.data.response.csvString)
  };

  checkboxChangeHandler = (e) => {
    this.setState({
      ...this.state,
      filters: {
        ...this.state.filters,
        [e.target.id]: !this.state.filters[e.target.id],
      },
    });
  };

  setFilters = () => {
    console.log(this.state.filters);
  };

  resetFilters = () => {
    // console.log(this.state.filters)
    this.setState({
      filters: {
        amazon: false,
        flipkart: false,
        snapdeal: false,
        lenskart: false,
        swiggy: false,
        onemg: false,
        bewakoof: false,
        paytmmall: false,
        myntra: false,
      },
    });
  };

  render() {
    const {
      initialStatus, lastStatus, services, isLoading,
    } = this.state;
    return (
      <div>
        <NavbarComponent />
        <Container>
          <div className="number-analyser-section csv-container">
            <Input
              type="date"
              id="startDate"
              placeholder="Start Date"
              value={this.state.startDate}
              onChange={this.handleStartDate}
              className="form-control"
            />
            <Input
              type="date"
              id="endDate"
              placeholder="End Date"
              value={this.state.endDate}
              onChange={this.handleEndDate}
              className="form-control"
            />
            <Button
              type="submit"
              onClick={this.getReport}
              color="primary"
              style={{ float: 'left' }}
              className="kyc-filter-button"
            >
              Download CSV Report
            </Button>
          </div>
          <div className="number-analyser-section upload-container">
            {/* <Input type='file' /> */}
            <CSVReader
              onDrop={this.handleOnDrop}
              // onError={this.handleOnError}
              addRemoveButton
              removeButtonColor="#659cef"
              onRemoveFile={this.handleOnRemoveFile}
            >
              <span>Drop CSV file here or click to upload.</span>
            </CSVReader>
            <Button
              type="submit"
              onClick={this.analyseMultipleNumbers}
              color="primary"
              style={{ float: 'left' }}
              className="kyc-filter-button"
            >
              Upload & Analyse
            </Button>
          </div>
          <p style={{ color: 'green', textAlign: 'left' }}>
            Note: Numbers should not have 91 as prefix and must be of 10 digits
          </p>
          {this.state.csvUploadSuccess ? (
            <p style={{ color: 'green', textAlign: 'left' }}>
              {this.state.msg || 'File Upload Started'}
            </p>
          ) : (
            <p style={{ color: 'red', textAlign: 'left' }}>
              {this.state.msg}
              {' '}
            </p>
          )}

          <p style={{ color: '#888888', textAlign: 'left' }}>
            (add numbers list in single column)
          </p>
          {/* <div className='number-analyser-section'> */}
          <div className="number-analyser-section">
            <Input
              type="number"
              id="phone_number"
              name="Phone Number"
              placeholder="Analyse Phone Number"
              value={this.state.number}
              onChange={this.handlephoneNumberChange}
              className="form-control"
              autoFocus
            />
            <div className="button-group-number-analyser">
              <Button
                type="submit"
                onClick={this.history}
                color="primary"
                style={{ float: 'left' }}
                className="kyc-filter-button mov-left"
              >
                History
              </Button>
              <Button
                type="submit"
                onClick={this.analyse}
                color="primary"
                style={{ float: 'left' }}
                className="kyc-filter-button"
              >
                Analyse
              </Button>
            </div>
          </div>
          <div>
            <table className="FilterTable">
              <tr>
                <td>
                  <span>
                    <input
                      type="checkbox"
                      id="amazon"
                      onChange={this.checkboxChangeHandler}
                      checked={this.state.filters.amazon}
                    />
                    <label className="FilterLabel">amazon</label>
                  </span>
                </td>
                <td>
                  <span>
                    <input
                      type="checkbox"
                      id="flipkart"
                      onChange={this.checkboxChangeHandler}
                      checked={this.state.filters.flipkart}
                    />
                    <label className="FilterLabel">flipkart</label>
                  </span>
                </td>
                <td>
                  <span>
                    <input
                      type="checkbox"
                      id="onemg"
                      onChange={this.checkboxChangeHandler}
                      checked={this.state.filters.onemg}
                    />
                    <label className="FilterLabel">
                      onemg
                      {' '}
                      <strong>(OTP)</strong>
                    </label>
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>
                    <input
                      type="checkbox"
                      id="lenskart"
                      onChange={this.checkboxChangeHandler}
                      checked={this.state.filters.lenskart}
                    />
                    <label className="FilterLabel">
                      lenskart
                      {' '}
                      <strong>(OTP)</strong>
                    </label>
                  </span>
                </td>
                <td>
                  <span>
                    <input
                      type="checkbox"
                      id="bewakoof"
                      onChange={this.checkboxChangeHandler}
                      checked={this.state.filters.bewakoof}
                    />
                    <label className="FilterLabel">bewakoof</label>
                  </span>
                </td>
                <td>
                  <span>
                    <input
                      type="checkbox"
                      id="swiggy"
                      onChange={this.checkboxChangeHandler}
                      checked={this.state.filters.swiggy}
                    />
                    <label className="FilterLabel">
                      swiggy
                      {' '}
                      <strong>(OTP)</strong>
                    </label>
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>
                    <input
                      type="checkbox"
                      id="snapdeal"
                      onChange={this.checkboxChangeHandler}
                      checked={this.state.filters.snapdeal}
                    />
                    <label className="FilterLabel">
                      snapdeal
                      {' '}
                      <strong>(OTP)</strong>
                    </label>
                  </span>
                </td>
                {/*
                  <td>
                  <span>
                  <input type="checkbox" id="myntra" onChange={this.checkboxChangeHandler} checked={this.state.filters.myntra} /><label className="FilterLabel">myntra</label>
                  </span>
                  </td>
                  <td>
                  <span>
                  <input type="checkbox" id="paytmmall" onChange={this.checkboxChangeHandler} checked={this.state.filters.paytmmall} /><label className="FilterLabel">paytmmall</label>
                  </span>
                  </td>
                */}
              </tr>
            </table>
            {/*
              <button className="kyc-filter-button" onClick={this.setFilters}>set</button>
            */}
            <button className="btn-primary btn" onClick={this.resetFilters}>
              Reset
            </button>
          </div>
          <Row className="status-div">
            <div>
              Created At:
              {(this.state.createdAt
                && new Date(this.state.createdAt).toLocaleDateString())
                || ''}
            </div>
            <div>
              Last Updated At:
              {(this.state.updatedAt
                && new Date(this.state.updatedAt).toLocaleDateString())
                || ''}
            </div>
          </Row>
          {Object.keys(this.state.trustChecker).length ? (
            <table>
              <tr>
                <td>Name</td>
                <td>
                  {this.state.trustChecker.associatedName
                    ? this.state.trustChecker.associatedName
                    : '-'}
                </td>
              </tr>
              <tr>
                <td>Email</td>
                <td>
                  {this.state.trustChecker.associatedEmail
                    ? this.state.trustChecker.associatedEmail
                    : '-'}
                </td>
              </tr>
            </table>
          ) : null}
          {!isLoading && (
            <table className="mt-2">
              <thead>
                <tr>
                  <th>Service</th>
                  <th>Initial Status</th>
                  <th>Current Status</th>
                </tr>
              </thead>
              <tbody>
                {services.map((div) => (
                  <tr key={div}>
                    <td>{div}</td>
                    <td
                      className={`${initialStatus[div] == 'Registered' && 'success'
                      } ${initialStatus[div] == 'Not Registered' && 'danger'
                      } ${initialStatus[div] == 'errored' && 'warning'}`}
                    >
                      {initialStatus[div] || 'Not Checked'}
                    </td>
                    <td
                      className={`${lastStatus[div] == 'Registered' && 'success'
                      } ${lastStatus[div] == 'Not Registered' && 'danger'} ${lastStatus[div] == 'errored' && 'warning'
                      }`}
                    >
                      {lastStatus[div] || 'Not Checked'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {isLoading && (
            <div>
              <img src={require('../../assets/loader.svg')} />
            </div>
          )}
          {/* </div> */}
        </Container>
      </div>
    );
  }
}

export default NumberAnalyserComponent;
