import React, { Component } from 'react';
import '../../assets/css/nReports.css';

class RenewalsTable extends Component {
  render() {
    return (
      <div className="table2 row-margin" style={{ 'margin-top': '21px', 'margin-left': '107px' }}>
        <h2 style={{ 'font-weight': '600' }}> User Retention</h2>
        <table className="newTable">
          <tr>
            <th>Duration (trailing Days)</th>
            <th>Renewals (Count/%)</th>
            <th>Inorganic (Count/%)</th>
            <th>Organic (Count/%)</th>
          </tr>
          <tr>
            <td style={{ 'font-weight': 'bolder', 'padding-left': '30px' }}>15</td>
            <td>
              {this.props.last15days}
              {' '}
              (
              {this.props.last15dayspercentage}
              %)
            </td>
            <td>
              {this.props.last15inorganic}
              {' '}
              (
              {this.props.last15inorganic_percentage}
              %)
            </td>
            <td>
              {this.props.last15organic}
              {' '}
              (
              {this.props.last15organic_percentage}
              %)
            </td>
          </tr>
          <tr>
            <td style={{ 'font-weight': 'bolder', 'padding-left': '30px' }}>30</td>
            <td>
              {this.props.last30days}
              {' '}
              (
              {this.props.last30dayspercentage}
              %)
            </td>
            <td>
              {this.props.last30inorganic}
              {' '}
              (
              {this.props.last30inorganic_percentage}
              %)
            </td>
            <td>
              {this.props.last30organic}
              {' '}
              (
              {this.props.last30organic_percentage}
              %)
            </td>
          </tr>
          <tr>
            <td style={{ 'font-weight': 'bolder', 'padding-left': '30px' }}>180</td>
            <td>
              {this.props.last180days}
              {' '}
              (
              {this.props.last180dayspercentage}
              %)
            </td>
            <td>
              {this.props.last180inorganic}
              {' '}
              (
              {this.props.last180inorganic_percentage}
              %)
            </td>
            <td>
              {this.props.last180organic}
              {' '}
              (
              {this.props.last180organic_percentage}
              %)
            </td>
          </tr>
        </table>
      </div>
    );
  }
}

export default RenewalsTable;
