import NavbarComponent from 'components/Others/NavbarComponent';
import React, { useEffect, useState } from 'react';
import { sess_get } from 'services/HttpRequests';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { useHistory } from 'react-router-dom';
import 'react-accessible-accordion/dist/fancy-example.css';

export default function ViewDiscount() {
  const [discountCoupons, setDiscountCoupons] = useState([]);
  const history = useHistory();
  useEffect(() => {
    const session_token = localStorage.getItem('session_token');
    sess_get('admin/console/discount-coupon-list', session_token, { portal: 'CARE' })
      .then((res) => {
        const a = res?.data?.response;
        if (a.length > 0) {
          a.map((coup) => {
            if (!coup._id || coup._id === null) setDiscountCoupons(coup.docs);
          });
        }
      })
      .catch((error) => {
        /* eslint-disable no-console */
        console.error(error);
      });
  }, []);

  const viewDiscountDetails = (id) => {
    history.push(`/discount/${id}`);
  };

  return (
    <div>
      <NavbarComponent />
      <div style={{ width: '90%', margin: '20px auto' }}>
        <Accordion allowZeroExpanded>
          <AccordionItem key={1}>
            <AccordionItemHeading>
              <AccordionItemButton>Discount Coupons</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <table className="newTable" bordered hover responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Code</th>
                    <th>User Type</th>
                    <th>Flat Discount</th>
                    <th>Discount Percentage</th>
                    <th>percentage Cap</th>
                    {/* <th>Usage Limit</th> */}
                    <th>
                      <div>Used/Limit</div>
                      <div>Pending</div>
                    </th>
                    <th>Start Date</th>
                    <th>Expiry Date</th>
                    <th>Coupon Users</th>
                    <th>Active</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {discountCoupons.map((item, index) => {
                    const rowno = index;
                    let user_type = 'All Users';
                    switch (item.user_type) {
                      case 'all_users':
                        user_type = 'All Users';
                        break;
                      case 'new_users':
                        user_type = 'New Users';
                        break;
                      case 'existing_users':
                        user_type = 'Existing Users';
                        break;
                      default:
                        user_type = 'All Users';
                        break;
                    }

                    return (
                      <tr key={rowno}>
                        <td>{rowno + 1}</td>
                        <td>{item.code}</td>
                        <td>{user_type}</td>
                        <td>{item.flat}</td>
                        <td>{item.percentage}</td>
                        <td>{item.percentageCap}</td>
                        <td>
                          <div>
                            {item.used || '-'}
                            /
                            {item.limit || '-'}
                          </div>
                          <div>{item.pending}</div>
                        </td>
                        <td>{item.startDate.split('T')[0]}</td>
                        <td>{item.expiryDate.split('T')[0]}</td>
                        <td
                          className="edit"
                          aria-hidden="true"
                        // onClick={() => {
                        //   item.users
                        //     && item.users.length
                        //   // && this.showCouponUserList(item.users);
                        // }}
                        >
                          <a className="edit-button">
                            {item.users && item.users.length
                              ? 'Users'
                              : '-'}
                          </a>
                        </td>
                        <td>{item.active.toString()}</td>
                        <td
                          className="edit"
                          aria-hidden="true"
                        // onClick={this.redirectToCoupon(item._id)}
                        >
                          <a className="edit-button" onClick={() => viewDiscountDetails(item._id)}>Edit</a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {/* <AddNumberCSVModal
                id={addNumberCSVModal}
                show={!!addNumberCSVModal}
                close={this.handleModalClose}
              /> */}
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
}
