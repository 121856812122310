import React, { Component, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import Popup from 'reactjs-popup';
import Loader from 'components/Others/Loader';
import { sess_get, sess_post } from 'services/HttpRequests';
import { toast } from 'react-toastify';
import { GetPayments } from '../../services/KYCRequests';
import DownloadIcon from '../../assets/images/download_icon_red.png';

function TableHeader() {
  return (
    <thead>
      <tr>
        <th>#</th>
        <th>Payment Id</th>
        <th>Status</th>
        <th>Paid At</th>
        <th>Amount</th>
        <th>Method</th>
        <th>Source</th>
        <th>Category</th>
        <th>Invoice</th>
        <th>Refund</th>
      </tr>
    </thead>
  );
}

function GetMethodDetails(payment) {
  if (payment.method == 'card') {
    if (payment?.card_details) {
      return `${payment?.card_details?.network}(${payment?.card_details?.last4})`;
    }
  } else if (payment?.method == 'wallet') {
    return payment?.wallet;
  } else if (payment?.method == 'upi') {
    return payment?.upi_details;
  } else {
    return payment?.bank;
  }
}

function PaymentRow({ payment, id, makeRefund }) {

  const handleInvoiceDownload = (id) => {

    const session_token = localStorage.getItem("session_token");
    sess_get('admin/console/invoice-download-url', session_token, { invoice_no: id })
      .then((resp) => {
        if (resp?.data?.response?.url?.url) {
          window.open(resp?.data?.response?.url?.url);
        }
      })
      .catch((err) => {
        console.log('err', err);
      })
  }


  function refundStatus(status) {
    switch (status) {
      case 'processed':
        return <div>PROCESSED</div>;

      case 'created':
        return <div>CREATED</div>;

      default:
        return <button onClick={() => makeRefund(payment.payment_id)} style={{ cursor: 'pointer' }}>Refund</button>;
    }
  }

  return (

    <>
      <td>{id + 1}</td>
      <td>{payment?.payment_id || '-'}</td>
      <td>{payment?.status || '-'}</td>
      <td>{payment?.payment_created_at ? new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: '2-digit' }).format(new Date(Date.parse(payment.payment_created_at))) : '-'}</td>
      <td>{payment?.amount / 100 || '-'}</td>
      <td>{payment?.method || '-'}</td>
      <td>{GetMethodDetails(payment)}</td>
      <td>{payment?.sub?.length > 0 ? 'SUB' : 'STP'}</td>
      <td>
        {payment?.invoice_no ? (
          <img src={DownloadIcon} onClick={() => handleInvoiceDownload(payment.invoice_no)} />
        ) : '-'}
      </td>
      <td>
        {refundStatus(payment.refund_status)}
      </td>
    </>
  );
}

export default function PaymentDetails(props) {
  const [isPaymentDetailsFetched, setIsPaymentDetailsFetched] = useState(false);
  const [payments, setPayments] = useState([]);
  const [paymentId, setPaymentId] = useState(''); // forRefund
  const [refundPopup, setRefundPopup] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isPartialRefund, setIsPartialRefund] = useState(false);
  const [partialAmount, setPartialAmount] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [reason, setReason] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const params = {
      user_id: props.user_id,
    };
    GetPayments(params).then((resp) => {
      if (resp && resp.success) {
        setPayments(resp?.response?.payments || []);
        setIsPaymentDetailsFetched(true);
      }
    });
  }, []);

  const processRefund = () => {
    const session_token = localStorage.getItem('session_token');
    const data = { reason };
    if (confirmationMessage !== 'REFUND') return setError('Invalid confirmation message');
    setError('');
    setProcessing(true);
    data.paymentId = paymentId;
    data.isPartialRefund = isPartialRefund;
    if (isPartialRefund) { data.partialAmount = partialAmount * 100; }
    sess_post('admin/console/refund-user', session_token, data)
      .then((res) => {
        setRefundPopup(false);
        toast.success('User refund initiated');
        setProcessing(false);
        window.location.reload();
      })
      .catch((err) => {
        setProcessing(false);
        setError(err?.response?.data?.error?.reason || 'An error occured');
      });
  };

  if (payments.length > 0) {
    return (
      <>
        <Table responsive className="newTable">
          <TableHeader />
          <tbody>
            {
              payments.map((payment, index) => (
                <tr key={payment._id}>
                  <PaymentRow payment={payment} id={index} makeRefund={(id) => { setRefundPopup(true); setPaymentId(id); }} />
                </tr>
              ))
            }
          </tbody>
        </Table>
        <Popup open={refundPopup} closeOnDocumentClick={false} closeOnEscape={false}>
          <div style={{
            textAlign: 'center', width: '400px', minHeight: '300px', padding: '20px',
          }}
          >
            <h2 style={{ marginBottom: '20px' }}>Initiate refund for user</h2>
            {processing && <Loader />}
            {!processing && (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '10px' }}>
                  <label>
                    <input style={{ marginRight: '8px' }} checked={!isPartialRefund} type="checkbox" onChange={() => setIsPartialRefund(false)} />
                    Full refund
                  </label>
                  <label>
                    <input style={{ marginRight: '8px' }} checked={isPartialRefund} type="checkbox" onChange={() => setIsPartialRefund(true)} />
                    Partial refund
                  </label>
                </div>
                {
                  isPartialRefund
                  && (
                    <div>
                      <h4>Partial refund amount</h4>
                      <input
                        type="number"
                        value={partialAmount}
                        onChange={(e) => setPartialAmount(e.target.value.replace(/^0+/, ''))}
                        placeholder="partial value amount"
                      />
                    </div>
                  )
                }
                <label>
                  <p style={{ width: '240px' }}> Reason:</p>
                  <textarea value={reason} onChange={(e) => { setReason(e.target.value); }} style={{ width: '100%' }} />
                </label>
                <p style={{ margin: 'auto', width: '240px' }}>Please enter the text "REFUND" as-is to confirm the action.</p>
                <input
                  value={confirmationMessage}
                  placeholder="REFUND"
                  onChange={(e) => setConfirmationMessage(e.target.value)}
                  style={{ 'margin-top': '5px' }}
                />
                <p style={{ color: 'red' }}>{error}</p>
                <div style={{ margin: '20px', display: 'flex', justifyContent: 'space-around' }}>
                  <button onClick={processRefund}>Proceed</button>
                  <button onClick={() => setRefundPopup(false)}>Close</button>
                </div>
              </>
            )}
          </div>
        </Popup>
      </>
    );
  }

  if (payments.length == 0 && isPaymentDetailsFetched) {
    return (
      <p>No Details Found</p>
    );
  }
  return (
    <p>Loading.....</p>
  );
}
