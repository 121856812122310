/* eslint-disable */
import moment from 'moment';
import React, { Component, useEffect, useState } from 'react';
// import { useHistory } from "react-router-dom";
import { Container } from 'reactstrap';
import Loader from 'components/Others/Loader';
import { useHistory } from 'react-router-dom';
import {
  GetUserAccountDetails,
  GetUserMinuteDetails,
  GetUserWalletBalance,
  GetUsersList,
} from '../../services/KYCRequests';
import NavbarComponent from '../Others/NavbarComponent';
import KYCFilters from './KYCFilters';
import UserActivityDetails from './UserActivityDetails';
import UserDetails from './UserDetails';
import CarePortalActivityList from './CarePortalActivityList';
import UserKycActivity from './UserKycActivity';

export function ShowUsersIfFetched(props) {
  const { isUsersFetched } = props;
  const { userDetails } = props;
  const { userMinutes } = props;
  const { userWalletBalance } = props;
  const { userActivityDetails } = props;
  if (isUsersFetched) {
    if (userDetails && userDetails.user_id) {
      return (
        // <KYCTable users={this.state.users} />
        <>
          <UserDetails
            userDetails={userDetails}
            userMinutes={userMinutes}
            userWalletBalance={userWalletBalance}
          />
          <h1 className="demo" style={{ color: 'brown' }}>User Activity Details</h1>
          <UserActivityDetails userActivityDetails={userActivityDetails} />
          <CarePortalActivityList list={props.carePortalActivity} />
          <UserKycActivity list={props.kycDetails} />
        </>
      );
    }
  }
  return <div />;
}

export function UsersList(props) {
  const history = useHistory();

  const fetchDetails = (userId) => {
    let specificUser = window.location.search;
    specificUser += `&fixed-user=${userId}`;
    props.fetchProfileDetails(userId);
    history.push({ search: specificUser });
  };
  if (props?.usersList?.length > 0) {
    return (
      <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
        {
          props?.usersList?.map((user) => (
            <div
              style={{
                border: '1px solid black', borderRadius: '8px', padding: '10px', cursor: 'pointer', textAlign: 'left',
              }}
              onClick={() => { user.hasProfile && fetchDetails(user.user_id); }}
            >
              <p>
                User_id :
                {' '}
                {user.user_id || '-'}
              </p>
              <p>
                Mobile no :
                {' '}
                {user.zvr_mobile_no || '-'}
              </p>
              <p>
                Doosra no :
                {' '}
                {user.v_mobile_no || '-'}
              </p>
              <p>
                User type :
                {' '}
                {user.user_type || '-'}
              </p>
            </div>
          ))
        }
      </div>
    );
  }
  if (props?.usersList?.length === 0) {
    return <div>No user found</div>;
  }
  return null;
}

export default function KYCComponent() {
  useEffect(() => {
    // const userName = localStorage.getItem('username') || 'Doosra';
    // const isLogged = localStorage.getItem('isLogged');
    const sc = new URLSearchParams(window.location.search).get('searchCategory');
    const st = new URLSearchParams(window.location.search).get('searchText');
    const fixedUser = new URLSearchParams(window.location.search).get('fixed-user');
    if (sc && st) {
      fetchUsersList(sc, st, fixedUser);
    }
  }, []);

  const [userMinutes, setUserMinutes] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [userActivityDetails, setUserActivityDetails] = useState([]);
  const [walletBalance, setWalletBalance] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [searchType, setSearchType] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [carePortalActivity, setCarePortalActivity] = useState([]);
  const [kycDetails, setKycDetails] = useState([]);
  const [error, setError] = useState('');
  const [usersList, setUsersList] = useState(null);
  const [userDetailsFetched, setUserDetailsFetched] = useState(false);
  const [displayDetails, setDisplayDetails] = useState(false);
  const history = useHistory();

  const fetchUsersList = (searchCategory, searchText, fixedUser, { searchArea } = {}) => {
    setSearchValue(searchText);
    setSearchType(searchCategory);
    const urlParams = new URLSearchParams();
    urlParams.append('searchCategory', searchCategory);
    urlParams.append('searchText', searchText);
    if (fixedUser) urlParams.append('fixed-user', fixedUser);
    if (searchArea) urlParams.append('searchArea', searchArea);

    history.push({ search: urlParams.toString() });
    const params = {};
    if (
      searchCategory
      && searchCategory.length > 0
      && searchText
      && searchText.length > 0
    ) {
      params.search_category = searchCategory;
      params.search_text = searchText;
      params.searchArea = searchArea;
    }

    setSubmitting(true);
    setDisplayDetails(false);
    if (fixedUser) {
      fetchProfileDetails(fixedUser);
      setSubmitting(false);
      setDisplayDetails(true);
      return;
    }
    if (searchText && searchText.length > 0) {
      GetUsersList(params).then((resp) => {
        if (resp && resp.success) {
          const { usersList } = resp.response;
          if (usersList.length == 1 && usersList[0].hasProfile) {
            setDisplayDetails(true);
            fetchProfileDetails(usersList[0].user_id);
          } else {
            setUsersList(usersList);
          }
          setError('');
          setSubmitting(false);
        }
      }).catch((err) => {
        setSubmitting(false);
      });
    }
  };

  const fetchProfileDetails = (user_id) => {
    setDisplayDetails(true);
    setSubmitting(true);
    setUserDetailsFetched(false);
    GetUserAccountDetails({ user_id }).then((resp) => {
      if (resp && resp.success) {
        const { profileDetails } = resp.response;
        const { userActivity } = resp.response;
        const { carePortalActivity } = resp.response;
        const { kycActivity } = resp.response;
        setUserActivityDetails(userActivity);
        setUserDetails(profileDetails);
        setCarePortalActivity(carePortalActivity);
        setKycDetails(kycActivity);
        setUserDetailsFetched(true);
        setError('');
        setSubmitting(false);
        const userId = profileDetails.user_id;
        GetUserMinuteDetails({ user_id }).then((mresp) => {
          if (mresp && mresp.success) {
            const minuteDetails = mresp.response;
            setUserMinutes(minuteDetails);
          }
        });
        GetUserWalletBalance({ user_id }).then((resp) => {
          if (resp && resp.success) {
            const balance = resp?.response?.walletDetails?.balance || 0;
            setWalletBalance(balance);
          }
        });
      }
    }).catch((err) => {
      setError(err?.error?.reason);
      setSubmitting(false);
    });
  };

  return (
    <div>
      <NavbarComponent />
      <Container>
        <KYCFilters fetchProfiles={fetchUsersList} />
        <p style={{ color: 'red' }}>{error}</p>
        {submitting
          ? (
            <div style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', height: '600px',
            }}
            >
              <Loader />
            </div>
          )
          : (
            <>
              {
                displayDetails
                  ? (
                    <ShowUsersIfFetched
                      isUsersFetched={userDetailsFetched}
                      userDetails={userDetails}
                      userMinutes={userMinutes}
                      userActivityDetails={userActivityDetails}
                      userWalletBalance={walletBalance}
                      carePortalActivity={carePortalActivity}
                      kycDetails={kycDetails}
                    />
                  )
                  : <UsersList usersList={usersList} fetchProfileDetails={fetchProfileDetails} />
              }
            </>
          )}
      </Container>
    </div>
  );
}
