import React, { Component } from 'react';
import Navbar from '../../Others/NavbarComponent';
import { Button, Row, Col } from 'reactstrap';
import Payments from './Payments';
import Insights from './Insights';
import Orders from './Orders';
import Subscriptions from './Subscriptions';
import Refunds from './Refunds';

class PaymentComponent extends Component {
  constructor() {
    super();
    this.state = {
      current: 'Payments'
    }
  }


  selectPayments = () => {
    this.setState({ current: 'Payments' });
  }
  selectOrders = () => {
    this.setState({ current: 'Orders' });
  }
  selectSubscriptions = () => {
    this.setState({ current: 'Subscriptions' })
  }
  // selectInsights = () => {
  //   this.setState({ current: 'Insights' })
  // }
  selectRefunds = () => {
    this.setState({ current: 'Refunds' });
  }

  render() {
    let currentView;
    switch (this.state.current) {
      case 'Payments':
        currentView = <Payments />
        break;
      case 'Orders':
        currentView = <Orders />
        break;
      case 'Subscriptions':
        currentView = <Subscriptions />
        break;
      case 'Refunds':
        currentView = <Refunds />
        break;
      default:
        currentView = <Payments />
        break;
    }


    return (
      <div>
        <Navbar />
        <Row>
          <Col md={1}></Col>
          <Col md={6} style={{ 'padding-top': '30px', 'padding-bottom': '30px', 'text-align': 'left' }}>
            <Button outline color="primary" onClick={this.selectPayments} active={(this.state.current == 'Payments') ? true : false}>Payments</Button>{' '}
            <Button outline color="secondary" onClick={this.selectOrders} active={(this.state.current == 'Orders') ? true : false}>Orders</Button>{' '}
            <Button outline color="success" onClick={this.selectSubscriptions} active={(this.state.current == 'Subscriptions') ? true : false}>Subscriptions</Button>{' '}
            <Button outline color="info" onClick={this.selectRefunds} active={(this.state.current == 'Refunds') ? true : false}>Refunds</Button>
          </Col>
          <Col md={3}></Col>
        </Row>
        <Row>
          <Col md={1} sm={1}></Col>
          <Col md={10} sm={10}>
            {currentView}
          </Col>
          <Col md={1} sm={1}></Col>
        </Row>
      </div>
    )
  }
}


export default PaymentComponent;