/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
import axios from 'axios';
import imageCompression from 'browser-image-compression';
import React, { Component } from 'react';
import Popup from 'reactjs-popup';
// import 'reactjs-popup/dist/index.css';
import '../../assets/css/modal.css';
import Compress from 'compress.js';
import {
  getCaptchaForAadhaar,
  verifyAadhaar,
  verifyDL,
  verifyPassport,
  verifyVoterId,
} from '../../services/KYCAnalysis';
import { getUserDetailsFromId } from '../../services/KYCRequests';
// import { GetDailyReports } from '../../services/ReportRequests';
import Loader from '../Others/Loader';
import Navbar from '../Others/NavbarComponent';

const compress = new Compress();
const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

class AddKYC extends Component {
  constructor() {
    super();
    this.state = {
      one: true,
      two: false,
      three: false,
      four: false,
      userId: null,
      userDetails: {},
      userDoesNotExist: false,
      userExists: false,
      detailsVerified: false,
      verificationError: false,
      confirmBeforeUpload: false,
      documentType: 'DL',
      nameOnDocument: null,
      // documentNumber: null,
      phoneNumberOnDocument: null,
      documentId: null,
      fatherName: null,
      DOB: null,
      DOE: null,
      DOI: null,
      placeOfIssue: null,
      // documentExpiryDate: null,
      gender: null,
      address: null,
      fileNo: null,
      document_front_photo: null,
      document_back_photo: null,
      user_pic: null,
      captchaData: {},
      filledCaptcha: null,
      loading: false,
      dlData: {},
      voterIdData: {},
      // passportData: {},
      // aadhaarData: {},
      documentsUploadError: '',
      KYCDocumentsUploaded: false,
      userError: '',
      referenceId: ''
    };
  }

  selectOne = () => {
    const { detailsVerified } = this.state;
    if (detailsVerified) {
      this.onReset();
    }
    this.setState({
      one: true,
      two: false,
      three: false,
      four: false,
      documentType: 'DL',
      verificationError: '',
    });
  };

  selectTwo = () => {
    const { detailsVerified } = this.state;
    if (detailsVerified) {
      this.onReset();
    }
    this.setState({
      one: false,
      two: true,
      three: false,
      four: false,
      documentType: 'Aadhaar',
      verificationError: '',
    });
  };

  selectThree = () => {
    const { detailsVerified } = this.state;
    if (detailsVerified) {
      this.onReset();
    }
    this.setState({
      one: false,
      two: false,
      three: true,
      four: false,
      documentType: 'Voter ID',
      verificationError: '',
    });
  };

  selectFour = () => {
    const { detailsVerified } = this.state;
    if (detailsVerified) {
      this.onReset();
    }
    this.setState({
      one: false,
      two: false,
      three: false,
      four: true,
      documentType: 'Passport',
      verificationError: '',
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onReset = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      one: true,
      two: false,
      three: false,
      four: false,
      userId: null,
      userDetails: {},
      userDoesNotExist: false,
      userExists: false,
      detailsVerified: false,
      verificationError: '',
      confirmBeforeUpload: false,
      documentType: 'DL',
      nameOnDocument: null,
      // documentNumber: null,
      // documentExpiryDate: null,
      // passportData: {},
      // aadhaarData: {},
      phoneNumberOnDocument: null,
      documentId: null,
      fatherName: null,
      DOB: null,
      DOE: null,
      DOI: null,
      placeOfIssue: null,
      gender: null,
      address: null,
      fileNo: null,
      document_front_photo: null,
      document_back_photo: null,
      user_pic: null,
      captchaData: {},
      filledCaptcha: null,
      loading: false,
      dlData: {},
      voterIdData: {},
      documentsUploadError: '',
      KYCDocumentsUploaded: false,
      userError: '',
      referenceId: ''
    });
    this.forceUpdate();
  };

  uploadDocumentFront = async (event) => {
    const compressedFile = await this.handleImageUpload(event);
    this.setState({ document_front_photo: compressedFile });
  };

  uploadDocumentBack = async (event) => {
    const compressedFile = await this.handleImageUpload(event);
    this.setState({ document_back_photo: compressedFile });
  };

  uploadUserPic = async (event) => {
    const compressedFile = await this.handleImageUpload(event);
    this.setState({ user_pic: compressedFile });
  };

  handleImageUpload = async (event) => {
    const imageFile = event.target.files[0];

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      // smaller than maxSizeMB
      // await uploadToServer(compressedFile); // write your own logic
      return compressedFile;
    } catch (error) {
      console.log('error');
    }
  };

  handleImageForCompress = async (event) => {
    let imageFile = [];
    imageFile = [...event.target.files];
    compress
      .compress(imageFile, {
        size: 1,
      })
      .then((data) => {
        const a = {};
        a.name = data[0].alt;
        a.size = data[0].endSize;
      });
  };

  checkUserId = (e) => {
    if (e) e.preventDefault();
    this.setState({ loading: true, verificationError: '' });
    const { userId } = this.state;
    getUserDetailsFromId({ userId })
      .then((res) => {
        if (
          res.response
          && res.response.profileDetails
          && res.response.profileDetails.user_id
        ) {
          this.setState({
            userExists: true,
            userDetails: res.response.profileDetails,
            userDoesNotExist: false,
            loading: false,
            userError: '',
          });
        }
        if (
          res.response
          && res.response.profileDetails
          && !res.response.profileDetails.user_id
        ) {
          this.setState({
            userDoesNotExist: true,
            loading: false,
            userExists: false,
            userError: '',
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err?.error?.reason) {
          this.setState({
            userError: err.error.reason,
            loading: false,
            userExists: false,
          });
        }
      });
  };

  verifyData = () => {
    const {
      one, two, three, four, documentId, DOB, fileNo, DOI, nameOnDocument,
    } = this.state;
    if (one) {
      // dl
      this.setState({ loading: true, verificationError: '' });
      const data = {
        dlNumber: documentId,
        dob: DOB,
      };
      verifyDL(data)
        .then((res) => {
          if (res.response && res.response.verified) {
            this.setState({
              verificationError: '',
              loading: false,
              detailsVerified: true,
              referenceId: res.response.referenceId
            });
          } else {
            this.setState({
              verificationError: 'Verification error, check details',
              loading: false,
              detailsVerified: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            verificationError: err.message || err.error.reason || 'Verification error, check details',
            loading: false,
            detailsVerified: false,
          });
        });
    }

    if (two) {
      // aadhaar
      this.setState({ loading: true, verificationError: '' });
      getCaptchaForAadhaar()
        .then((res) => {
          this.setState({ captchaData: res.response, loading: false });
        })
        .catch(() => {
          console.log('err');
        });
    }

    if (three) {
      // voder id
      this.setState({ loading: true, verificationError: '' });
      const data = {
        epicNumber: documentId,
      };
      verifyVoterId(data)
        .then((res) => {
          if (res.response && res.response.verified) {
            this.setState({
              detailsVerified: true,
              loading: false,
              verificationError: '',
              referenceId: res.response.referenceId
            });
          } else {
            this.setState({
              detailsVerified: false,
              loading: false,
              verificationError: 'Verification error, check details',
            });
          }
        })
        .catch((err) => {
          console.log('err');
          this.setState({
            detailsVerified: false,
            loading: false,
            verificationError: err.message || err.error.reason || 'Verification error, check details',
          });
        });
    }

    if (four) {
      // passport
      this.setState({ loading: true, verificationError: '' });
      const data = {
        fileNo,
        dob: DOB,
        doi: DOI,
        passportNo: documentId,
        name: nameOnDocument,
      };
      verifyPassport(data)
        .then((res) => {
          if (res.response && res.response.verified) {
            this.setState({
              detailsVerified: true,
              loading: false,
              verificationError: '',
              referenceId: res.response.referenceId
            });
          } else {
            this.setState({
              detailsVerified: false,
              loading: false,
              verificationError: 'Verification error, check details',
            });
          }
        })
        .catch((err) => {
          this.setState({
            detailsVerified: false,
            loading: false,
            verificationError: err.message || err.error.reason || 'Verification error, check details',
          });
        });
    }
  };

  verifyAadhaarData = () => {
    this.setState({ loading: true, verificationError: '' });
    const {
      captchaData,
      documentId,
      filledCaptcha,
    } = this.state;
    const data = {
      sessionId: captchaData.sessionId,
      aadhaarNoUser: documentId,
      securityCode: filledCaptcha,
      //  match: this.state.filledCaptcha,
      //  aadhaarNoClient:,
      //  dob: this.state.DOB,
      //  gender: this.state.gender,
      //  phoneNo:
    };
    verifyAadhaar(data)
      .then((res) => {
        if (res.response && res.response.verified) {
          this.setState({
            loading: false,
            detailsVerified: true,
            verificationError: '',
            referenceId: res.response.referenceId
          });
        } else {
          this.setState({
            loading: false,
            detailsVerified: false,
            verificationError: 'Verification error,incorrect details / CAPTCHA',
          });
          getCaptchaForAadhaar().then((resp) => {
            this.setState({ captchaData: resp.response, loading: false });
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
          detailsVerified: false,
          verificationError: err.message || err.error.reason || 'Verification error, check details',
        });
        getCaptchaForAadhaar().then((res) => {
          this.setState({ captchaData: res.response, loading: false });
        });
      });
  };

  confirmFormUpload = () => {
    this.setState({
      confirmBeforeUpload: true,
    });
  };

  closeModal = () => {
    const { KYCDocumentsUploaded } = this.state;
    if (KYCDocumentsUploaded) {
      this.onReset();
    } else {
      this.setState({
        confirmBeforeUpload: false,
        documentsUploadError: '',
      });
    }
  };

  uploadFormData = () => {
    const {
      userDetails,
      document_front_photo,
      document_back_photo,
      user_pic,
      documentType,
      nameOnDocument,
      document_id,
      phoneNumberOnDocument,
      documentId,
      fatherName,
      DOB,
      DOE,
      DOI,
      placeOfIssue,
      gender,
      address,
      referenceId
    } = this.state;
    if (!userDetails.user_id) return;
    this.setState({ loading: true, verificationError: '' });
    const formData = new FormData();

    formData.append('document_front_photo', document_front_photo);
    formData.append('document_back_photo', document_back_photo);
    formData.append('user_photo', user_pic);
    formData.append('document_type', documentType);
    formData.append('name_on_document', nameOnDocument);
    formData.append('document_number', document_id);
    formData.append(
      'phonenumber_on_document',
      phoneNumberOnDocument,
    );
    formData.append('document_id', documentId);
    formData.append('father_name', fatherName);
    formData.append('dob', DOB);
    formData.append('doe', DOE);
    formData.append('doi', DOI);
    formData.append('place_of_issue', placeOfIssue);
    formData.append('document_expiry_date', DOE);
    formData.append('gender', gender);
    formData.append('address', address);
    formData.append('user_id', userDetails.user_id);
    formData.append('referenceId', referenceId);

    const sessiontoken = localStorage.getItem('session_token');
    const header = {
      'Content-Type': 'multipart/form-data',
      'x-metro-api-key': API_KEY,
      'x-metro-sessiontoken': sessiontoken,
    };
    axios
      .post(`${API_URL}admin/console/e-kyc`, formData, { headers: header })
      .then(() => {
        this.setState({ loading: false, KYCDocumentsUploaded: true });
      })
      .catch((err) => {
        if (
          err
          && err.response
          && err.response.data
          && err.response.data.error
        ) {
          this.setState({
            loading: false,
            documentsUploadError: err.response.data.error.reason,
          });
        }
      });
  };

  render() {
    let Inputs;
    const {
      userExists,
      dlData,
      voterIdData,
      // passportData,
      // aadhaarData,
      captchaData,
      documentType,
      nameOnDocument,
      phoneNumberOnDocument,
      documentId,
      four,
      fileNo,
      fatherName,
      DOB,
      one,
      DOE,
      DOI,
      placeOfIssue,
      // documentExpiryDate,
      gender,
      address,
      loading,
      userDoesNotExist,
      userError,
      userId,
      userDetails,
      two,
      three,
      verificationError,
      filledCaptcha,
      detailsVerified,
      confirmBeforeUpload,
      documentsUploadError,
      KYCDocumentsUploaded,
    } = this.state;
    if (userExists) {
      Inputs = (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '20% 80%',
            margin: '0 20%',
            gridGap: '5px',
          }}
        >
          <label>Document Type</label>
          <input
            id="documentType"
            value={documentType}
            onChange={this.onChange}
          />

          <label>Name on Document</label>
          <input
            id="nameOnDocument"
            placeholder="Name of user as on Document"
            value={nameOnDocument}
            onChange={this.onChange}
          />
          {/*
          <label>Document Number</label>
          <input
          id="documentNumber"
          value={documentNumber}
          onChange={this.onChange}
          />
          */}

          <label>Phone Number On Document</label>
          <input
            id="phoneNumberOnDocument"
            value={phoneNumberOnDocument}
            onChange={this.onChange}
          />

          <label>Document Id</label>
          <input
            id="documentId"
            placeholder="Id of the document"
            value={documentId}
            onChange={this.onChange}
          />

          {four ? (
            <>
              <label>File No.</label>
              <input
                id="fileNo"
                value={fileNo}
                onChange={this.onChange}
              />
            </>
          ) : null}
          <label>Father Name</label>
          <input
            id="fatherName"
            value={fatherName}
            onChange={this.onChange}
          />
          <label>DOB</label>
          <input
            type="date"
            id="DOB"
            value={DOB}
            onChange={this.onChange}
          />
          {one || four ? (
            <>
              <label>DOE</label>
              <input
                type="date"
                id="DOE"
                value={DOE}
                onChange={this.onChange}
              />
            </>
          ) : null}

          {four ? (
            <>
              <label>DOI</label>
              <input
                type="date"
                id="DOI"
                value={DOI}
                onChange={this.onChange}
              />
            </>
          ) : null}

          <label>Place of Issue</label>
          <input
            id="placeOfIssue"
            value={placeOfIssue}
            onChange={this.onChange}
          />

          {/*      <label>Document expiry date</label>
          <input
            type="date"
            id="documentExpiryDate"
            value={documentExpiryDate}
            onChange={this.onChange}
         />
  */}
          <label>Gender</label>
          <input
            id="gender"
            value={gender}
            onChange={this.onChange}
          />

          <label>Address</label>
          <input
            id="address"
            value={address}
            onChange={this.onChange}
          />
        </div>
      );
    }

    if (loading) {
      return (
        <div>
          <Navbar />
          <div style={{ height: '50vh' }}>
            <div style={{ margin: '20% 0' }}>
              <Loader />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <Navbar />

        <div>
          <div style={{ margin: '30px' }}>
            <form onSubmit={this.checkUserId} onReset={this.onReset}>
              <label htmlFor="userId">
                User Id
                <input
                  id="userId"
                  value={userId}
                  onChange={this.onChange}
                  style={{ 'margin-left': '10px', height: '32px' }}
                  autoFocus
                />
              </label>
              <button className="btn-primary btn" style={{ 'margin-left': '10px', 'margin-bottom': '4px' }} type="submit">Check</button>
              <button className="btn-primary btn" style={{ 'margin-left': '10px', 'margin-bottom': '4px' }} type="button">Reset</button>
            </form>
          </div>
          {userDoesNotExist ? (
            <p style={{ color: 'red' }}>No user found</p>
          ) : null}
          {userError && (
            <p style={{ color: 'red' }}>{userError}</p>
          )}
          {userExists ? (
            <>
              <div>
                <p>
                  User name:
                  {userDetails.zvr_name}
                </p>
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '15% 85%',
                  margin: '0 45%',
                }}
              >
                <input
                  id="one"
                  type="checkbox"
                  checked={one}
                  onChange={this.selectOne}
                />
                <label>DL</label>
                <input
                  id="two"
                  type="checkbox"
                  checked={two}
                  onChange={this.selectTwo}
                />
                <label>Aadhaar</label>
                <input
                  id="three"
                  type="checkbox"
                  checked={three}
                  onChange={this.selectThree}
                />
                <label>Voter ID</label>

                <input
                  id="four"
                  type="checkbox"
                  checked={four}
                  onChange={this.selectFour}
                />
                <label>Passport</label>
              </div>
              <div>
                {Inputs}
                <button type="button" onClick={this.verifyData} style={{ margin: '20px' }}>
                  Verify
                </button>
              </div>
            </>
          ) : null}
          {verificationError !== '' ? (
            <p style={{ color: 'red' }}>{verificationError}</p>
          ) : null}

          {captchaData.captchaImage ? (
            <>
              <label>Captcha Image</label>
              <img alt="" src={`data:image/jpeg;base64,${captchaData.captchaImage}`} />
              <label>Captcha Input</label>
              <input
                id="filledCaptcha"
                value={filledCaptcha}
                onChange={this.onChange}
              />
              <button type="button" onClick={this.verifyAadhaarData}>
                verifyAadhaarData
              </button>
            </>
          ) : null}

          {dlData.name ? (
            <div style={{ backgroundColor: 'aliceblue', margin: '0 20%' }}>
              <h4>DL data</h4>
              <p>
                <strong>DL No.</strong>
                {dlData.dl_number}
              </p>
              <p>
                <strong>Name: </strong>
                {dlData.name}
              </p>
              <p>
                <strong>Father Name:</strong>
                {dlData['father/husband']}
              </p>
              <p>
                <strong>DOB:</strong>
                {dlData.dob}
              </p>
              <p>
                <strong>Address: </strong>
                {dlData.address}
              </p>
            </div>
          ) : null}

          {voterIdData.name ? (
            <div style={{ backgroundColor: 'aliceblue', margin: '0 20%' }}>
              <h4>VOter Id data</h4>
              <p>
                <strong>Voter Id</strong>
                {voterIdData.epic_no}
              </p>
              <p>
                <strong>DOB</strong>
                {voterIdData.dob}
              </p>
              <p>
                <strong>State</strong>
                {voterIdData.state}
              </p>
              <p>
                <strong>Name</strong>
                {voterIdData.name}
              </p>
              <p>
                <strong>District</strong>
                {voterIdData.district}
              </p>
            </div>
          ) : null}

          {detailsVerified ? (
            <div>
              <h4>Photos</h4>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '30% 70%',
                  margin: '0 20%',
                  gridGap: '20px',
                }}
              >
                <label>Document Front</label>
                <input type="file" accept="image/jpeg image/png" onChange={this.uploadDocumentFront} />
                <label>Document Back</label>
                <input type="file" accept="image/jpeg image/png" onChange={this.uploadDocumentBack} />
                <label>User</label>
                <input type="file" accept="image/jpeg image/png" onChange={this.uploadUserPic} />
              </div>
              <div />
              <div className="alert alert-danger">
                Note: Supported images files jpg/jpeg/png
                <br />
                (other image formats like heic heif should be converted to jpg or png before uploading)
              </div>
              <button
                type="button"
                onClick={this.confirmFormUpload}
                style={{ margin: '30px' }}
              >
                Upload
              </button>
              <div>
                <Popup
                  open={confirmBeforeUpload}
                  onClose={this.closeModal}
                >
                  <div className="Modal">
                    <h4 style={{ textAlign: 'center', margin: '30px 0' }}>
                      Are you sure you want to upload documents ?
                    </h4>
                    <p style={{ textAlign: 'center', color: 'red' }}>
                      {documentsUploadError !== '' ? (
                        <p>{documentsUploadError}</p>
                      ) : null}
                    </p>
                    <h5 style={{ textAlign: 'center', color: 'limegreen' }}>
                      {KYCDocumentsUploaded
                        ? 'Document Upload Successful'
                        : null}
                    </h5>
                    {KYCDocumentsUploaded ? null : (
                      <button
                        type="button"
                        onClick={this.uploadFormData}
                        style={{ margin: '20px' }}
                      >
                        Upload Data Confirm
                      </button>
                    )}
                    <button
                      onClick={this.closeModal}
                      style={{ margin: '20px' }}
                      type="button"
                    >
                      Close
                    </button>
                  </div>
                </Popup>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default AddKYC;
