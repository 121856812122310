/* eslint-disable camelcase */
import moment from 'moment';
import React, { useState } from 'react';
import Navbar from '../../Others/NavbarComponent';
import { sess_get } from '../../../services/HttpRequests';

function MessageLogsPage() {
  const [error, setError] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [tableData, setTableData] = useState([]);
  const [searching, setSearching] = useState(false);

  function submitNumber() {
    console.log(searchValue);
    setSearching(true);
    const session_token = localStorage.getItem('session_token');
    sess_get('admin/console/user-message-logs', session_token, { searchValue })
      .then((resp) => {
        if (resp?.data?.response?.messageList?.length > 0) {
          setTableData(resp.data.response.messageList);
          setError('');
        } else {
          setError('No Messages Found');
        }
        setSearching(false);
      })
      .catch((err) => {
        if (err?.response?.data?.error?.reason) {
          setError(err.response.data.error.reason);
        }
        setSearching(false);
      });
  }

  let tableDataDisplay;
  if (tableData.length > 0) {
    tableDataDisplay = tableData.map((item) => (
      // eslint-disable-next-line no-underscore-dangle
      <tr key={item._id}>
        <td>{moment(item.created_at).format('hh:mm a DD/MM/YYYY')}</td>
        <td>{item.message}</td>
      </tr>
    ));
  }
  return (
    <div>
      <Navbar />
      <div style={{ textAlign: 'center', margin: '50px auto' }}>
        <input
          style={{ width: '300px', height: '32px', paddingBottom: '3px' }}
          placeholder="Doosra number ..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          autoFocus
        />

        <button className="btn-primary btn mov-left" type="button" onClick={submitNumber} style={{ 'margin-bottom': '2px' }}>Search</button>
        <p>NOTE:- add 91 for doosra number </p>
        {searching ? (
          <p style={{ color: 'blue' }}>Searching ...</p>
        ) : (
          <>
            <p style={{ color: 'red' }}>{error}</p>
            <table>
              <tr>
                <th>Date</th>
                <th>Message</th>
              </tr>
              {tableDataDisplay}
            </table>
          </>
        )}
      </div>
    </div>
  );
}

export default MessageLogsPage;
