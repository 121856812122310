import React, { Component } from 'react';
import {
  Table, Modal, ModalBody, ModalHeader,
} from 'reactstrap';
import moment from 'moment';
import '../../assets/css/reports.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

class GiftTable extends Component {
  getCouponDetails = (data, userData) => {
    if (data && data.codes && data.codes.length != 0) {
      this.props.getDetails(data, userData);
    }
  }

  render() {
    let data;
    let noData;

    if (this.props.tableData && this.props.tableData.length > 0) {
      data = this.props.tableData.map((entry) => (

        <tr>
          <td>{entry.email}</td>
          <td>{entry.total_amount}</td>
          <td>{(entry.order_id) ? entry.order_id : '--'}</td>
          <td>{moment(entry.created_at).format('DD-MM-YYYY')}</td>
          <td className="Clickable" onClick={() => this.getCouponDetails({ codes: entry.voucher_codes }, entry)}><a>{entry.voucher_codes.length}</a></td>
        </tr>
      ));
    } else {
      noData = <h1 style={{ padding: '20px' }}> no data found</h1>;
    }

    function TableHeader() {
      return (
        <thead>
          <tr>
            <th>Email</th>
            <th>Amount Paid</th>
            <th>orderId</th>
            <th>Date</th>
            <th>Vouchers</th>
          </tr>
        </thead>
      );
    }

    return (
      <div>
        <table className="newTable">
          <TableHeader />
          {data}
        </table>
        {noData}
      </div>
    );
  }
}

export default GiftTable;
