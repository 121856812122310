import React, { useEffect, useState } from "react";
// import { sess_get, sess_post } from "../../services/HttpRequests";
import { sess_get, sess_post } from "../../../services/HttpRequests";
import Navbar from "../../Others/NavbarComponent";
import Pagination from "../History/Pagination";
import LinksDataTable from "./LinksDataTable";
import PaymentLinksPopup from "./PaymentLinksPopup";
import SearchPaymentLinks from "./SearchPaymentLinks";

function PaymentLinks() {
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [defaultPageLength, setDefaultPageLength] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState({});

  useEffect(() => {
    fetchPaymentLinkHandler();
  }, []);

  function fetchPaymentLinkHandler(query) {
    if (query?.search_key) {
      setSearchQuery(query);
    }

    let data = {};
    if (!query) {
      data = { ...data, ...searchQuery };
      data.pageNo = currentPage;
      data.pageSize = defaultPageLength;
    } else {
      data = { ...searchQuery, ...query };
      data.pageSize = defaultPageLength;
    }
    console.log("data", data);
    const session_token = localStorage.getItem("session_token");
    sess_get("admin/console/payment-links", session_token, data)
      .then((resp) => {
        setTableData(resp.data.response.list.docs);
        setTotalPages(
          Math.ceil(resp.data.response.list.count / defaultPageLength)
        );
        if (data.pageNo) {
          setCurrentPage(data.pageNo);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function createPaymentLinkHandler(data, callback) {
    const session_token = localStorage.getItem("session_token");
    data.created_by = localStorage.getItem("care_portal_user_name");
    console.log(data, localStorage.getItem("care_portal_user_name"));
    sess_post("admin/console/payment-link", session_token, data)
      .then((resp) => {
        callback(resp);
        window.location.reload();
      })
      .catch((error) => {
        callback(error);
      });
  }

  function cancelPaymentLink(data, callback) {
    const session_token = localStorage.getItem("session_token");
    sess_post("admin/console/payment-link-cancel", session_token, data)
      .then((resp) => {
        callback(resp);
        window.location.reload();
      })
      .catch((error) => {
        callback(error);
      });
  }

  return (
    <div>
      <Navbar />
      <h4 style={{ margin: "30px" }}>Doosra Payment Links (Conumers Only)</h4>
      <div
        style={{
          margin: "40px auto",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <SearchPaymentLinks
          searchLinks={(query) => fetchPaymentLinkHandler(query)}
        />
        <PaymentLinksPopup
          createPaymentLink={(data, callback) =>
            createPaymentLinkHandler(data, callback)
          }
        />
      </div>
      <LinksDataTable
        tableData={tableData}
        cancelLink={(data, callback) => cancelPaymentLink(data, callback)}
      />
      <div>
        <Pagination
          currentPage={currentPage}
          lastPage={totalPages}
          getInfo={fetchPaymentLinkHandler}
        />
      </div>
    </div>
  );
}

export default PaymentLinks;
