import React, { Component } from 'react';

import whiteLoader from '../../assets/images/white_preloader_s.gif';



export default function Loader(props) {
  return (
    <img src={whiteLoader} style={props.style} />
  )

}


