import moment from 'moment';
import React, { Component } from 'react';
import { Table } from 'reactstrap';

function TableHeader() {
  return (
    <thead>
      <tr>
        <th>Date</th>
        <th>Change Type</th>
        <th>Previous</th>
        <th>Updated</th>
      </tr>
    </thead>
  );
}
// eslint-disable-next-line react/prefer-stateless-function
class UserActivityDetails extends Component {
  render() {
    /* eslint-disable react/prop-types */
    const { userActivityDetails } = this.props;
    if (userActivityDetails?.length > 0) {
      return (
        <Table responsive className="newTable" style={{ 'margin-bottom': '5px' }}>
          <TableHeader />
          <tbody>
            {userActivityDetails.map((activity) => {
              let oldValue = (activity?.metadata && activity?.metadata?.type === 'PASSWORD') ? '-' : activity.metadata?.old_value;
              oldValue = (oldValue && oldValue !== '') ? oldValue : '-';
              let newValue = (activity?.metadata && activity?.metadata?.type === 'PASSWORD') ? '-' : activity.metadata?.new_value;
              newValue = (newValue && newValue !== '') ? newValue : '-';
              return (
                <tr>
                  <td>{moment(new Date(activity.created_at)).format('YYYY-MM-DD HH:mm')}</td>
                  <td>{activity.metadata ? activity?.metadata?.type : activity?.action}</td>
                  <td>{oldValue || '-'}</td>
                  <td>{newValue || '-'}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      );
    }
    return <p>No Activities</p>;
  }
}

export default UserActivityDetails;
