import React, { useEffect, useState } from 'react';
import { sess_get, sess_put } from 'services/HttpRequests';
import {
  Form, Col, Row, Button, Table, InputGroup, FormLabel,
} from 'react-bootstrap';
import moment from 'moment';
import { Multiselect } from 'multiselect-react-dropdown';
import NavbarComponent from 'components/Others/NavbarComponent';
import { useHistory } from 'react-router-dom';

export function AddOns(props) {
  return <Form.Group key={props.i} style={{ display: 'flex', gap: '10px', marginTop: '5px' }}>
    <Form.Control disabled value={props.number} type="text" />
    <Button onClick={() => props.removeNumber(props.i)} variant="primary">Remove</Button>
  </Form.Group>
}

export default function EditDiscount(props) {
  const history = useHistory();
  const [data, setData] = useState({});
  const [error, setError] = useState('');
  const [hasError, setHasError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newNumbersList, setNewNumbersList] = useState([]);
  const [newNumber, setNewNumber] = useState('');

  useEffect(() => {
    getDiscountData();
  }, []);


  const getDiscountData = () => {
    const session_token = localStorage.getItem('session_token');
    sess_get(`admin/console/discount-coupon?id=${props.match.params.id}`, session_token)
      .then((response) => {
        if (response.data.response) {
          setData(response.data.response);
        }
      })
      .catch((error) => {
        console.log(error);

      });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let numbersList = [...data.numbers, ...newNumbersList];
    const session_token = localStorage.getItem('session_token');
    sess_put(`admin/console/discount-coupon?id=${props.match.params.id}`, session_token, { numbers: numbersList })
      .then((response) => {
        history.push('/discount');
      })
      .catch((err) => {
        console.error(err);

      });
  }


  const addNumber = () => {
    let a = newNumbersList;
    if (/^\d{10}$/.test(newNumber)) {
      setError('');
      a.push(newNumber);
      setNewNumbersList(a);
      setNewNumber('');
    } else {
      return setError('invalid number');
    }
  }

  const removeNumber = (i) => {
    const a = [...newNumbersList];
    a.splice(i, 1);
    setNewNumbersList(a);
  }

  return (
    <div>
      <NavbarComponent />
      {data.code &&
        <div style={{ maxWidth: '800px', margin: '50px auto' }}>
          <div style={{ textAlign: 'left' }}>
            <h2>Edit Discount Coupons</h2>
          </div>
          <Form onSubmit={handleSubmit} style={{ border: '1px solid black', padding: '20px', borderRadius: '8px', textAlign: 'left' }}>
            <Form.Group controlId="code" style={{ margin: '10px 0' }}>
              <Form.Label>Code</Form.Label>
              <Form.Control disabled value={data.code} type="text" />
              <Form.Text className="error-label">{error}</Form.Text>
            </Form.Group>
            <Form.Group controlId="startDate" style={{ margin: '10px 0' }}>
              <Form.Label>Start Date</Form.Label>
              <Form.Control disabled value={moment(data.startDate).format('YYYY-MM-DD')} type="date" />
            </Form.Group>
            <Form.Group controlId="expiryDate" style={{ margin: '10px 0' }}>
              <Form.Label>Expiry Date</Form.Label>
              <Form.Control disabled value={moment(data.expiryDate).format('YYYY-MM-DD')} type="date" />
            </Form.Group >
            <Form.Group controlId="expiryDate" style={{ margin: '10px 0' }}>
              <Form.Label>Plans</Form.Label>
              <Multiselect
                displayValue="plan_type"
                selectedValues={data.plans}
                disabled
              />
            </Form.Group>
            <Form.Group controlId="user_type" style={{ margin: '10px 0' }}>
              <Form.Label>User Type</Form.Label>
              <div key="inline-radio" className="mb-3">
                <Form.Check inline name="user_type" checked={data.user_type == 'all_users'} disabled value="all_users" label="All" type="radio" id="all_radio" />
                <Form.Check inline name="user_type" checked={data.user_type == 'new_users'} disabled value="new_users" label="New Users" type="radio" id="new_radio" />
                <Form.Check inline name="user_type" checked={data.user_type == 'existing_users'} disabled value="existing_users" label="Existing Users" type="radio" id="existing_radio" />
              </div>
            </Form.Group>
            <fieldset className="dicount_value_box">
              <legend>Discount Type:</legend>
              <div key="inline-radio" className="mb-3">
                <Form.Check inline name="radio" checked={data.type == 'flat'} disabled value="flat" label="Flat" type="radio" id="flat_radio" />
                <Form.Check inline name="radio" checked={data.type == 'percentage'} disabled value="percentage" label="Percentage" type="radio" id="percentage_radio" />
              </div>
              {
                data.type == 'flat'
                && (
                  <Form.Group controlId="flat" style={{ margin: '10px 0' }}>
                    <Form.Label>Flat Discount(Rs)</Form.Label>
                    <Form.Control disabled value={data.flat} type="number" />
                  </Form.Group>
                )

              }
              {
                data.type == 'percentage'
                && (
                  <>
                    <Form.Group controlId="percentage" style={{ margin: '10px 0' }}>
                      <Form.Label>Percentage Discount(%)</Form.Label>
                      <Form.Control value={data.percentage} type="number" disabled />
                    </Form.Group>
                    <Form.Group controlId="percentageCap">
                      <Form.Label>Percentage Discount Cap(Rs)</Form.Label>
                      <Form.Control value={data.percentageCap} type="number" disabled />
                    </Form.Group>
                  </>
                )
              }
            </fieldset>
            <Form.Group style={{ margin: '10px 0' }}>

              <FormLabel>Numbers</FormLabel>
              {data.numbers && data.numbers.map(number => (<Form.Control disabled value={number} />))}
              {newNumbersList.length > 0 &&
                newNumbersList.map((number, i) => (
                  <AddOns number={number} i={i} key={i} removeNumber={removeNumber} />
                ))
              }

              <Form.Group style={{ display: 'flex', gap: '10px', margin: '10px 0' }}>
                <Form.Control onChange={(e) => { setNewNumber(e.target.value); setError(''); }} value={newNumber} type="number" />
                <Button onClick={addNumber} variant="primary">Add</Button>
              </Form.Group>
            </Form.Group>
            <Form.Text style={{ color: 'red' }}>{error}</Form.Text>
            <Form.Group controlId="limit" style={{ margin: '10px 0' }}>
              <Form.Label>Usage Limit</Form.Label>
              <Form.Control disabled value={data.limit} type="number" />
              <Form.Text className="error-label">{error}</Form.Text>
            </Form.Group>
            <Form.Group style={{ textAlign: 'right' }}>
              <Button disabled={isSubmitting} className="submit-button" variant="primary" type="submit">
                Submit
              </Button>
            </Form.Group>
          </Form>
        </div>
      }
    </div>
  );
}