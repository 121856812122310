import { sess_get, sess_post } from "./HttpRequests";

async function getNumberStatus(number) {
  let getNumberURL = `admin/console/get-phone-number-status?number=${number}`;
  // let checkNumberURL = `admin/console/check-phone-number-status?number=${number}`
  const sessionToken = localStorage.getItem("session_token");
  let response = await sess_get(getNumberURL, sessionToken)
    .catch((e) => {
      console.log("Error while getting number status :: ", e);
      // return sess_get(checkNumberURL, sessionToken)
    })
    .catch((e) => {
      console.log("Error while checking number status :: ", e);
      // return e.toString()
    });
  if (
    response &&
    response.data &&
    response.data.response &&
    response.data.response.success
  ) {
    return response.data.response.result;
  } else {
    return null;
  }
}

async function checkNumberStatus(number, data) {
  let getNumberURL = `admin/console/get-phone-number-status?number=${number}`;
  let checkNumberURL = `admin/console/check-phone-number-status?number=${number}`;
  const sessionToken = localStorage.getItem("session_token");
  let response = await sess_get(checkNumberURL, sessionToken, data).catch(
    (e) => {
      console.log("Error while checking number status :: ", e);
      return e.toString();
    }
  );

  if (
    response &&
    response.data &&
    response.data.response &&
    response.data.response.success
  ) {
    return response.data.response.result;
  } else {
    return null;
  }
}

async function checkNumberListStatus(numbers, filters) {
  let checkNumberListURL = `admin/console/check-phone-number-list-status`;
  const sessionToken = localStorage.getItem("session_token");
  let response = await sess_post(checkNumberListURL, sessionToken, {
    numbers,
    filters,
  }).catch((e) => {
    throw e
  });
  console.log(response);
  return response.data;
}

async function getNumberStatusCsv(startDate, endDate) {
  let url = `admin/console/get-phone-number-status-csv`;
  const sessionToken = localStorage.getItem("session_token");
  let response = await sess_post(url, sessionToken, {
    startDate,
    endDate,
  }).catch((e) => { });
  return response;
}

export {
  getNumberStatus,
  checkNumberStatus,
  checkNumberListStatus,
  getNumberStatusCsv,
};
