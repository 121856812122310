import React, { Component } from 'react';
import '../../assets/css/nReports.css';

class Other extends Component {
  render() {
    return (
      <div className="table2 row-margin" style={{ 'margin-left': '107px' }}>
        <h2 style={{ 'font-weight': '600' }}>Other Metrics</h2>

        <table className="newTable">
          <tr>
            <th>Metric</th>
            <th>LifeTime</th>
            <th>Today</th>
          </tr>
          <tr>
            <td style={{ 'font-weight': 'bolder' }}>Verified Prospects</td>
            <td>
              {' '}
              {this.props.totalVerified}
              {' '}
            </td>
            <td>
              {' '}
              {this.props.todayVerified}
              {' '}
            </td>
          </tr>
          <tr>
            <td style={{ 'font-weight': 'bolder' }}>Bounced Users</td>
            <td>
              {' '}
              {this.props.totalBounced}
              {' '}
            </td>
            <td>
              {' '}
              {this.props.todayBounced}
              {' '}
            </td>
          </tr>
          <tr>
            <td style={{ 'font-weight': 'bolder' }}>Referrals</td>
            <td>{this.props.totalReferral}</td>
            <td>
              {this.props.todayReferral}
              {' '}
            </td>
          </tr>
        </table>
      </div>
    );
  }
}

export default Other;
