import React, { Component } from 'react';
import { Table, Row, Col, Form, Input, Button, Alert } from 'reactstrap';
import Pagination from './Pagination';
import { GetRefundsData } from '../../../services/PaymentRequests';
import moment from 'moment';
import SearchComponent from '../../Others/SearchComponent';
let colorMap = {
  created: '#808080',
  processed: '#4bb371',
  failed: '#000000',
  speed_changed: '#666666',
};

class Refunds extends Component {
  constructor() {
    super();
    this.state = {
      refundsList: [],
      pageSize: 10,
      pageNo: 1,
      totalPages: null,
      searchCategory: null,
      keys: [
        "Select Category",
        "refund_id",
        "payment_id",
        "refund_amount",
        "email",
        "contact",
        "refund_created_at",
        "refund_status",
      ],
      searchInputType: "string",
    };
  }

  componentDidMount() {
    let data = {
      pageNo: 1,
      pageSize: 10,
    };
    GetRefundsData(data)
      .then((resp) => {
        if (resp && resp.success) {
          this.setState({
            refundsList: resp.response.required_docs,
            pageNo: resp.response.pageNo,
            pageSize: resp.response.pageSize,
            totalPages: resp.response.totalPages,
            error: "",
          });
        }
      })
      .catch((error) => {
        this.setState({ error: error?.response?.reason });
      });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    if (value == "all") {
      this.setState({ [name]: null });
    } else {
      this.setState({ [name]: value });
    }
  };

  search = (event) => {
    event.preventDefault();
    let data = {
      pageNo: 1,
      pageSize: 10,
    };
    this.getRefundData(data);
  };

  getRefundData = (data) => {
    if (!data) {
      data = {
        pageNo: this.state.pageNo,
        pageSize: this.state.pageSize,
      };
    }
    if (this.state.searchCategory) {
      data.label = this.state.searchCategory;
    }

    if (this.state.searchKeyProperty) {
      data.searchKeyProperty = this.state.searchKeyProperty;
    }

    if (this.state.searchKeyword) {
      data.searchKeyword = this.state.searchKeyword;
    }

    if(this.state.searchInputType){
      data.searchInputType=this.state.searchInputType;
    }

    GetRefundsData(data)
      .then((resp) => {
        if (resp && resp.success) {
          this.setState({
            refundsList: resp.response.required_docs,
            pageNo: resp.response.pageNo,
            pageSize: resp.response.pageSize,
            totalPages: resp.response.totalPages,
            // searchKeyword: "",
            // searchKeyProperty: "",
            error: "",
          });
        }
      })
      .catch((err) => {
        this.setState({
          // paymentList: [],
          error: err?.error?.reason || "Something went wrong",
        });
      });
  };

  handleSearchInputChanges = (event) => {
    let state = {};
    if (event.target.value === "Select Category") {
      state = {
        searchKeyProperty: "",
        searchKeyword: "",
      };
    }
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value === "Select Category" ? "" : event.target.value,
      ...state,
      searchInputType:event.target.getAttribute('data-is_date')
    });
  };

  render() {
    const keys = this.state.keys || [];
    function TableHeader() {
      return (
        <thead>
          <tr>
            <th>Refund ID</th>
            <th>Payment ID</th>
            <th>Refund Amount</th>
            <th>Email</th>
            <th>Contact</th>
            <th>Refund Created At</th>
            <th>Refund Status</th>
          </tr>
        </thead>
      );
    }

    const data = this.state.refundsList.map((refund) => {
      return (
        <tr key={refund.refund_id}>
          <td>{refund.refund_id}</td>
          <td>{refund.payment_id}</td>
          <td>{refund.refund_amount / 100}</td>
          <td>{refund.email}</td>
          <td>{refund.contact}</td>
          <td>
            {moment(refund.refund_created_at).format("DD MMM YYYY h:mm a")}
          </td>
          <td style={{ color: colorMap[refund.refund_status] || "#7b7b7b" }}>
            {refund.refund_status}
          </td>
        </tr>
      );
    });

    return (
      <div>
        <Form onSubmit={this.search} style={{ "padding-bottom": "20px" }}>
          <Row>
            <Col md={3}>
              <Input
                type="select"
                id="searchCategory"
                name="searchCategory"
                value={this.state.searchCategory}
                className="dropdown-color"
                onChange={this.handleInputChange}
              >
                <option value="all"> All </option>
                <option value="created"> Created </option>
                <option value="processed"> Processed </option>
                <option value="failed"> Failed </option>
              </Input>
            </Col>
            <SearchComponent
              keys={keys}
              handleSearchInputChanges={this.handleSearchInputChanges}
              searchKeyProperty={this.state.searchKeyProperty}
              searchKeyword={this.state.searchKeyword}
            />
            <Col md={3}>
              <Button
                type="submit"
                color="primary"
                style={{ float: "right" }}
                className="kyc-filter-button"
              >
                Search
              </Button>
            </Col>
          </Row>
        </Form>

        {this.state.error && <Alert color="danger">{this.state.error}</Alert>}

        <Table responsive className="newTable" style={{"width":"100%"}}>
          <TableHeader></TableHeader>
          <tbody>{data}</tbody>
        </Table>
        <Pagination
          currentPage={this.state.pageNo}
          lastPage={this.state.totalPages}
          getInfo={this.getRefundData}
        />
      </div>
    );
  }
}

export default Refunds;
