import React, { Component } from 'react';
import {
  Table, Modal, ModalBody, ModalHeader,
} from 'reactstrap';
import moment from 'moment';
import '../../assets/css/reports.css';
import Navbar from '../Others/NavbarComponent';

class CouponsTable extends Component {
  getBack = () => {
    this.props.getBack();
  }

  render() {
    let data;
    let noData;
    console.log(this.props.tableData);
    if (this.props.tableData && this.props.tableData.length > 0) {
      data = this.props.tableData.map((entry) => (

        <tr>
          <td>{entry.code}</td>
          <td>{(entry.is_used) ? 'Yes' : 'No'}</td>
          <td><a>{entry.percentageCap}</a></td>
          <td>{moment(entry.expiryDate).format('DD-MM-YYYY')}</td>
          <td>{moment(entry.createdAt).format('DD-MM-YYYY')}</td>
        </tr>
      ));
    } else {
      noData = <h1 style={{ padding: '20px' }}> no data found</h1>;
    }

    function TableHeader() {
      return (
        <thead>
          <tr>
            <th>Coupon Code</th>
            <th>Is Used</th>
            <th>Plan Cap (Rs.)</th>
            <th>Expiry Date</th>
            <th>Created At</th>
          </tr>
        </thead>
      );
    }

    return (
      <div>
        <Navbar />
        <div>

          <h4 style={{ 'text-align': 'left', margin: '30px 5%' }}>
            User :
            {this.props.ownerData.email}
          </h4>
        </div>

        <table>
          <TableHeader />
          {data}
        </table>
        {noData}
        <button style={{ margin: '50px' }} onClick={this.getBack}>Go back</button>
      </div>
    );
  }
}

export default CouponsTable;
