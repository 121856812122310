import { sess_get } from "./HttpRequests";

async function GetUsers(url, params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get("admin/console/ivr/" + url, session_token, params)
      .then((resp) => {
        if (resp.data !== undefined && resp.data.success) {
          resolve(resp.data);
        } else {
          resolve(resp);
        }
      })
      .catch((error) => {
        if (
          Object.keys(error).length > 0 &&
          error?.response?.data?.error?.reason
        ) {
          resolve(error.response.data);
        } else {
          resolve({
            success: false,
            error: { code: 1003, reason: "Something went wrong" },
          });
        }
      });
  });

  return response.success !== undefined ? response : {};
}

async function GetPayments(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get("admin/console/user-payment-details", session_token, params)
      .then((resp) => {
        if (resp.data !== undefined && resp.data.success) {
          resolve(resp.data);
        } else {
          resolve(resp);
        }
      })
      .catch((error) => {
        if (
          Object.keys(error).length > 0 &&
          error?.response?.data?.error?.reason
        ) {
          resolve(error.response.data);
        } else {
          resolve({
            success: false,
            error: { code: 1003, reason: "Something went wrong" },
          });
        }
      });
  });

  return response.success !== undefined ? response : {};
}

async function GetUserAccountDetails(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get("admin/console/user-profile-details", session_token, params)
      .then((resp) => {
        if (resp.data !== undefined && resp.data.success) {
          resolve(resp.data);
        } else {
          resolve(resp);
        }
      })
      .catch((error) => {
        if (
          Object.keys(error).length > 0 &&
          error?.response?.data?.error?.reason
        ) {
          reject(error.response.data);
        } else {
          reject({
            success: false,
            error: { code: 1003, reason: "Something went wrong" },
          });
        }
      });
  });

  return response.success !== undefined ? response : {};
}

async function GetPlans(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get("admin/console/fetch-user-orders-history", session_token, params)
      .then((resp) => {
        if (resp.data !== undefined && resp.data.success) {
          resolve(resp.data);
        } else {
          resolve(resp);
        }
      })
      .catch((error) => {
        if (
          Object.keys(error).length > 0 &&
          error?.response?.data?.error?.reason
        ) {
          resolve(error.response.data);
        } else {
          resolve({
            success: false,
            error: { code: 1003, reason: "Something went wrong" },
          });
        }
      });
  });

  return response.success !== undefined ? response : {};
}

async function GetUserMinuteDetails(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get("admin/console/user-call-minute-detail", session_token, params)
      .then((resp) => {
        if (resp.data !== undefined && resp.data.success) {
          resolve(resp.data);
        } else {
          resolve(resp);
        }
      })
      .catch((error) => {
        if (
          Object.keys(error).length > 0 &&
          error?.response?.data?.error?.reason
        ) {
          resolve(error.response.data);
        } else {
          resolve({
            success: false,
            error: { code: 1003, reason: "Something went wrong" },
          });
        }
      });
  });

  return response.success !== undefined ? response : {};
}

async function getUserDetailsFromId(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");

    sess_get(
      "admin/console/user-profile-details-from-id",
      session_token,
      params
    )
      .then((resp) => {
        if (resp.data !== undefined && resp.data.success) {
          resolve(resp.data);
        } else {
          resolve(resp);
        }
      })
      .catch((error) => {
        if (
          Object.keys(error).length > 0 &&
          error?.response?.data?.error?.reason
        ) {
          reject(error.response.data);
        } else {
          reject({
            success: false,
            error: { code: 1003, reason: "Something went wrong" },
          });
        }
      });
  });

  return response.success !== undefined ? response : {};
}

async function GetUserWalletBalance(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get("admin/console/user-wallet", session_token, params)
      .then((resp) => {
        if (resp.data !== undefined && resp.data.success) {
          resolve(resp.data);
        } else {
          resolve(resp);
        }
      })
      .catch((error) => {
        if (
          Object.keys(error).length > 0 &&
          error?.response?.data?.error?.reason
        ) {
          resolve(error.response.data);
        } else {
          resolve({
            success: false,
            error: { code: 1003, reason: "Something went wrong" },
          });
        }
      });
  });

  return response.success !== undefined ? response : {};
}

async function GetUsersList(params) {
  const response = await new Promise(function (resolve, reject) {
    const session_token = localStorage.getItem("session_token");
    sess_get("admin/console/fetch-users-list", session_token, params)
      .then((resp) => {
        if (resp.data !== undefined && resp.data.success) {
          resolve(resp.data);
        } else {
          resolve(resp);
        }
      })
      .catch((error) => {
        if (
          Object.keys(error).length > 0 &&
          error?.response?.data?.error?.reason
        ) {
          reject(error.response.data);
        } else {
          reject({
            success: false,
            error: { code: 1003, reason: "Something went wrong" },
          });
        }
      });
  });

  return response.success !== undefined ? response : {};
}

export {
  GetUsers,
  GetPayments,
  GetPlans,
  GetUserAccountDetails,
  GetUserMinuteDetails,
  getUserDetailsFromId,
  GetUserWalletBalance,
  GetUsersList
};
