import React, { Component } from 'react';
import {
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from 'reactstrap';

class PaginationOption extends Component {
  jumpTo(pageNo) {
    const data = {
      pageNo,
      pageSize: 10,
    };
    this.props.getInfo(data);
  }

  render() {
    const selectedOption = (
      <>
        {this.props.currentPage - 2 < 1 ? (
          <PaginationItem disabled>
            <PaginationLink>..</PaginationLink>
          </PaginationItem>
        ) : (
          <PaginationItem>
            <PaginationLink
              onClick={() => this.jumpTo(this.props.currentPage - 2)}
            >
              {this.props.currentPage - 2}
            </PaginationLink>
          </PaginationItem>
        )}
        {this.props.currentPage - 1 < 1 ? (
          <PaginationItem disabled>
            <PaginationLink>..</PaginationLink>
          </PaginationItem>
        ) : (
          <PaginationItem>
            <PaginationLink
              onClick={() => this.jumpTo(this.props.currentPage - 1)}
            >
              {this.props.currentPage - 1}
            </PaginationLink>
          </PaginationItem>
        )}
        <PaginationItem active>
          <PaginationLink onClick={() => this.jumpTo(this.props.currentPage)}>
            {this.props.currentPage}
          </PaginationLink>
        </PaginationItem>
        {this.props.currentPage + 1 <= this.props.lastPage ? (
          <PaginationItem>
            <PaginationLink
              onClick={() => this.jumpTo(this.props.currentPage + 1)}
            >
              {this.props.currentPage + 1}
            </PaginationLink>
          </PaginationItem>
        ) : (
          <PaginationItem disabled>
            <PaginationLink>..</PaginationLink>
          </PaginationItem>
        )}
        {this.props.currentPage + 2 <= this.props.lastPage ? (
          <PaginationItem>
            <PaginationLink
              onClick={() => this.jumpTo(this.props.currentPage + 2)}
            >
              {this.props.currentPage + 2}
            </PaginationLink>
          </PaginationItem>
        ) : (
          <PaginationItem disabled>
            <PaginationLink>..</PaginationLink>
          </PaginationItem>
        )}
      </>
    );

    return (
      <div style={{ margin: '40px' }}>
        <Row>
          <Col md={4} />
          <Col md={5}>
            <Pagination aria-label="Page navigation example">
              <PaginationItem
                disabled={this.props.currentPage == 1}
              >
                <PaginationLink first onClick={() => this.jumpTo(1)} />
              </PaginationItem>
              <PaginationItem
                disabled={this.props.currentPage - 1 == 0}
              >
                <PaginationLink
                  previous
                  onClick={() => this.jumpTo(this.props.currentPage - 1)}
                />
              </PaginationItem>
              {selectedOption}
              <PaginationItem
                disabled={
                  this.props.currentPage + 1 > this.props.lastPage
                }
              >
                <PaginationLink
                  next
                  onClick={() => this.jumpTo(this.props.currentPage + 1)}
                />
              </PaginationItem>
              <PaginationItem
                disabled={
                  this.props.currentPage == this.props.lastPage
                }
              >
                <PaginationLink
                  last
                  onClick={() => this.jumpTo(this.props.lastPage)}
                />
              </PaginationItem>
            </Pagination>
          </Col>
          <Col md={4} />
        </Row>
      </div>
    );
  }
}

export default PaginationOption;
