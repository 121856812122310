/* eslint-disable camelcase */
import {
  sess_get, sess_post,
} from './HttpRequests';

async function verifyDL(params) {
  const response = await new Promise((resolve, reject) => {
    const sessionToken = localStorage.getItem('session_token');
    sess_post('admin/console/kyc-dl-analysis', sessionToken, params).then((resp) => {
      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }
    })
      .catch((error) => {
        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          reject(error.response.data);
        } else {
          reject(new Error('some thing went wrong'));
        }
      });
  });
  return (response.success !== undefined) ? response : {};
}

async function verifyVoterId(params) {
  const response = await new Promise((resolve, reject) => {
    const sessionToken = localStorage.getItem('session_token');
    sess_post('admin/console/kyc-voterid-analysis', sessionToken, params).then((resp) => {
      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }
    })
      .catch((error) => {
        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          reject(error.response.data);
        } else {
          reject(new Error('some thing went wrong'));
        }
      });
  });
  return (response.success !== undefined) ? response : {};
}

async function verifyPassport(params) {
  const response = await new Promise((resolve, reject) => {
    const sessionToken = localStorage.getItem('session_token');
    sess_post('admin/console/kyc-passport-analysis', sessionToken, params).then((resp) => {
      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }
    })
      .catch((error) => {
        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          reject(error.response.data);
        } else {
          reject(new Error('some thing went wrong'));
        }
      });
  });
  return (response.success !== undefined) ? response : {};
}

async function getCaptchaForAadhaar() {
  const response = await new Promise((resolve, reject) => {
    const sessionToken = localStorage.getItem('session_token');
    sess_get('admin/console/kyc-aadhaar-captcha', sessionToken).then((resp) => {
      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }
    })
      .catch((error) => {
        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          reject(error.response.data);
        } else {
          reject(new Error('some thing went wrong'));
        }
      });
  });
  return (response.success !== undefined) ? response : {};
}

async function verifyAadhaar(params) {
  const response = await new Promise((resolve, reject) => {
    const sessionToken = localStorage.getItem('session_token');
    sess_post('admin/console/kyc-aadhaar-analysis', sessionToken, params).then((resp) => {
      if (resp.data !== undefined && resp.data.success) {
        resolve(resp.data);
      } else {
        resolve(resp);
      }
    })
      .catch((error) => {
        if (Object.keys(error).length > 0 && error?.response?.data?.error?.reason) {
          reject(error.response.data);
        } else {
          reject(new Error('some thing went wrong'));
        }
      });
  });
  return (response.success !== undefined) ? response : {};
}

export {
  verifyDL,
  verifyVoterId,
  verifyPassport,
  getCaptchaForAadhaar,
  verifyAadhaar,
};
