/* eslint-disable */
import moment from "moment";
import React, { Component } from "react";
import "../../assets/css/reports.css";

class ReportTable extends Component {
  render() {
    let data;

    let noData;
    if (this.props.tableData && this.props.tableData.length > 0) {
      data = this.props.tableData.map((entry) => (
        <tr key={Math.random()}>
          <td>{moment(entry.date).format("DD-MM-YYYY")}</td>
          <td>{entry.new_users || "-"}</td>
          <td>{entry.new_users_web || "-"}</td>
          <td>{entry.new_users_mobile || "-"}</td>
          <td>{entry.new_users_iap_ios || "-"}</td>
          <td>{entry.total_active_users}</td>
          <td>{entry.new_annual_sign_up || "-"}</td>
          <td>{entry.new_half_yearly_sign_up || "-"}</td>
          <td>{entry.new_monthly_sing_up || "-"}</td>
          {/* <td>{entry.new_active_free_trial || "-"}</td> */}
          {/* <td>{entry.new_active_free_trial_web || "-"}</td> */}
          {/* <td>{entry.new_active_free_trial_mobile || "-"}</td> */}
          {/* <td>{entry.new_active_free_trial_isp_ios || "-"}</td> */}
          {/* <td>{entry.total_active_free_trial || "-"}</td> */}
          {/* <td>{entry.new_upgraded_pro_active || "-"}</td> */}
          <td>{entry.total_signin_users || "-"}</td>
          {/* <td>{entry.verified_prospects || "-"}</td> */}
          <td>{entry.bounced_users || "-"}</td>
          <td>{entry.deactivated_users || "-"}</td>
          <td>{entry.terminated_users || "-"}</td>
          <td>{entry.total_referrals || "-"}</td>
        </tr>
      ));
    } else {
      noData = <h1 style={{ padding: "20px" }}> no data found</h1>;
    }

    function TableHeader() {
      return (
        <thead>
          <tr>
            <th>Date</th>
            <th>New Users</th>
            <th>New Users (Web)</th>
            <th>New Users (Android)</th>
            <th>New Users (IOS)</th>
            <th>Total Active Users</th>
            <th>New Users (Annual)</th>
            <th>New Users (HY)</th>
            <th>New Users (Monthly)</th>
            {/* <th>New Users (Trial)</th> */}
            {/* <th>New Users (Trial, Web)</th> */}
            {/* <th>New Users (Trial, Android)</th> */}
            {/* <th>New Users (Trial, IOS)</th> */}
            {/* <th>Total Active Users (Trial)</th> */}
            {/* <th>New Users (Upgraded Pro)</th> */}
            <th>Total Active Users (app)</th>
            {/* <th>Verified Prospects</th> */}
            <th>Bounced Users</th>
            <th>Deactivated Users</th>
            <th>Terminated Users</th>
            <th>Referrals</th>
          </tr>
        </thead>
      );
    }

    return (
      <div>
        <table className="bigTable">
          <TableHeader />
          <tbody>
            {data}
          </tbody>
        </table>
        {noData}
      </div>
    );
  }
}

export default ReportTable;
