import React, { Component } from "react";
import { Control, Errors, LocalForm } from "react-redux-form";
import { Link, Redirect } from "react-router-dom";
import { Button, Col, Container, Label, Row } from "reactstrap";
import { post } from "../../services/HttpRequests";
import { LoginAPI } from "../../services/LoginRequest";
import NavbarComponent from "./NavbarComponent";
var QRCode = require("qrcode.react");

const required = (val) => val && val.length;
const validEmail = (val) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);

class HomeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogged: false,
      errorMsg: "",
      is_2FA_enabled: false,
      isValidUser: true,
      token: "",
      user: {},

    };
    window.$isLogged = false;
    // this.login = this.login.bind(this);
    this.validateLogin = this.validateLogin.bind(this);
    if (localStorage.getItem("session_token")) {
      window.$isLogged = true;
    }
  }

  generateSecret2FA = (username) => {
    return new Promise((resolve, reject) => {
      post("admin/console/generate-secret", { username })
        .then((response) => {
          if (response.data !== undefined && response.data.success) {
            return resolve(response);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.data && err.response.data.error) {
            // dispatchError(dispatch, err.response.data.error.reason);
            this.setState({ errorMsg: err.response.data.error.reason });
            return reject(err);
          }
          this.setState({ errorMsg: "generateSecret2FA: Somethin went wrong" });
          return reject(err);
        });
    });
  };

  verify2FAToken = (username, token) => {
    return new Promise((resolve, reject) => {
      post("admin/console/verify-2fa-token", { username, token })
        .then((response) => {
          if (response.data !== undefined && response.data.success) {
            window.$isLogged = true;
            this.setState({ isLogged: true });
            localStorage.setItem("isLogged", true);
            localStorage.setItem("username", username);
            localStorage.setItem(
              "session_token",
              response.data.response["x-metro-sessiontoken"]
            );
            // dispatch({
            //   type: actionTypes.TWO_FA_VERIFY,
            //   payload: response.data.response,
            // });
            return resolve(response);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.data && err.response.data.error) {
            // dispatchError(dispatch, err.response.data.error.reason);
            return this.setState({ errorMsg: err.response.data.error.reason });
          }
          this.setState({ errorMsg: "verify 2FA Token: Somethin went wrong" });
          return reject(err);
        });
    });
  };

  validate2FAToken = (username, token) => {
    return new Promise((resolve, reject) => {
      post("admin/console/validate-2fa-token", { username, token })
        .then((response) => {
          if (response.data !== undefined && response.data.success) {
            localStorage.setItem(
              "session_token",
              response.data.response["x-metro-sessiontoken"]
            );
            localStorage.setItem(
              "care_portal_user_name",
              response?.data?.response?.user?.name
            );
            window.$isLogged = true;
            this.setState({ isLogged: true });
            localStorage.setItem("isLogged", true);
            localStorage.setItem("username", username);
            // dispatch({
            //   type: actionTypes.LOGIN_SUCCESS,
            //   payload: response.data.response,
            // });
            return resolve(response);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.data && err.response.data.error) {
            return this.setState({ errorMsg: err.response.data.error.reason });
          }
          this.setState({ errorMsg: "validate2FA Token: Somethin went wrong" });
          return reject(err);
        });
    });
  };

  validateLogin(values) {
    if (values.email && values.password) {
      return true;
    }
    return false;
  }

  handleQRTokenValidate = (e) => {
    e.preventDefault();
    const { user } = this.state;
    if (user) {
      const { is_2FA_enabled, email } = user;
      if (!is_2FA_enabled && email) {
        this.verify2FAToken(email, this.state.token).then((resp) => {
          this.props.history.push("/kyc");
        });
      }
      if (is_2FA_enabled && email) {
        this.validate2FAToken(email, this.state.token).then((resp) => {
          this.props.history.push("/kyc");
        });
      }
    }
  };

  handleLogin = (e) => {
    e.preventDefault();
    if (this.state.email && this.state.password)
      LoginAPI(this.state.email, this.state.password)
        .then((res) => {
          if (res && res.success) {
            const { user } = res.response;
            if (Object.keys(user) && !user.is_2FA_enabled)
              this.generateSecret2FA(this.state.email)
                .then(({ data }) => {
                  this.setState({
                    is_2FA_enabled: false,
                    isValidUser: true,
                    user: { ...user, ...data.response },
                    errorMsg: "",
                  });
                })
                .catch((err) => {

                  console.log('sdss', err);
                });

            if (user && user.is_2FA_enabled) {
              this.setState({
                is_2FA_enabled: true,
                isValidUser: true,
                user,
                errorMsg: "",
              });
            }
          }
        })
        .catch((err) => {
          console.log('sdsas', err);
          this.setState({
            errorMsg: err?.error?.reason
              ? err.error.reason
              : "Something went wrong",
          });
        });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handle2FASendOtp = (e) => {
    // e.preventDefault();
    return new Promise((resolve, reject) => {
      let email = "";
      const { is_2FA_enabled, user } = this.state;
      if (is_2FA_enabled && user) {
        email = user.email;
        post("admin/console/send-otp-2fa", { username: email })
          .then((response) => {
            if (response.data !== undefined && response.data.success) {
              return resolve(response);
            }
          })
          .catch((err) => {
            console.log(err);
            if (err.response && err.response.data && err.response.data.error) {
              return this.setState({
                errorMsg: err.response.data.error.reason,
              });
            }
            this.setState({
              errorMsg: "validate2FAToken: Somethin went wrong",
            });
            return reject(err);
          });
      }
    });
  };

  resetState = () => {
    this.setState({});
  };

  render() {
    if (window.$isLogged) {
      return <Redirect to="/kyc" />;
    }
    const { user } = this.state;
    const { is_2FA_enabled, _id: isValidUser, secret } = user;
    return (
      <div>
        <NavbarComponent />
        <Container>
          <Row className="mt-5">
            <Col xs="12" sm="12" md={{ size: 8, offset: 4 }}>
              <LocalForm>
                <>
                  {!isValidUser && (
                    <>
                      <Row className="form-group">
                        <Col md={6}>
                          <Control.text
                            model=".email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            className="form-control field"
                            validators={{
                              required,
                              validEmail,
                            }}
                            onChange={this.onChange}
                          />
                          <Errors
                            className="text-danger"
                            model=".email"
                            show="touched"
                            messages={{
                              required: "Required ",
                              validEmail: "Invalid Email Address",
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col md={6}>
                          <Control.password
                            model=".password"
                            id="password"
                            name="password"
                            placeholder="Password"
                            className="form-control field"
                            validators={{
                              required,
                            }}
                            onChange={this.onChange}
                          />
                          <Errors
                            className="text-danger"
                            model=".password"
                            show="touched"
                            messages={{
                              required: "Required",
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <p className="col-md-6" style={{ color: 'red' }}>{this.state.errorMsg}</p>
                      </Row>
                      <Row className="form-group">
                        <Col md={6}>
                          <Button
                            type="submit"
                            onClick={this.handleLogin}
                            className="kyc-filter-button"
                          >
                            Login
                          </Button>

                        </Col>
                      </Row>
                    </>
                  )}
                  {!is_2FA_enabled && isValidUser && (
                    <Row className="form-group">
                      <Col md={6}>
                        <div>
                          {secret && (
                            <QRCode value={secret.otpauth_url || secret} />
                          )}
                          {secret.base32}
                        </div>
                      </Col>
                    </Row>
                  )}
                  {isValidUser && (
                    <>
                      <Row className="form-group">
                        <Col md={6}>
                          <Control.text
                            model=".token"
                            id="token"
                            name="token"
                            placeholder="Enter Google Auth Token"
                            className="form-control field"
                            onChange={this.onChange}
                            autoFocus={true}
                          />
                          <Errors
                            className="text-danger"
                            model=".token"
                            show="touched"
                            messages={{
                              required: "Required",
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="form-group">
                        <p className="col-md-6" style={{ color: 'red' }}>{this.state.errorMsg}</p>
                      </Row>
                      <Row className="form-group">
                        <Col md={6}>
                          <Button
                            type="submit"
                            onClick={this.handleQRTokenValidate}
                            className="kyc-filter-button"
                          >
                            Submit
                          </Button>

                        </Col>
                      </Row>
                      <Row className="form-group">
                        <Col md={6}>
                          {is_2FA_enabled && (
                            <Link
                              // to="/2fa-reset-otp"
                              to={{
                                pathname: "/2fa-reset-otp",
                                state: {
                                  ...this.state,
                                  // reset:()=>{}
                                  // resetState: this.resetState,
                                },
                              }}
                              onClick={this.handle2FASendOtp}
                            >
                              Can't access your device, Reset here{" "}
                            </Link>
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row className="form-group">
                    <Col md={{ size: 6 }}>
                      <Link to={"/verify-otp"}>Reset Password</Link>
                    </Col>
                  </Row>
                </>
              </LocalForm>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default HomeComponent;
