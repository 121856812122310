import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import Loader from 'components/Others/Loader';
import { sess_post } from 'services/HttpRequests';

export default function UpgradeToPro(props) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [reason, setReason] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const closeHandler = () => {
    setError('');
    setConfirmationMessage('');
    setReason('');
    setProcessing(false);
    setIsPopupOpen(false);
    if (success) {
      window.location.reload();
    } else {
      setSuccess(false);
    }
  };

  const upgradeHandler = () => {
    if (reason === '') return setError('Provide reason');
    if (confirmationMessage !== 'UPGRADE') return setError('confirm action');
    const session_token = localStorage.getItem('session_token');
    setProcessing(true);
    sess_post(
      'admin/console/upgrade-users-to-pro?type=user_id', session_token,
      { users: [props.userId], reason },
    )
      .then((res) => res.data)
      .then((res) => {
        setError('');
        setSuccess(true);
        setProcessing(false);
      })
      .catch((err) => {
        setError(err?.response?.data?.error?.reason || 'an error occured');
        setProcessing(false);
      });
  };

  return (
    <div>
      <button className="btn btn-primary" onClick={() => setIsPopupOpen(true)}>Upgrade</button>
      <Popup open={isPopupOpen} closeOnEscape={false} closeOnDocumentClick={false}>
        <div className="Modal">
          {processing ? (
            <div
              style={{ textAlign: 'center', margin: '30px 0' }}
            >
              <Loader />
            </div>
          ) : (
            <>
              <h4
                style={{
                  textAlign: 'center',
                  margin: '30px 0',
                }}
              >
                {success ? (
                  <h5 style={{ color: 'limegreen' }}>
                    user upgraded to Pro plan
                  </h5>
                ) : (
                  'Are you sure you want to upgrade this user to Pro ?'
                )}
              </h4>
              {!success && (
                <>
                  <form>
                    <label style={{ 'margin-left': '35px' }}>
                      <p style={{ fontSize: '12px', margin: 0 }}> Reason:</p>
                      <textarea value={reason} onChange={(e) => { setReason(e.target.value); }} style={{ 'margin-top': '5px' }} />
                    </label>
                    <label style={{ 'margin-left': '35px' }}>
                      <p style={{ fontSize: '12px', margin: 0 }}>Please enter the text "UPGRADE" as-is to confirm the action.</p>
                      <input
                        value={confirmationMessage}
                        placeholder="UPGRADE"
                        onChange={(e) => { setConfirmationMessage(e.target.value); }}
                        style={{ 'margin-top': '5px' }}
                      />
                    </label>
                  </form>
                  <p style={{ color: 'red', textAlign: 'center' }}>
                    {error}
                  </p>
                  <button
                    onClick={upgradeHandler}
                    style={{ margin: '20px' }}
                  >
                    Confirm
                  </button>
                </>
              )}
              <button
                onClick={closeHandler}
                style={{ 'margin-left': '100px' }}
              >
                Close
              </button>
            </>
          )}
        </div>

      </Popup>
    </div>
  );
}
