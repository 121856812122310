/* eslint-disable */
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { sess_get } from 'services/HttpRequests';
import { CSVLink } from 'react-csv';
import { Table } from 'reactstrap';
import { toast } from 'react-toastify';
import Navbar from '../../Others/NavbarComponent';
import Pagination from '../../Payments/History/Pagination';

const inputStyle = {
  display: 'block',
  width: '300px',
  padding: '0.375rem 0.75rem',
  fontSize: '1rem',
  lineHeight: '1.5',
  color: '#495057',
  backgroundColor: '#fff',
  backgroundClip: 'padding-box',
  border: '1px solid #ced4da',
  borderRadius: '0.25rem',
  transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
};

const headers = [
  { label: 'User Type', key: 'userType' },
  { label: 'Agent', key: 'agent' },
  { label: 'User ID', key: 'user_id' },
  { label: 'Name', key: 'name' },
  { label: 'Number', key: 'number' },
  { label: 'Date', key: 'date' },
  { label: 'Time', key: 'time' },
  { label: 'Reason', key: 'reason' },
  { label: 'Call Tag', key: 'tag' },
  { label: 'Note', key: 'note' },
];

export default function SupportLogs() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloadData, setDownloadData] = useState([]);
  const [searchFor, setSearchFor] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const csvLink = useRef(null);

  useEffect(() => {
    getSupportCallsData();
  }, []);

  const getSupportCallsData = (data) => {
    const session_token = localStorage.getItem('session_token');
    const searchData = { pageNo, pageSize: 10 };
    if (data?.pageNo) searchData.pageNo = data.pageNo;
    if (data?.searchFor) searchData.searchFor = data.searchFor;
    if (data?.searchValue) searchData.searchValue = data.searchValue;
    if (data?.fromDate) searchData.fromDate = data.fromDate;
    if (data?.toDate) searchData.toDate = data.toDate;

    sess_get('admin/console/support-calling', session_token, searchData)
      .then((res) => {
        const formattedData = res?.data?.response?.docs?.map((item) => {
          item.agent = item?.metadata?.updated_by || '-';
          item.userType = item?.is_existing_user ? 'EXISTING' : 'INTERESTED';
          item.date = moment.utc(item.time).format('DD-MM-YY');
          item.time = moment.utc(item.time).format('HH:mm');
          item.user_id = item?.user_id || '-';
          item.name = item.name || '-';
          item.number = item.number || '-';
          item.reason = item.reason || '-';
          item.tag = item.tag || '-';
          return item;
        });
        setData(formattedData);
        setPageNo(res?.data?.response?.pageNo);
        setTotalPages(res?.data?.response?.totalPages);
      })
      .catch((err) => {
        toast.error('an Error occured');
      });
  };

  const getSupportCallsReport = (data) => {
    if (loading) return;
    const session_token = localStorage.getItem('session_token');
    const searchData = { pageNo: 1, pageSize: 10000 };
    if (searchFor) searchData.searchFor = searchFor;
    if (searchValue) searchData.searchValue = searchValue;
    if (fromDate) searchData.fromDate = fromDate;
    if (toDate) searchData.toDate = toDate;
    setLoading(true);
    sess_get('admin/console/support-calling', session_token, searchData)
      .then((res) => {
        const formattedData = res?.data?.response?.docs?.map((item) => {
          item.agent = item?.metadata?.updated_by || '-';
          item.userType = item?.is_existing_user ? 'EXISTING' : 'INTERESTED';
          item.date = moment.utc(item.time).format('DD-MM-YY');
          item.time = moment.utc(item.time).format('HH:mm');
          item.user_id = item?.user_id || '-';
          item.name = item.name || '-';
          item.number = item.number || '-';
          item.reason = item.reason || '-';
          item.tag = item.tag || '-';
          return item;
        });
        setDownloadData(formattedData);
        csvLink.current.link.click();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error('an Error occured');
      });
  };

  let tableData = null;

  if (data.length > 0) {
    tableData = data.map((item) => (
      <tr style={{
        margin: '3px', borderTop: '1px solid black', fontSize: '12px', fontWeight: 'normal',
      }}
      >
        <td style={{ minWidth: '130px' }}>{item?.is_existing_user ? 'EXISTING' : 'INTERESTED'}</td>
        <td style={{ minWidth: '220px' }}>{item?.agent}</td>
        <td style={{ minWidth: '190px' }}>{item.user_id}</td>
        <td style={{ minWidth: '150px' }}>{item.name}</td>
        <td style={{ minWidth: '130px' }}>{item.number}</td>
        <td style={{ minWidth: '110px' }}>{item.date}</td>
        <td style={{ minWidth: '90px' }}>{item.time}</td>
        <td style={{ minWidth: '130px' }}>{item.reason}</td>
        <td style={{ minWidth: '130px' }}>{item.tag}</td>
        <td style={{ whiteSpace: 'pre-line', wordBreak: 'break-all' }}>{item.note}</td>
      </tr>
    ));
  }

  return (
    <div>
      <Navbar />
      <div style={{ width: '96%', margin: '50px auto' }}>
        <div style={{
          display: 'flex', alignItems: 'center', marginBottom: '30px', justifyContent: 'space-between',
        }}
        >
          <div />
          <h1 style={{ margin: 0 }}>Support Call History</h1>
          <button
            style={{
              color: 'white',
              backgroundColor: 'black',
              fontSize: '18px',
              padding: '4px 10px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
            onClick={getSupportCallsReport}
          >
            {loading ? 'generating file ...' : 'Download Data'}
          </button>

        </div>
        <form
          style={{
            padding: '10px', margin: '20px', display: 'flex', gap: '10px',
          }}
          onSubmit={(e) => {
            e.preventDefault(); getSupportCallsData({
              searchFor, searchValue, fromDate, toDate,
            });
          }}
          onReset={(e) => { e.preventDefault(); getSupportCallsData({ pageNo: 1 }); }}
        >
          <select
            className="form-control"
            style={{ width: '300px' }}
            onChange={(e) => {
              setSearchFor(e.target.value);
              setSearchValue('');
              setFromDate('');
              setToDate('');
            }}
          >
            <option>---select---</option>
            <option value="user_type">User type</option>
            <option value="agent">Agent</option>
            <option value="user_id">User ID</option>
            <option value="name">Name</option>
            <option value="number">Number</option>
            <option value="date">Date</option>
            <option value="reason">Reason</option>
            <option value="note">Note</option>
          </select>
          {
            searchFor === 'date'
            && (
              <>
                <input
                  className="form-control"
                  style={{ width: '300px' }}
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
                <input
                  className="form-control"
                  style={{ width: '300px' }}
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </>
            )
          }
          {
            searchFor === 'user_type'
            && (
              <select
                className="form-control"
                style={{ width: '300px' }}
                onChange={(e) => setSearchValue(e.target.value)}
              >
                <option>---select---</option>
                <option value="EXISTING">EXISTING</option>
                <option value="INTERESTED">INTERESTED</option>
              </select>
            )
          }
          {
            searchFor === 'reason'
            && (
              <select
                className="form-control"
                style={{ width: '300px' }}
                onChange={(e) => setSearchValue(e.target.value)}
              >
                <option>---select---</option>
                <option value="RENEWAL">RENEWAL</option>
                <option value="SUPPORT">SUPPORT</option>
                <option value="DEMO">DEMO</option>
                <option value="FEEDBACK">FEEDBACK</option>
              </select>
            )
          }
          {!['date', 'user_type', 'reason'].includes(searchFor)
            && (
              <input
                className="form-control"
                style={{ width: '300px' }}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            )}

          <button type="submit" className="btn btn-primary">Search</button>
          <button type="reset" className="btn btn-primary">Clear</button>
        </form>
        <Table responsive className="newTable" style={{ margin: '0 0 5px 0', width: '100%' }}>
          <thead>
            <tr style={{ borderBottom: '1px solid black' }}>
              <th>User Type</th>
              <th>Agent</th>
              <th>User ID</th>
              <th>Name</th>
              <th>Number</th>
              <th style={{ minWidth: '100px' }}>Date</th>
              <th>Time</th>
              <th>Reason</th>
              <th>Call Tag</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            {tableData}
          </tbody>
        </Table>
        <Pagination
          currentPage={pageNo}
          lastPage={totalPages}
          getInfo={getSupportCallsData}
        />
      </div>
      <CSVLink
        style={{ textAlign: 'right' }}
        data={downloadData}
        filename={`support_calls_history${moment().format('DD-MM-YYYY')}.csv`}
        headers={headers}
        ref={csvLink}
      />
    </div>

  );
}
