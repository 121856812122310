/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  UncontrolledDropdown,
} from 'reactstrap';
import { Logout } from '../../services/LoginRequest';

class NavbarComponent extends Component {
  constructor(props) {
    super(props);
    this.toggleNav = this.toggleNav.bind(this);
    this.logout = this.logout.bind(this);
    // this.ShowNavOnlyIfUserLogged = this.ShowNavOnlyIfUserLogged.bind(this)

    this.state = {
      isNavOpen: false,
      // eslint-disable-next-line react/no-unused-state
      isLogged: localStorage.getItem('isLogged'),
      isClickedLoggedOut: false,
    };
    if (localStorage.getItem('session_token')) {
      window.$isLogged = true;
    }
  }

  logout = () => {
    Logout().then(() => {
      this.setState({ isLogged: false });
      localStorage.removeItem('session_token');
      localStorage.removeItem('isLogged');
      localStorage.removeItem('care_portal_user_name');
      this.setState({ isClickedLoggedOut: true });
    });
  };

  toggleNav() {
    const { isNavOpen } = this.state;
    this.setState({
      isNavOpen: !isNavOpen,
    });
  }

  render() {
    const { isClickedLoggedOut, isNavOpen } = this.state;
    if (isClickedLoggedOut) {
      return <Redirect to="/home" />;
    }
    const ShowNavOnlyIfUserLogged = () => {
      if (window.$isLogged) {
        return (
          <div>
            <NavbarToggler
              onClick={() => this.setState({ isNavOpen: !isNavOpen })}
            />
            <Collapse isOpen={isNavOpen} navbar className="ml-auto">
              <Nav navbar className="ml-auto">
                <NavItem>
                  <NavLink
                    className={window.$isLogged ? 'nav-link' : 'hide'}
                    to="/kyc"
                  >
                    KYC
                    {' '}
                  </NavLink>
                </NavItem>

                <UncontrolledDropdown
                  nav
                  inNavbar
                  className={window.$isLogged ? '' : 'hide'}
                >
                  <DropdownToggle nav caret>
                    Payments
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>
                      <NavLink
                        style={{ color: 'black' }}
                        className={window.$isLogged ? 'nav-link' : 'hide'}
                        to="/payments-history"
                      >
                        History
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink
                        style={{ color: 'black' }}
                        className={window.$isLogged ? 'nav-link' : 'hide'}
                        to="/doosra-payment-links"
                      >
                        Doosra Payment Links
                      </NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem>
                  <NavLink
                    className={window.$isLogged ? 'nav-link' : 'hide'}
                    to="/numberanalyser"
                  >
                    Number Analyser
                    {' '}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={window.$isLogged ? 'nav-link' : 'hide'}
                    to="/reports"
                  >
                    Reports
                    {' '}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={window.$isLogged ? 'nav-link' : 'hide'}
                    to="/gift-cards"
                  >
                    Gift Cards
                    {' '}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={window.$isLogged ? 'nav-link' : 'hide'}
                    to="/add-kyc"
                  >
                    add KYC
                    {' '}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={window.$isLogged ? 'nav-link' : 'hide'}
                    to="/discount"
                  >
                    Discount
                    {' '}
                  </NavLink>
                </NavItem>
                <UncontrolledDropdown
                  nav
                  inNavbar
                  className={window.$isLogged ? '' : 'hide'}
                >
                  <DropdownToggle nav caret>
                    Logs
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>
                      <NavLink
                        style={{ color: 'black' }}
                        className={window.$isLogged ? 'nav-link' : 'hide'}
                        to="/message-log"
                      >
                        Message logs
                        {' '}
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink
                        style={{ color: 'black' }}
                        className={window.$isLogged ? 'nav-link' : 'hide'}
                        to="/support-logging"
                      >
                        Support Call Logs
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink
                        style={{ color: 'black' }}
                        className={window.$isLogged ? 'nav-link' : 'hide'}
                        to="/support-log-view"
                      >
                        Support Call History
                      </NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem className="mov-leftt">
                  <a
                    className={window.$isLogged ? 'nav-link' : 'hide'}
                    onClick={this.logout}
                    style={{ color: '#ed3833', cursor: 'pointer' }}
                  >
                    Logout
                    {' '}
                  </a>
                </NavItem>
              </Nav>
            </Collapse>
          </div>
        );
      }
      return <div />;
    };
    return (
      <Navbar dark expand="sm">
        <Container>
          <NavbarBrand href="/" className="mr-auto">
            <h4 style={{ display: ' table-cell' }}>
              <img src="new_icon.png" alt="icon" className="Navbar-logo" />
              {' '}
              Support Portal
            </h4>
          </NavbarBrand>
          <ShowNavOnlyIfUserLogged />
        </Container>
      </Navbar>
    );
  }
}

export default NavbarComponent;
