import React, { Component } from "react";

class SearchPaymentLinks extends Component {
  constructor() {
    super();
    this.state = {
      searchKey: "",
      searchValue: "",
      fromDate: "",
      toDate: "",
    };
  }
  changeHandler = (e) => {
    console.log(e.target.value);
    this.setState({ [e.target.id]: e.target.value });
  };

  dropDownSelectHandler = (e) => {
    this.setState({
      searchKey: e.target.value,
      searchValue: "",
      fromDate: "",
      toDate: "",
    });
  };

  searchHandler = () => {
    if (
      this.state.searchKey === "link_expire_by" ||
      this.state.searchKey === "created_at"
    ) {
      this.props.searchLinks({
        search_key: this.state.searchKey,
        from_date: this.state.fromDate,
        to_date: this.state.toDate,
      });
    } else {
      this.props.searchLinks({
        search_key: this.state.searchKey,
        search_value: this.state.searchValue,
      });
    }
  };

  render() {
    let searchInput;
    switch (this.state.searchKey) {
      case "created_at":
      case "link_expire_by":
        searchInput = (
          <React.Fragment>
            <input
              id="fromDate"
              value={this.state.fromDate}
              onChange={this.changeHandler}
              className="SearchBox"
              placeholder="From..."
              type="date"

            />
            <input
              id="toDate"
              value={this.state.toDate}
              onChange={this.changeHandler}
              className="SearchBox"
              placeholder="To..."
              type="date"

            />
          </React.Fragment>
        );
        break;
      case "status":
        searchInput = (
          <select
            id="searchValue"
            onChange={this.changeHandler}
            value={this.state.searchValue}
          >
            <option> --- select ---</option>
            <option value="created">Created</option>
            <option value="paid">Paid</option>
            <option value="expired">Expired</option>
            <option value="cancelled">Cancelled</option>
          </select>
        );
        break;
      default:
        searchInput = (
          <input
            id="searchValue"
            value={this.state.searchValue}
            onChange={this.changeHandler}
            className="SearchBox"
            placeholder="Search..."
            style={{height:'32px'}}
            autoFocus={true}
          />
        );
        break;
    }
    return (
      <div>
        <select
          id="searchKey"
          onChange={this.dropDownSelectHandler}
          value={this.state.searchKey}
          className="btn-primary btn mov-right"
          style={{'margin-bottom': '2px'}}
        >
          <option>---Select---</option>
          <option value="link_id">Payment Id</option>
          <option value="amount">Amount</option>
          <option value="receipt">Receipt no.</option>
          <option value="customer_number">Contact Number</option>
          <option value="customer_email">Contact Email</option>
          <option value="status">Status</option>
          <option value="created_at">Created At</option>
          <option value="link_expire_by">Expiry Date</option>
          <option value="created_by">Created By</option>
        </select>

        {searchInput}

        <button class="btn btn-primary mov-left" onClick={this.searchHandler} style={{'margin-bottom': '2px'}}>Search</button>
      </div>
    );
  }
}

export default SearchPaymentLinks;
