import React, { Component } from "react";
import Popup from "reactjs-popup";

class PaymentLinksPopup extends Component {
  constructor() {
    super();
    this.state = {
      isPopupOpen: false,
      amount: "",
      description: "",
      customer_name: "",
      customer_number: "",
      customer_email: "",
      org_name: "",
      org_id: "",
      email_notify: false,
      sms_notify: false,
      reference_id: "",
      expire_by: "",
      error: "",
      loading: false
    };
    this.initialState = this.state;
  }

  openPopup = () => {
    this.setState({ isPopupOpen: true });
  };

  closePopup = () => {
    this.setState(this.initialState);
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onToggleCheckbox = (e) => {
    this.setState({ [e.target.id]: !this.state[e.target.id] });
  };

  createLinkAndClosePopup = () => {
    this.setState({ loading: true });
    this.props.createPaymentLink(this.state, (res) => {
      if (res?.response?.data?.success === false) {
        this.setState({ error: res.response.data.error.reason, loading: false });
      } else {
        this.setState(this.initialState);
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <button class="btn-primary btn" onClick={this.openPopup}>Create Payment Link</button>
        <Popup
          open={this.state.isPopupOpen}
          onClose={this.closePopup}
          closeOnDocumentClick={false}
        >
          <div className="Modal" style={{ textAlign: "center" }}>
            <div style={{ margin: "10px 0" }}>
              <input
                type="number"
                value={this.state.amount}
                id="amount"
                placeholder="Amount"
                onChange={this.onChange}
              />
              <span
                style={{ color: "red", fontSize: "18px", marginLeft: "2px" }}
              >
                *
              </span>
              <input
                value={this.state.description}
                id="description"
                onChange={this.onChange}
                placeholder="Description"
              />
              <span
                style={{ color: "red", fontSize: "18px", marginLeft: "2px" }}
              >
                *
              </span>
            </div>
            <div style={{ margin: "10px 0" }}>
              <label style={{ width: "100%", margin: "0" }}>
                <strong>Customer Details</strong>
              </label>
              <input
                id="customer_name"
                value={this.state.customer_name}
                onChange={this.onChange}
                placeholder="Customer Name"
              />
              <div>
                <input
                  id="customer_email"
                  value={this.state.customer_email}
                  onChange={this.onChange}
                  placeholder="Customer Email"
                />
                <input
                  id="customer_number"
                  value={this.state.customer_number}
                  onChange={this.onChange}
                  placeholder="Customer Contact Number"
                />
              </div>
            </div>
            <div>
              <label style={{ width: "100%", margin: "0" }}>
                <strong>Notify By</strong>
              </label>
              <label>
                <input
                  style={{ marginRight: "4px" }}
                  id="email_notify"
                  defaultChecked={this.state.email_notify}
                  onChange={this.onToggleCheckbox}
                  type="checkbox"
                  disabled={!this.state.customer_email}
                />
                Email
              </label>
              <label style={{ marginLeft: "20px" }}>
                <input
                  id="sms_notify"
                  style={{ marginRight: "4px" }}
                  defaultChecked={this.state.sms_notify}
                  onChange={this.onToggleCheckbox}
                  type="checkbox"
                  disabled={!this.state.customer_number}
                />
                Number
              </label>
            </div>
            <div style={{ margin: "10px 0" }}>
              <label style={{ margin: "0" }}>
                <strong>Receipt no / Reference Id</strong>
              </label>
              <input
                id="reference_id"
                onChange={this.onChange}
                value={this.state.reference_id}
                placeholder="Reference id /receipt no"
              />
            </div>
            <div>
              <label style={{ width: "100%", margin: "0" }}>
                <strong>Link Expiry</strong>
              </label>

              <input
                id="expire_by"
                type="date"
                onChange={this.onChange}
                value={this.state.expire_by}
              />
            </div>

            <p style={{ color: "red" }}>{this.state.error}</p>
            <div style={{ display: "flex", justifyContent: "space-around" }}>

              <button onClick={this.createLinkAndClosePopup} disabled={this.state.loading}>Create</button>


              <button onClick={this.closePopup} disabled={this.state.loading}>Close</button>

            </div>
          </div>
        </Popup>
      </React.Fragment>
    );
  }
}

export default PaymentLinksPopup;
