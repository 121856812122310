/* eslint-disable */
import moment from 'moment';
import React from 'react';
import { Table } from 'reactstrap';

function TableHeader() {
  return (
    <thead>
      <tr>
        <th>Date</th>
        <th>Time</th>
        <th>Action</th>
        <th>Document</th>
        <th>Reason</th>
      </tr>
    </thead>
  );
}

// eslint-disable-next-line react/prefer-stateless-function
export default function UserKycActivity(props) {
  if (props?.list?.length === 0) return null;

  /* eslint-disable react/prop-types */

  const displayAction = (activity) => activity?.action || '-';

  const displayReason = (activity) => {
    if (activity?.metadata?.reason) {
      return activity?.metadata?.reason;
    }

    if (activity?.reason) {
      return activity?.reason;
    }

    return '-';
  };

  const doucmentType = (activity) => {
    if (activity?.metadata?.document_type) {
      return activity?.metadata?.document_type;
    }
    return '-';
  };

  if (props?.list?.length > 0) {
    return (
      <>
        <h1 className="demo" style={{ color: 'brown' }}> KYC Activity </h1>
        <Table responsive className="newTable" style={{ 'margin-bottom': '5px' }}>
          <TableHeader />
          <tbody>
            {props.list.map((activity) => (
              <tr>
                <td>{activity?.created_at ? moment(new Date(activity.created_at)).format('YYYY-MM-DD') : '-'}</td>
                <td>{activity?.created_at ? moment(new Date(activity.created_at)).format('HH:mm') : '-'}</td>
                <td>{displayAction(activity)}</td>
                <td>{doucmentType(activity)}</td>
                <td>{displayReason(activity)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  }
}
