/* eslint-disable */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Loader from 'components/Others/Loader';
import Navbar from '../../Others/NavbarComponent';
import { sess_get, sess_post } from '../../../services/HttpRequests';

const inputHolder = { display: 'flex', flexDirection: 'column', textAlign: 'left' };
const buttonOne = {
  padding: '8px 20px', border: 'none', color: 'white', background: 'black', borderRadius: '8px',
};

export default function SupportLogging() {
  const [userExists, setUserExists] = useState(false);
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [data, setData] = useState({ is_existing_user: true });
  const [shouldShowTags, changeShowTagsStatus] = useState(false);

  let view;

  const onChangeHandler = (e) => {
    const { id, value } = e.target;
    e.preventDefault(e);
    setData((data) => ({ ...data, [id]: value }));
    if ((id === 'reason' && value === 'RENEWAL') || data.reason === 'RENEWAL') changeShowTagsStatus(true);
    else changeShowTagsStatus(false);
  };

  const checkForUserAvailability = () => {
    if (!data.user_id) return toast.error('Provide user id');
    const session_token = localStorage.getItem('session_token');
    setSubmitting(true);
    sess_get('admin/console/user-exists', session_token, { user_id: data.user_id })
      .then((res) => {
        if (res?.data?.response?.docs?.user_id) {
          setUserExists(true);
          setError('');
          setSubmitting(false);
        } else {
          setUserExists(false);
          toast.error('No data received');
          setSubmitting(false);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.reason || 'An error occured');
      });
  };

  const submitHandler = () => {
    const session_token = localStorage.getItem('session_token');
    setSubmitting(true);
    sess_post('admin/console/support-calling', session_token, data)
      .then((res) => {
        if (res?.data?.response?.message) {
          setUserExists(false);
          setError('');
          setData({ user_id: '', is_existing_user: true });
          toast.success('Data uploaded successfully');
        } else {
          toast.error('No data receicved');
        }
        setSubmitting(false);
      })
      .catch((err) => {
        console.log('errr', err);
        toast.error(err?.response?.data?.error?.reason || 'An error occured');
        setSubmitting(false);
      });
  };

  switch (data.is_existing_user) {
    case false:
      view = (
        <div style={{
          border: '1px solid black', padding: '20px', borderRadius: '10px', backgroundColor: '#ffebdd',
        }}
        >
          <form style={{
            display: 'flex', justifyContent: 'space-around', marginBottom: '20px', alignItems: 'center', gap: '20px',
          }}
          >
            <div style={inputHolder}>
              <label>Enter Name</label>
              <input
                value={data.name}
                id="name"
                onChange={onChangeHandler}
              />
            </div>
            <div style={inputHolder}>
              <label>Enter Number</label>
              <input
                value={data.number}
                id="number"
                onChange={onChangeHandler}
              />
            </div>
            <div style={inputHolder}>
              <label>Date and Time</label>
              <input
                type="datetime-local"
                value={data.time}
                id="time"
                onChange={onChangeHandler}
              />
            </div>
            <div style={{ ...inputHolder, flex: 2 }}>
              <label>Note</label>
              <textarea
                value={data.note}
                id="note"
                onChange={onChangeHandler}
              />
            </div>
          </form>
          <button style={buttonOne} onClick={submitHandler}>Submit</button>
        </div>
      );
      break;
    case true:
      view = (
        <div style={{
          border: '1px solid black', padding: '20px', borderRadius: '10px', backgroundColor: '#ffebdd',
        }}
        >
          <div style={{
            display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginBottom: '20px', gap: '20px',
          }}
          >
            <div style={inputHolder}>
              <label>User ID</label>
              <input
                placeholder="User ID"
                value={data.user_id}
                id="user_id"
                readOnly={userExists}
                onChange={onChangeHandler}
              />
            </div>
            {!userExists
              ? (
                <>
                  {
                    submitting
                      ? <button style={buttonOne} onClick={checkForUserAvailability}><Loader style={{ width: '20px', height: '20px' }} /></button>
                      : <button style={buttonOne} onClick={checkForUserAvailability}>Check</button>
                  }
                  <div />
                  <div />
                </>
              )
              : (
                <>
                  <div style={inputHolder}>
                    <label>Date and Time</label>
                    <input
                      type="datetime-local"
                      value={data.time}
                      id="time"
                      onChange={onChangeHandler}
                    />
                  </div>
                  <div style={inputHolder}>
                    <label>Call Reason</label>
                    <select
                      value={data.reason}
                      id="reason"
                      onChange={onChangeHandler}
                    >
                      <option value="" selected disabled hidden>--- select ---</option>
                      <option value="DEMO">Demo</option>
                      <option value="FEEDBACK">Feedback</option>
                      <option value="RENEWAL">Renewal</option>
                      <option value="SUPPORT">Support</option>
                    </select>
                  </div>
                  {shouldShowTags
                    ? (
                      <div style={inputHolder}>
                        <label>Call Tags</label>
                        <select
                          value={data.tag}
                          id="tag"
                          onChange={onChangeHandler}
                        >
                          <option value="" selected disabled hidden>--- select ---</option>
                          <option value="Unable to reach the user">Unable to reach the user</option>
                          <option value="Reach out later">Reach out later</option>
                          <option value="Confused/ Need to think about it">Confused/ Need to think about it</option>
                          <option value="KYC issue">KYC issue</option>
                          <option value="Interested in Alohaa">Interested in Alohaa</option>
                          <option value="Demo needed">Demo needed</option>
                          <option value="Need more discounts/Offers">Need more discounts/Offers</option>
                          <option value="Happy Customer">Happy Customer</option>
                          <option value="Wants to change number">Wants to change number</option>
                          <option value="Interested to renew">Interested to renew</option>
                          <option value="Not an active Doosra User">Not an active Doosra User</option>
                          <option value="Purchased by someone else">Purchased by someone else</option>
                          <option value="Purchased by employer">Purchased by employer</option>
                          <option value="Want Feature Improvements">Want Feature Improvements</option>
                          <option value="Not Interested DND/DNC">Not Interested DND/DNC</option>
                          <option value="Interested DND/DNC">Interested DND/DNC</option>
                        </select>
                      </div>
                    ) : null}
                  <div style={{ ...inputHolder, flex: 2 }}>
                    <label>Note</label>
                    <textarea
                      value={data.note}
                      id="note"
                      onChange={onChangeHandler}
                    />
                  </div>
                </>
              )}
          </div>
          {
            userExists
            && <button style={buttonOne} onClick={submitHandler}>Submit</button>
          }
        </div>
      );
    default:
      break;
  }

  return (
    <div>
      <Navbar />
      <div style={{ width: '1200px', margin: '50px auto' }}>
        <h1>Add Activity</h1>
        <div style={{ textAlign: 'left', padding: '20px 0' }}>
          <input
            type="checkbox"
            checked={data.is_existing_user}
            onChange={(e) => { setData({ is_existing_user: true }); }}
          />
          <label>Existing User</label>
          <input
            style={{ marginLeft: '30px' }}
            type="checkbox"
            checked={!data.is_existing_user}
            onChange={(e) => { setData({ is_existing_user: false }); }}
          />
          <label>Interested User</label>
        </div>
        {view}
      </div>
    </div>
  );
}
