/* eslint-disable */
import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import Loader from 'components/Others/Loader';
import { PUT } from 'services/HttpRequests';

export default function WhitelistUser(props) {
  const [processing, setProcessing] = useState(false);
  const [reason, setReason] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const closeHandler = () => {
    setError('');
    setIsPopupOpen(false);
    setConfirmationMessage('');
    setReason('');
    setProcessing(false);
    if (success) {
      window.location.reload();
    }
  };

  const whitelistUserHandler = () => {
    if (!reason) return setError('Provide reason');
    if (confirmationMessage !== 'WHITELIST') return setError('invalid confirmation value');
    setProcessing(true);
    PUT('v1/user/whitelist', {}, { userId: props.userId, reason })
    .then((res) => {
      if (res?.data?.error) {
        setSuccess(false);
        setError(res?.data?.error?.reason);
        setProcessing(false);
      } else {
        setError('');
        setSuccess(true);
        setProcessing(false);
      }
    })
    .catch((err) => {
      setSuccess(false);
      setError(err?.response?.data?.error?.reason || 'an error occured');
      setProcessing(false);
    });
  };

  return (
    <div>
      <button className="btn btn-danger" onClick={() => setIsPopupOpen(true)}>Whitelist User</button>
      <Popup
        open={isPopupOpen}
        onClose={closeHandler}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className="Modal">
          {processing ? (
            <div
              style={{ textAlign: 'center', margin: '30px 0' }}
            >
              <Loader />
            </div>
          ) : (
            <>
              <h4
                style={{
                  textAlign: 'center',
                  margin: '30px 0',
                }}
              >
                {success ? (
                  <h5 style={{ color: 'limegreen' }}>
                    User has been Whitelisted
                  </h5>
                ) : (
                  'Do you want to whitelist this user?'
                )}
              </h4>
              {!success && (
                <>
                  <form>
                    <label style={{ 'margin-left': '35px' }}>
                      Reason:
                      <textarea value={reason} onChange={(e) => setReason(e.target.value)} style={{ 'margin-top': '5px', width: '100%' }} />
                    </label>
                    <label style={{ 'margin-left': '35px' }}>
                      <p style={{ fontSize: '12px', margin: 0 }}>Please enter the text "WHITELIST" as-is to confirm the action.</p>
                      <input
                        value={confirmationMessage}
                        placeholder="WHITELIST"
                        onChange={(e) => setConfirmationMessage(e.target.value)}
                        style={{ 'margin-top': '5px' }}
                      />
                    </label>
                  </form>
                  <p style={{ color: 'red', textAlign: 'center' }}>
                    {error}
                  </p>
                  <button
                    onClick={whitelistUserHandler}
                    style={{ margin: '20px' }}
                  >
                    Confirm
                  </button>
                </>
              )}
              <button
                onClick={closeHandler}
                style={{ 'margin-left': '100px' }}
              >
                Close
              </button>
            </>
          )}
        </div>
      </Popup>
    </div>
  );
}
