import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { Col, Row, Table } from 'reactstrap';
import { Delete, sess_post } from '../../services/HttpRequests';
import Loader from '../Others/Loader';
import DowngradeUser from './DowngradeUser';
import ExtendValidity from './ExtendValidityPopup';
import PaymentDetails from './KYCUserPaymentsModal';
import PayoutDetails from './PayoutDetails';
import SuspendUser from './SuspendUserPopup';
import UnblockUser from './unblockUserPopup';
import PlansList from './UserPlansHistory';
import UpgradeToPro from './UpgradeToPro';
import UnsuspendUser from './UnsuspendUserPopup';
import TemporaryReactivateUser from './TempReactivatePopup';
import WhitelistUser from './WhitelistPopup';

class KYCTable extends Component {
  constructor(props) {
    super(props);
    // this.toggleModal = this.toggleModal.bind(this);
    // this.StoreSelectedUser = this.StoreSelectedUser.bind(this)
    this.getUserStatus = this.getUserStatus.bind(this);
    // this.UserRow = this.UserRow.bind(this)
    this.state = {
      // isModalOpen: false,
      selectedUserId: '',
      selectedUser: {},
      downgradeUserPopupOpen: false,
      unBlockUserPopupOpen: false,
      suspendStatePopupOpen: false,
      successMessage: '',
      successMsg: '',
      passMsg: '',
      processing: false,
      stateChange: false,
      running: false,
      error: '',
      errorMsg: '',
      failMsg: '',
      suspendReason: '',
    };
    this.updateReason = this.updateReason.bind(this);
  }

  // componentDidMount() {
  //   this.getPlanDetails(this.props.userDetails);
  // }

  // toggleModal() {
  //   this.setState({
  //     isModalOpen: !this.state.isModalOpen
  //   });
  // }
  // StoreSelectedUser = (user) => {
  //   alert(JSON.stringify(user))
  //   this.setState({
  //     'selectedUserId': user.user_id,
  //     'selectedUser': user
  //   });
  //   this.toggleModal()
  // }

  getUserStatus(user) {
    if (user.status === 'TERMINATED') return '-'; // return status as TERMINATED
    // return subscription plan (i.e. : yearly , quarterly, etc);
    if (user?.subscription_id && user?.subscription_status != 'created') {
      return `${user.statuses.plan}(SUB)`;
    }
    if (user?.statuses?.plan) return user.statuses.plan;
    return '-';
  }

  downgradeUserPopupOpen = () => {
    this.setState({ downgradeUserPopupOpen: true });
  };

  downgradeUserPopupClose = (reload) => {
    if (reload) {
      window.location.reload();
    } else {
      this.setState({ downgradeUserPopupOpen: false });
    }
  };

  unBlockUserPopupOpen = () => {
    this.setState({ unBlockUserPopupOpen: true });
  };

  unBlockUserPopupClose = (reload) => {
    if (reload) {
      window.location.reload();
    } else {
      this.setState({ unBlockUserPopupOpen: false });
    }
  };

  confirmUnBlockUser = (user_id) => {
    this.setState({ stateChange: true });
  };

  updateReason(event) {
    this.setState({ suspendReason: event.target.value });
  }

  suspendStatePopupOpen = () => {
    this.setState({ suspendStatePopupOpen: true });
  };

  suspendStatePopupClose = (reload) => {
    if (reload) {
      window.location.reload();
    } else {
      this.setState({ suspendStatePopupOpen: false });
    }
  };

  formatDate = (date) => {
    if (date === '-') {
      return date;
    }
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'Asia/Kolkata',
    }).format(new Date(Date.parse(date)));
  };

  FirebaseTokens = (token) => {
    if (!token) return 'No';
    if (token.length === 0) return 'No';
    return `Yes (${token.length})`;
  }

  render() {
    const style = {
      content: {
        border: '0',
        borderRadius: '4px',
        padding: '2rem',
        maxWidth: '57rem',
        maxheight: '10rem',
      },
    };

    const userDetails = (
      <div>
        <h1 className="demo" style={{ color: 'brown' }}>
          Account Details
        </h1>
        <Row style={{ 'text-align': 'left', 'margin-top': '10px' }}>
          <Col sm="5" style={{ 'margin-left': '20px' }}>
            <Table className="newTable">
              <tbody>
                <tr>
                  <td>
                    <strong>User Id</strong>
                  </td>
                  <td>{this.props.userDetails?.user_id}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Name</strong>
                  </td>
                  <td>{this.props.userDetails?.zvr_name}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Kyc Name</strong>
                  </td>
                  <td>{this.props.userDetails?.name_on_document || '-'}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Phone No</strong>
                  </td>
                  <td>{this.props.userDetails?.zvr_mobile_no}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Doosra No</strong>
                  </td>
                  <td>{this.props.userDetails?.v_mobile_no}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Email</strong>
                  </td>
                  <td>{this.props.userDetails?.zvr_email || '-'}</td>
                </tr>
                <tr>
                  <td>
                    <strong>User Type</strong>
                  </td>
                  <td>
                    {this.props.userDetails?.user_type}
                    {' '}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Plan Status</strong>
                  </td>
                  <td>{this.props.userDetails?.status}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Account Type</strong>
                  </td>
                  <td>{this.props.userDetails?.user_account_type || '-'}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Account State</strong>
                  </td>
                  <td>
                    {this.props.userDetails?.account_status === 'NA' ? (
                      'NA'
                    ) : (
                      <>
                        <a
                          style={{
                            color: 'red',
                            'text-decoration': ' underline',
                            cursor: 'pointer',
                          }}
                          onClick={this.unBlockUserPopupOpen}
                        >
                          {this.props.userDetails.account_status}
                        </a>
                        <UnblockUser
                          userId={this.props.userDetails.user_id}
                          isOpen={this.state.unBlockUserPopupOpen}
                          closeHandler={this.unBlockUserPopupClose}
                        />
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Sign-up via</strong>
                  </td>
                  <td>{this.props.userDetails?.sign_up_type || '-'}</td>
                </tr>
                <tr>
                  <td>
                    <strong>KYC verified</strong>
                  </td>
                  <td>
                    {this.props.userDetails?.is_kyc_completed && this.props.userDetails.is_kyc_completed
                      .toString()
                      .toUpperCase()}
                    {' '}
                    (
                    {this.props.userDetails?.kyc_done_from === ''
                      ? '-'
                      : this.props.userDetails?.kyc_done_from}
                    )
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Kyc Document Type</strong>
                  </td>
                  <td>{this.props.userDetails?.document_type || '-'}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col sm="1" />
          <Col sm="5">
            <Table className="newTable">
              <tbody>
                <tr>
                  <td>
                    <strong>Created At</strong>
                  </td>
                  <td>{this.props.userDetails?.created_at ? this.formatDate(this.props.userDetails.created_at) : '-'}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Intial_Payment/Activation Date</strong>
                  </td>
                  <td>
                    {this.props.userDetails?.initial_activated_at
                      ? this.formatDate(
                        this.props.userDetails.initial_activated_at,
                      )
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      Expires On
                      {' '}
                      <ExtendValidity
                        userId={this.props.userDetails.user_id}
                      />
                    </strong>
                  </td>
                  <td>
                    {this.props.userDetails?.expire_on
                      ? this.formatDate(this.props.userDetails.expire_on)
                      : '-'}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Active Plan</strong>
                  </td>
                  <td>{this.getUserStatus(this.props.userDetails)}</td>
                </tr>

                <tr>
                  <td>
                    <strong>Credits</strong>
                  </td>
                  <td>{this.props.userDetails?.credits}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Remaining Free Min.</strong>
                  </td>
                  <td>
                    {this.props?.userMinutes?.monthly_free_minutes_remaining}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Remaining Purchased Min.</strong>
                  </td>
                  <td>{this.props?.userMinutes?.purchased_remaining_minutes}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Referral Code</strong>
                  </td>
                  <td>{this.props.userDetails?.ref_code}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Referred By</strong>
                  </td>
                  <td>
                    {this.props.userDetails?.refered_by
                      ? this.props.userDetails.refered_by
                      : '-'}
                  </td>
                </tr>

                <tr>
                  <td>
                    <strong>Wallet Balance</strong>
                  </td>
                  <td>{this.props.userWalletBalance || '0'}</td>
                </tr>

                <tr>
                  <td>
                    <strong>FCM Tokens</strong>
                  </td>
                  <td>{this.FirebaseTokens(this.props.userDetails?.firebase_tokens)}</td>
                </tr>

                {this.props.userDetails?.user_type == 'Doosra pro'
                  && this.props.userDetails?.status != 'SUSPENDED' ? (
                  <tr>
                    <td>
                      <strong>Downgrade to Essential</strong>
                    </td>
                    <td>
                      <button className="btn btn-primary" onClick={this.downgradeUserPopupOpen}>
                        Change Plan
                      </button>
                      <DowngradeUser
                        isOpen={this.state.downgradeUserPopupOpen}
                        userId={this.props.userDetails?.user_id}
                        closeHandler={(val) => this.downgradeUserPopupClose(val)}
                      />
                    </td>
                  </tr>
                ) : null}
                {this.props.userDetails?.user_type !== 'Doosra pro'
                  && this.props.userDetails?.status !== 'SUSPENDED'
                  && this.props.userDetails?.status !== 'TERMINATED' ? (
                  <tr>
                    <td>
                      <strong>Upgrade to Pro</strong>
                    </td>
                    <td>
                      <UpgradeToPro
                        userId={this.props.userDetails?.user_id}
                      />
                    </td>
                  </tr>
                ) : null}
                {this.props.userDetails?.status !== 'SUSPENDED'
                  && this.props.userDetails?.status !== 'TERMINATED' ? (
                  <tr>
                    <td>
                      <strong>Suspend Account</strong>
                    </td>
                    <td>
                      <button className="btn btn-danger" onClick={this.suspendStatePopupOpen}>
                        Suspend
                      </button>
                      <SuspendUser
                        userId={this.props.userDetails?.user_id}
                        isOpen={this.state.suspendStatePopupOpen}
                        closeHandler={(val) => this.suspendStatePopupClose(val)}
                      />
                    </td>
                  </tr>
                ) : null}
                {this.props.userDetails?.status === 'SUSPENDED' ? (
                  <tr>
                    <td>
                      <strong>Un-Suspend Account</strong>
                    </td>
                    <td>
                      <UnsuspendUser
                        userId={this.props.userDetails?.user_id}
                      />
                    </td>
                  </tr>
                ) : null}
                {this.props.userDetails?.status === 'TERMINATED' ? (
                  <tr>
                    <td>
                      <strong>Temporary Reactivate</strong>
                    </td>
                    <td>

                      <TemporaryReactivateUser
                        userId={this.props.userDetails?.user_id}
                      />
                    </td>
                  </tr>
                ) : null}
                {this.props.userDetails?.status === 'TERMINATED' ? (
                  <tr>
                    <td>
                      <strong>Whitelist Account</strong>
                    </td>
                    <td>
                      <WhitelistUser
                        userId={this.props.userDetails?.user_id}
                      />
                    </td>
                  </tr>
                ) : null}

              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    );

    return (
      <div>
        {userDetails}
        <h1 className="demo" style={{ color: 'brown' }}>
          Plans List
        </h1>
        <PlansList user_id={this.props.userDetails?.user_id} />
        <h1 className="demo" style={{ color: 'brown' }}>
          Payment Details
        </h1>
        <PaymentDetails user_id={this.props.userDetails?.user_id} />
        <h1 className="demo" style={{ color: 'brown' }}>
          Referral Payout
        </h1>
        <PayoutDetails user_id={this.props.userDetails?.user_id} />
      </div>
    );
  }
}

export default KYCTable;
