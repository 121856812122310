import React, { useEffect, useState } from 'react';
import {
  Button, Col, Form, FormGroup, Input, Row,
} from 'reactstrap';

export default function KYCFilters(props) {
  const [searchArea, setSearchArea] = useState('consumer');
  const [searchCategory, setSearchCategory] = useState('');
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const sa = new URLSearchParams(window.location.search).get('searchArea');
    const sc = new URLSearchParams(window.location.search).get('searchCategory');
    const st = new URLSearchParams(window.location.search).get('searchText');
    if (sc) setSearchCategory(sc);
    if (st) setSearchText(st);
    if (sa) setSearchArea(sa);
  }, []);

  const search = (e) => {
    if (searchCategory && searchText) {
      let searchTextQ = searchText;
      if (searchCategory === 'v_mobile_no') {
        searchTextQ = `91${searchText}`;
      }
      props.fetchProfiles(
        searchCategory,
        searchTextQ,
        null,
        { searchArea },
      );
      e.preventDefault();
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setSearchText('');
    setSearchCategory('');
    setSearchArea('consumer');
    props.fetchProfiles('', '', null, { searchArea: 'consumer' });
  };

  // const fetchUpdatedUsers = (event) => {
  //   const { target } = event;
  //   const { value } = target;
  //   const { name } = target;

  //   let sc = "";
  //   if (props.search_category) {
  //     sc = props.search_category;
  //   }
  //   let st = "";
  //   if (props.search_text) {
  //     st = props.search_text;
  //   }
  //   let ssc = "";
  //   if (props.sub_search_category && (props.search_category === "zvr_mobile_no" || props.search_category === "zvr_email")) {
  //     ssc = props.sub_search_category;
  //   }

  //   props.fetchProfiles(value, sc, st, ssc);
  //   event.preventDefault();
  // }

  return (
    <Row className="mt-5">
      <Col xs="12" sm="12" md="12">
        <Form onSubmit={search}>
          <FormGroup row className="form-group">
            <Col md={3} className="ml-2 mt-1">
              <Input
                type="select"
                id="searchArea"
                name="searchArea"
                value={searchArea}
                className="dropdown-color form-control"
                onChange={(e) => setSearchArea(e.target.value)}
              >
                <option value="">Select area</option>
                <option value="consumer"> Consumer </option>
              </Input>
            </Col>
            <Col md={3} className="ml-2 mt-1">
              <Input
                type="select"
                id="searchCategory"
                name="searchCategory"
                value={searchCategory}
                className="dropdown-color form-control"
                onChange={(e) => setSearchCategory(e.target.value)}
              >
                <option value="">Select category</option>
                {/*
                    <option value='zvr_name'> Name </option>
                  */}
                <option value="v_mobile_no"> Doosra </option>
                <option value="zvr_mobile_no"> Mobile Number </option>
                <option value="zvr_email"> Email </option>
                <option value="ref_code"> Referral Code </option>
                <option value="user_id">User ID</option>
                <option value="payment_id">Payment Id</option>
              </Input>
            </Col>
            <Col md={3} className="mt-1">
              <Input
                type="text"
                id="search_text"
                name="searchText"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="form-control"
                autoFocus
              />
            </Col>
            <Col md={1} className="mt-1">
              <Button
                type="submit"
                color="primary"
                style={{ float: 'left' }}
                className="kyc-filter-button"
              >
                Search
              </Button>
            </Col>
            <Col md={1} className="mt-1">
              <Button
                color="primary"
                onClick={handleReset}
                className="kyc-filter-button"
              >
                Reset
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </Col>
    </Row>
  );
}
