import * as actionType from '../actions/actionTypes';
const initialState = {
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionType.SEND_OTP:
      return {
        ...state,
        ...action.payload,
        error:'Otp sent'
      };
    case actionType.VERIFY_OTP:
      return {
        ...state,
        ...action.payload,
        error:'Otp verified'
      };
      case actionType.RESET_PASSWORD:
      return {
        ...state,
        ...action.payload,
        error:''
      };
      case actionType.ERROR:
      return {
        ...state,
        error:action.payload,
      };
    default:
      return state;
  }
}
