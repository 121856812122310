import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Container, Row, Col, Button, Label } from 'reactstrap';
import { Control, LocalForm, Errors } from 'react-redux-form';
import {
  Container,
  Form,
  Input,
  Alert,
  FormGroup,
  Label,
  FormFeedback,
  FormText,
  Row,
  Col,
  Button,
} from 'reactstrap';
import NavbarComponent from '../Others/NavbarComponent';

import {
  loginUser,
  setUserFromLocalStorage,
  sendOtp,
  verifyOtp,
  setError
} from '../../actions/authActions';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

const REACT_APP_REDIRECT_AFTER =
  process.env.REACT_APP_REDIRECT_AFTER * 1000 || 10 * 1000;
const REACT_APP_RESEND_OTP_AFTER = process.env.REACT_APP_RESEND_OTP_AFTER || 30;

class Otp extends Component {
  constructor() {
    super();
    this.state = {
      phone_number: '',
      otp: '',
      otpDisabled: true,
    };
  }

  componentDidMount() {}

  verifyOtp = (e) => {
    e.preventDefault();
    const { otp } = this.state;
    const { phone_number, secret } = this.props.auth;
    if (phone_number && secret && otp) {
      this.props
        .verifyOtp({ phone_number, secret, otp })
        .then((res) => {
          // setTimeout(() => {
          //   // this.props.history.push("/");
          //   this.props.resetState();
          //   this.props.history.push('/');
          // }, REACT_APP_REDIRECT_AFTER);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  sendOtp = (e) => {
    e.preventDefault();
    const { phone_number } = this.state;
    this.setState({ otp: '' });
    if (!phone_number) {
      this.props.setError('Phone number must not be empty');
      return this.setState({ error: 'Phone number must not be empty' });
    }
    if (phone_number.length != 10) {
      this.props.setError('Phone number length must be of length 10')
      return this.setState({
        error: 'Phone number length must be of length 10',
      });
    }
    if (phone_number) {
      this.props
        .sendOtp(phone_number)
        .then((res) => {
          this.initiateTimer();
          this.setState({ otpDisabled: false });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  initiateTimer = () => {
    clearInterval(this.timer);
    this.setState({ seconds: REACT_APP_RESEND_OTP_AFTER });
    this.timer = setInterval(() => {
      if (this.state.seconds <= 0) {
        clearInterval(this.timer);
        this.setState({ seconds: '' });
        return;
      }
      this.setState({ seconds: this.state.seconds - 1 });
    }, 1000);
  };

  onChange = (e) => {
    this.setState({ error: '', [e.target.id]: e.target.value });
  };

  render() {
    const { is_valid_OTP } = this.props.auth;
    if (is_valid_OTP) {
      return <Redirect to="/reset-password" />;
    }
    const { seconds, otpDisabled } = this.state;
    return (
      <div>
        <NavbarComponent />
        <Container>
          <Row className="mt-5">
            <Col xs="12" sm="12" md={{ size: 6, offset: 3 }}>
              <Form>
                <FormGroup>
                  <Input
                    value={this.state.phone_number}
                    placeholder="Enter your registered mobile number"
                    id="phone_number"
                    onChange={this.onChange}
                    autoFocus={true}

                  />
                  <FormFeedback>You will not be able to see this</FormFeedback>
                </FormGroup>
                <FormGroup style={{ textAlign: 'left' }}>
                  <Button
                    color="primary"
                    onClick={this.sendOtp}
                    disabled={seconds}
                    type="submit"
                  >
                    Send Otp{' '}
                    {this.state.seconds
                      ? `in ${this.state.seconds} seconds`
                      : ''}
                  </Button>
                </FormGroup>
                <FormGroup>
                  <Input
                    disabled={otpDisabled}
                    value={this.state.otp}
                    placeholder="Enter your otp sent to your mobile number"
                    id="otp"
                    onChange={this.onChange}
                  />
                </FormGroup>
                <FormGroup style={{ textAlign: 'left' }}>
                  <Button
                    color="primary"
                    onClick={this.verifyOtp}
                    disabled={otpDisabled}
                    type="submit"
                  >
                    Submit{' '}
                  </Button>
                </FormGroup>
              </Form>

              {/* <Row>
            <Col sm={4}></Col>
            <Col sm={4} style={{ paddingTop: '10%' }}> 
               <Form>
                <FormGroup>
                  
                  <Input
                    onChange={this.onChange}
                    id="phone_number"
                    value={this.state.phone_number}
                  />
                  <Label for="exampleEmail">Input without validation</Label>
                </FormGroup> 
                 <Form.Group>
              <Button
                style={{ marginLeft: 10 }}
                onClick={this.sendOtp}
                disabled={seconds}
                type="submit"
              >
                Send Otp{' '}
                {this.state.seconds ? `in ${this.state.seconds} seconds` : ''}
              </Button>
            </Form.Group>
            <Form.Group>
              <Form.Group>
                <Form.Control
                  type="text"
                  onChange={this.onChange}
                  value={this.state.otp}
                  id="otp"
                  placeholder="Enter otp sent to your registered mobile"
                  disabled={otpDisabled}
                />
              </Form.Group>
              <Button
                type="submit"
                disabled={otpDisabled}
                onClick={this.verifyOtp}
                style={{ marginLeft: 10 }}
              >
                Submit
              </Button>
            </Form.Group>
            {this.state.error && (
              <Alert variant="danger">{this.state.error}</Alert>
            )}
             
               </Form>
              */}
              {this.props.error && (
                <Alert variant="success">{this.props.error}</Alert>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.auth.error,
});

export default connect(mapStateToProps, {
  sendOtp,
  verifyOtp,
  setError
})(Otp);
