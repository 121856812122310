import React, { Component } from 'react';


class Insights extends Component {
  constructor() {
    super();
    this.state = {

    }
  }



  render() {
    return (
      <div>Insights</div>
    )
  }
}

export default Insights;