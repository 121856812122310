const axios = require("axios");

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const API_URL_TWO = process.env.REACT_APP_API_URL_TWO;

const headers = {
  "Content-Type": "application/json",
  "x-metro-api-key": API_KEY,
};

function checkForToken() {
  if (
    localStorage.getItem("session_token") &&
    localStorage.getItem("session_token") != ""
  ) {
    headers["x-metro-sessiontoken"] = localStorage.getItem("session_token");
  }
}

function get(url, params = {}) {
  const header = {
    "Content-Type": "application/json",
    "x-metro-api-key": API_KEY,
  };
  return axios
    .get(API_URL + url, { headers: header, params, withCredentials: true })
    .then((resp) => resp)
    .catch((err) => {
      AutoLogout(err);
      throw err;
    });
}

function post(url, params = {}) {
  const header = {
    "Content-Type": "application/json",
    "x-metro-api-key": API_KEY,
  };
  return axios
    .post(API_URL + url, params, { headers: header, withCredentials: true })
    .then((resp) => resp)
    .catch((err) => {
      AutoLogout(err);
      throw err;
    });
}

async function sess_get(url, sessiontoken, params) {
  const header = {
    "Content-Type": "application/json",
    "x-metro-api-key": API_KEY,
    "x-metro-sessiontoken": sessiontoken,
  };
  return axios
    .get(API_URL + url, { headers: header, params, withCredentials: true })
    .then((resp) => resp)
    .catch((err) => {
      AutoLogout(err);
      throw err;
    });
}

function sess_post(url, sessiontoken, params) {
  const header = {
    "Content-Type": "application/json",
    "x-metro-api-key": API_KEY,
    "x-metro-sessiontoken": sessiontoken,
  };
  return axios
    .post(API_URL + url, params, { headers: header, withCredentials: true })
    .then((resp) => resp)
    .catch((err) => {
      AutoLogout(err);
      throw err;
    });
}
function sess_put(url, sessiontoken, params) {
  const header = {
    "Content-Type": "application/json",
    "x-metro-api-key": API_KEY,
    "x-metro-sessiontoken": sessiontoken,
  };
  return axios
    .put(API_URL + url, params, { headers: header, withCredentials: true })
    .then((resp) => resp)
    .catch((err) => {
      AutoLogout(err);
      throw err;
    });
}

function PUT(url, params, updateData) {
  checkForToken();
  return axios
    .put(API_URL_TWO + url, updateData, { headers, params })
    .then((resp) => resp)
    .catch((err) => {
      AutoLogout(err);
      throw err;
    });
}
function setup_axios_interceptor(store, props) {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const { data } = error.response;
      if (data && data.error && data.error.code === 2046) {
        store.dispatch({
          type: "LOGOUT",
        });
        window.location.href = "/";
        localStorage.clear();
      }
      return Promise.reject(error);
    }
  );
}

async function AutoLogout(err) {
  if (
    err.response &&
    err.response.data &&
    err.response.data.error &&
    err.response.data.error.code == 1004
  ) {
    localStorage.removeItem("session_token");
    localStorage.removeItem("isLogged");
    localStorage.removeItem("username");
    localStorage.removeItem("care_portal_user_name");
    window.location.reload();
  }
}

function Delete(url, params, data) {
  const header = {
    "Content-Type": "application/json",
    "x-metro-api-key": API_KEY,
  };
  if (localStorage.getItem("session_token")) {
    header["x-metro-sessiontoken"] = localStorage.getItem("session_token");
  }

  return axios
    .delete(API_URL + url, {
      headers: header,
      params,
      data,
      withCredentials: true,
    })
    .then((resp) => resp)
    .catch((err) => {
      AutoLogout(err);
      throw err;
    });
}

export {
  get,
  post,
  sess_get,
  sess_post,
  sess_put,
  PUT,
  setup_axios_interceptor,
  Delete,
};
