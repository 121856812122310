import moment from "moment";
import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { sess_get } from "../../services/HttpRequests";

function TableHeader() {
  return (
    <thead>
      <tr>
        <th>Date</th>
        <th>Time</th>
        <th>Method</th>
        <th>Amount</th>
        <th>Entry type</th>
        <th>Status</th>
      </tr>
    </thead>
  );
}

function TableData(props) {
  let listDisplay;
  if (props.list.length > 0) {
    listDisplay = props.list.map((item, index) => {
      if (item?.payout?.id) {
        return (
          <tr key={item._id}>
            <td>{moment(item.created_at).format("DD-MM-YYYY")}</td>
            <td>{moment(item.created_at).format("hh:mm a")}</td>
            <td>{item.reference_method}</td>
            <td>{item?.payout?.amount}</td>
            <td>Debit</td>
            <td>{item?.payout?.status}</td>
          </tr>
        );
      } else {
        return (
          <tr key={item._id}>
            <td>{item?.created_at ? moment(item.created_at).format("DD-MM-YYYY") : '-'}</td>
            <td>{item?.created_at ? moment(item.created_at).format("hh:mm a") : '-'}</td>
            <td>{item?.reference_method || '-'}</td>
            <td>{item?.amount || '-'}</td>
            <td>{item?.entry || '-'}</td>
            <td>
              {item?.scratch_card_status === "scratched"
                ? "Claimed"
                : "Unclaimed"}
            </td>
          </tr>
        );
      }
    });
  }

  return <tbody>{listDisplay}</tbody>;
}

export default function PayoutDetails(props) {
  const [list, setList] = useState([]);
  useEffect(() => {
    let session_token = localStorage.getItem("session_token");
    sess_get("admin/console/referral-payouts", session_token, {
      user_id: props.user_id,
    })
      .then((res) => {
        setList(res?.data?.response?.payoutList);
      })
      .catch((err) => console.log(err));
  }, []);

  if (list.length > 0) {
    return (
      <Table responsive className="newTable">
        <TableHeader />
        <TableData list={list} />
      </Table>
    );
  } else {
    return <p>No Referral payouts</p>;
  }
}
