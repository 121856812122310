import React, { Component } from 'react';
import Navbar from '../Others/NavbarComponent';
import { verifyDL } from '../../services/KYCAnalysis';

class KYCAnalysis extends Component {
  constructor() {
    super();
    this.state = {
      one: true,
      two: false,
      three: false,
      four: false,
      dlNumber: null,
      dlDOB: null,
      EPICNo: null,
      passFileNumber: null,
      passDOB: null,
      passDOI: null,
      passNumber: null,
      passName: null,
    }
  }


  selectOne = () => {
    this.setState({
      one: true, two: false, three: false, four: false
    })
  }


  selectTwo = () => {
    this.setState({
      one: false, two: true, three: false, four: false
    })
  }


  selectThree = () => {
    this.setState({
      one: false, two: false, three: true, four: false
    })
  }
  selectFour = () => {
    this.setState({
      one: false, two: false, three: false, four: true
    })
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  }


  onSubmit = () => {
    if (this.state.one) {
      let data = {
        dlNumber: this.state.dlNumber,
        dob: this.state.dlDOB
      }
      verifyDL(data);
    }
  }

  onReset = () => {
    this.setState({
      dlNumber: null,
      dlDOB: null,
      EPICNo: null,
      passFileNumber: null,
      passDOB: null,
      passDOI: null,
      passNumber: null,
      passName: null,
    })
  }




  render() {
    let Inputs;

    if (this.state.one) {
      Inputs = <React.Fragment>
        <label>DL Number</label>
        <input
          id="dlNumber"
          name="DL Number"
          value={this.state.dlNumber}
          onChange={this.onChange}
        />

        <label>DOB</label>
        <input
          type="date"
          id="dlDOB"
          name="DL DOB"
          value={this.state.dlDOB}
          onChange={this.onChange}
        />
      </React.Fragment>
    }


    if (this.state.two) {
      Inputs = <React.Fragment>
        <label>EPIC Number</label>
        <input
          id="EPIC"
          name="EPIC Number"
          value={this.state.EPICNo}
          onChange={this.onChange}
        />

      </React.Fragment>
    }

    if (this.state.three) {
      Inputs = <React.Fragment>
        <label>EPIC Number</label>
        <input
          id="EPIC"
          name="EPIC Number"
          value={this.state.EPICNo}
          onChange={this.onChange}
        />

      </React.Fragment>
    }


    if (this.state.four) {
      Inputs = <React.Fragment>
        <label>File Number</label>
        <input
          id="passFileNumber"
          name="File Number"
          value={this.state.passFileNumber}
          onChange={this.onChange}
        />
        <label>DOB</label>
        <input
          type="date"
          id="passDOB"
          name="File Number"
          value={this.state.passDOB}
          onChange={this.onChange}
        />
        <label>DOI</label>
        <input
          type="date"
          id="passDOI"
          name="File Number"
          value={this.state.passDOI}
          onChange={this.onChange}
        />
        <label>Passport Number</label>
        <input
          id="passNumber"
          name="File Number"
          value={this.state.passNumber}
          onChange={this.onChange}
        />
        <label>Name</label>
        <input
          id="passName"
          name="Name"
          value={this.state.passName}
          onChange={this.onChange}
        />
      </React.Fragment>
    }


    return (
      <div>
        <Navbar />
        <div>
          <label>DL</label>
          <input
            id="one"
            type="checkbox"
            checked={this.state.one}
            onChange={this.selectOne}
          />
          <label>Aadhaar</label>
          <input
            id="two"
            type="checkbox"
            checked={this.state.two}
            onChange={this.selectTwo}
          />
          <label>Voter ID</label>
          <input
            id="three"
            type="checkbox"
            checked={this.state.three}
            onChange={this.selectThree}
          />
          <label>Passport</label>
          <input
            id="four"
            type="checkbox"
            checked={this.state.four}
            onChange={this.selectFour}
          />
        </div>


        {Inputs}

        <div>
          <button onClick={this.onSubmit} >Submit</button>
          <button onClick={this.onReset} >Reset</button>
        </div>
      </div>
    )
  }
}

export default KYCAnalysis;