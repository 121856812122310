import React, { Component } from "react";
import "../../assets/css/nReports.css";

class Engagement extends Component {
  render() {
    return (
      <div className="table2 row-margin">
        <h2 style={{ "font-weight": "600" }}> Engagement</h2>
        <table className="newTable">
          <tr>
            <th>Metric</th>
            <th>Lifetime</th>
            <th>Today</th>
            <th>Sov %</th>
          </tr>
          <tr>
            <td style={{ "font-weight": "bolder" }}>Passcodes</td>
            <td>{this.props.passcodesLifetime}</td>
            <td>{this.props.passcodesToday}</td>
            <td>{this.props.passcode_sov}</td>
          </tr>
          <tr>
            <td style={{ "font-weight": "bolder" }}>OTP Messages</td>
            <td>{this.props.otpLifetime}</td>
            <td>{this.props.otpToday}</td>
            <td>{this.props.otp_sov}</td>
          </tr>
          <tr>
            <td style={{ "font-weight": "bolder" }}>Calls from Trusted services</td>
            <td>{this.props.viaTSLifetime}</td>
            <td>{this.props.viaTSToday}</td>
            <td>{this.props.trusted_services_sov}</td>
          </tr>
          <tr>
            <td style={{ "font-weight": "bolder" }}>Calls from Trusted Contacts</td>
            <td>{this.props.viaTCLifetime}</td>
            <td>{this.props.viaTCToday}</td>
            <td>{this.props.trusted_contacts_sov}</td>
          </tr>
          <tr>
            <td style={{ "font-weight": "bolder" }}>Calls via Passcodes</td>
            <td>{this.props.viaPasscodeLifetime}</td>
            <td>{this.props.viaPasscodeToday}</td>
            <td>{this.props.passcode_calls_sov}</td>
          </tr>
        </table>
      </div>
    );
  }
}

export default Engagement;
