import React, { Component } from 'react';
import Pagination from './Pagination';
import { Table, Row, Col, Form, Button, FormGroup, Input, Alert } from 'reactstrap';
import { GetPaymentDetails } from '../../../services/PaymentRequests';
import DownloadIcon from '../../../assets/images/download_icon_red.png';
import moment from 'moment';
import SearchComponent from '../../Others/SearchComponent';
import { sess_get } from 'services/HttpRequests';
let colorMap = {
  authorized: '#fda73c',
  captured: '#4bb371',
  failed: '#f63f37',
};

class Payments extends Component {
  constructor() {
    super();
    this.state = {
      paymentList: [],
      pageSize: 10,
      pageNo: 1,
      totalPages: null,
      searchCategory: null,
      keys: [
        "Select Category",
        "payment_id",
        "order_id",
        "amount",
        "email",
        "contact",
        "created_at",
        "status",
      ],
      searchInputType: "string",
    };
  }

  componentDidMount() {
    let data = {
      pageNo: 1,
      pageSize: 10,
    };
    GetPaymentDetails(data)
      .then((resp) => {
        if (resp && resp.success) {
          this.setState({
            paymentList: resp.response.required_docs,
            pageNo: resp.response.pageNo,
            pageSize: resp.response.pageSize,
            totalPages: resp.response.totalPages,
            error: "",
          });
        }
      })
      .catch((error) => {
        this.setState({ error: error?.response?.reason });
      });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    if (value == "all") {
      this.setState({ [name]: null });
    } else {
      this.setState({ [name]: value });
    }
  };

  search = (event) => {
    event.preventDefault();
    let data = {
      pageNo: 1,
      pageSize: 10,
    };
    this.getPaymentData(data);
  };

  getPaymentData = (data) => {
    if (!data) {
      data = {
        pageNo: this.state.pageNo,
        pageSize: this.state.pageSize,
      };
    }
    if (this.state.searchCategory) {
      data.label = this.state.searchCategory;
    }

    if (this.state.searchKeyProperty) {
      data.searchKeyProperty = this.state.searchKeyProperty;
    }

    if (this.state.searchKeyword) {
      data.searchKeyword = this.state.searchKeyword;
    }
    if (this.state.searchInputType) {
      data.searchInputType = this.state.searchInputType;
    }

    GetPaymentDetails(data)
      .then((resp) => {
        if (resp && resp.success) {
          this.setState({
            paymentList: resp.response.required_docs,
            pageNo: resp.response.pageNo,
            pageSize: resp.response.pageSize,
            totalPages: resp.response.totalPages,
            // searchKeyword: isSearch ? "" : this.state.searchKeyword,
            // searchKeyProperty: isSearch ? "" : this.state.searchKeyProperty,
            error: "",
          });
        }
      })
      .catch((err) => {
        this.setState({
          // paymentList: [],
          error: err?.error?.reason || "Something went wrong",
        });
      });
  };
  searchReset = () => {
    this.setState({ ...this.state, searchKeyProperty: "Select Category", searchKeyword: " " });
  };
  handleKeywrodChanges = (event) => {

    console.log(event.target.name, event.target.value);



  }
  handleSearchInputChanges = (event) => {
    let state = {};
    if (event.target.value === "Select Category") {
      state = {
        searchKeyProperty: "",
        searchKeyword: "",
      };
    }
    console.log(event.target.name, event.target.value);


    this.setState({
      ...this.state,
      [event.target.name]: event.target.value === "Select category" ? "" : event.target.value,

      ...state,
      searchInputType: event.target.getAttribute('data-is_date')
    });
  };

  handleInvoiceDownload = (id) => {
    const session_token = localStorage.getItem("session_token");
    sess_get('admin/console/invoice-download-url', session_token, { invoice_no: id })
      .then((resp) => {
        if (resp?.data?.response?.url?.url) {
          window.open(resp?.data?.response?.url?.url);
        }
      })
      .catch((err) => {
        console.log('err', err);
      })
  }

  render() {
    const keys = this.state.keys || [];
    function TableHeader() {
      return (
        <thead>
          <tr>
            <th>Payment ID</th>
            <th>Order ID</th>
            <th>Amount</th>
            <th>Email</th>
            <th>Contact</th>
            <th>Created At</th>
            <th>Category</th>
            <th>Status</th>
            <th>Platform</th>
            <th>Invoice</th>
          </tr>
        </thead>
      );
    }

    const data = this.state.paymentList.map((payment) => {
      return (
        <tr key={payment._id}>
          <td>{payment.payment_id}</td>
          <td>{payment.order_id}</td>
          <td>{payment.amount / 100}</td>
          <td>{payment.email}</td>
          <td>{payment.contact}</td>
          <td>
            {moment(payment.payment_created_at).format("DD MMM YYYY h:mm a")}
          </td>
          <td>{payment.is_subscription ? "SUB" : "ORDER"}</td>
          <td style={{ color: colorMap[payment.status] || "#7b7b7b" }}>
            {payment.status}
          </td>
          <td>{payment.source}</td>
          <td data-label="Invoice">
            {payment?.invoice_no ? (
              <img src={DownloadIcon} onClick={() => this.handleInvoiceDownload(payment.invoice_no)} />
            ) : '-'}
          </td>
        </tr>
      );
    });

    return (
      <div>
        <Form onSubmit={this.search} style={{ "padding-bottom": "20px" }}>
          <Row>
            <Col md={3}>
              <Input
                type="select"
                id="searchCategory"
                name="searchCategory"
                value={this.state.searchCategory}
                className="dropdown-color"
                onChange={this.handleInputChange}
              >
                <option value="all"> All</option>
                <option value="captured"> Captured </option>
                <option value="authorized"> Authorized </option>
                <option value="failed"> Failed </option>
              </Input>
            </Col>
            <SearchComponent
              keys={keys}
              // handleKeywrodChanges={this.handleKeywrodChanges}
              handleSearchInputChanges={this.handleSearchInputChanges}
              searchKeyProperty={this.state.searchKeyProperty}
              searchKeyword={this.state.searchKeyword}
            />
            <Col md={3}>
              <Button
                type="submit"
                color="primary"
                style={{ float: "right" }}
                className="kyc-filter-button"
              >
                Search
              </Button>
            </Col>
          </Row>
        </Form>

        {this.state.error && <Alert color="danger">{this.state.error}</Alert>}

        <Table responsive className="newTable" style={{ "width": "100%" }}>
          <TableHeader></TableHeader>
          <tbody>{data}</tbody>
        </Table>

        <Pagination
          currentPage={this.state.pageNo}
          lastPage={this.state.totalPages}
          getInfo={this.getPaymentData}
        />
      </div>
    );
  }
}

export default Payments;
